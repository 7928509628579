import { PaginationBase } from 'src/app/app-core/forms/pagination.base';
import { StorageConfiguration } from 'src/app/app-core/configurations/storage.configuration';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AppFormBuilder } from 'src/app/app-core/forms/app-from.builder';
import { AssetModel } from 'src/app/app-model/asset.model';
import { AssetUsedModel, AssetUsedViewModel } from 'src/app/app-model/assetused.model';

export abstract class AssetPresenter extends PaginationBase {
  assets: AssetModel[] = [] as AssetModel[];
  asset: AssetModel = {} as AssetModel;
  assetuseds: AssetUsedModel[] = [] as AssetUsedModel[];
  assetused: AssetUsedModel = {} as AssetUsedModel;

  assetForm: AppFormBuilder = new AppFormBuilder({
    id: new UntypedFormControl(0),
    unitOrganitation_id: new UntypedFormControl('', [Validators.required]),
    subUnitOrganitation_id: new UntypedFormControl('', [Validators.required]),
    category_id: new UntypedFormControl('', [Validators.required]),
  });


  assetusedForm: AppFormBuilder = new AppFormBuilder({
    id: new UntypedFormControl(0),
    asset_id: new UntypedFormControl(0),
    statusAsalUsul: new UntypedFormControl('', [Validators.required]),
    statusHak: new UntypedFormControl('', [Validators.required]),
    registeredNo: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
    luas: new UntypedFormControl('', [Validators.required]),
    price: new UntypedFormControl('', [Validators.required]),
    yearProcurement: new UntypedFormControl('', [Validators.required, Validators.maxLength(4)]),
    address: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.maxLength(255)]),
    longtitude: new UntypedFormControl('', [Validators.maxLength(255)]),
    cerificateDate: new UntypedFormControl(''),
    certificateNo: new UntypedFormControl('', [Validators.maxLength(255)]),
    usedIn: new UntypedFormControl('', [Validators.maxLength(255)]),
    image: new UntypedFormControl(''),
    note: new UntypedFormControl(''),
  });

  get fieldAsset() {
    return {
      id: this.assetForm.getControl('id') ?? null,
      unitOrganitation_id: this.assetForm.getControl('unitOrganitation_id') ?? null,
      subUnitOrganitation_id: this.assetForm.getControl('subUnitOrganitation_id') ?? null,
      category_id: this.assetForm.getControl('category_id') ?? null,
    };
  }

  get fieldAssetUsed() {
    return {
      id: this.assetusedForm.getControl('id') ?? null,
      asset_id: this.assetusedForm.getControl('asset_id') ?? null,
      statusAsalUsul: this.assetusedForm.getControl('statusAsalUsul') ?? null,
      statusHak: this.assetusedForm.getControl('statusHak') ?? null,
      registeredNo: this.assetusedForm.getControl('registeredNo') ?? null,
      luas: this.assetusedForm.getControl('luas') ?? null,
      price: this.assetusedForm.getControl('price') ?? null,
      yearProcurement: this.assetusedForm.getControl('yearProcurement') ?? null,
      address: this.assetusedForm.getControl('address') ?? null,
      latitude: this.assetusedForm.getControl('latitude') ?? null,
      longtitude: this.assetusedForm.getControl('longtitude') ?? null,
      cerificateDate: this.assetusedForm.getControl('cerificateDate') ?? null,
      certificateNo: this.assetusedForm.getControl('certificateNo') ?? null,
      usedIn: this.assetusedForm.getControl('usedIn') ?? null,
      image: this.assetusedForm.getControl('image') ?? null,
      note: this.assetusedForm.getControl('note') ?? null,
    };
  }

  setFieldAssetValues(value?: AssetModel): void {
    if (value) {
      this.assetForm.getControl('id')?.setValue(value.id);
      this.assetForm.getControl('unitOrganitation_id')?.setValue(value.subUnitOrganitation.unitOrganitation_id);
      this.assetForm.getControl('subUnitOrganitation_id')?.setValue(value.subUnitOrganitation_id);
      this.assetForm.getControl('category_id')?.setValue(value.category_id);
    } else {
      this.assetForm.getControl('id')?.setValue(null);
      this.assetForm.getControl('unitOrganitation_id')?.setValue(null);
      this.assetForm.getControl('v')?.setValue(null);
      this.assetForm.getControl('category_id')?.setValue(null);
    }
  }

  setFieldAssetUsedValues(value?: AssetUsedModel): void {
    if (value) {
      this.assetusedForm.getControl('id')?.setValue(value.id);
      this.assetusedForm.getControl('asset_id')?.setValue(value.asset_id);
      this.assetusedForm.getControl('statusAsalUsul')?.setValue(value.statusAsalUsul);
      this.assetusedForm.getControl('statusHak')?.setValue(value.statusHak);
      this.assetusedForm.getControl('registeredNo')?.setValue(value.registeredNo);
      this.assetusedForm.getControl('luas')?.setValue(value.luas);
      this.assetusedForm.getControl('price')?.setValue(value.price);
      this.assetusedForm.getControl('yearProcurement')?.setValue(value.yearProcurement);
      this.assetusedForm.getControl('address')?.setValue(value.address);
      this.assetusedForm.getControl('latitude')?.setValue(value.latitude);
      this.assetusedForm.getControl('longtitude')?.setValue(value.longtitude);
      this.assetusedForm.getControl('cerificateDate')?.setValue(value.cerificateDate);
      this.assetusedForm.getControl('certificateNo')?.setValue(value.certificateNo);
      this.assetusedForm.getControl('usedIn')?.setValue(value.usedIn);
      this.assetusedForm.getControl('image')?.setValue(value.image);
      this.assetusedForm.getControl('note')?.setValue(value.note);
    } else {
      this.assetusedForm.getControl('id')?.setValue(null);
      this.assetusedForm.getControl('asset_id')?.setValue(null);
      this.assetusedForm.getControl('statusAsalUsul')?.setValue(null);
      this.assetusedForm.getControl('statusHak')?.setValue(null);
      this.assetusedForm.getControl('registeredNo')?.setValue(null);
      this.assetusedForm.getControl('luas')?.setValue(null);
      this.assetusedForm.getControl('price')?.setValue(null);
      this.assetusedForm.getControl('yearProcurement')?.setValue(null);
      this.assetusedForm.getControl('address')?.setValue('');
      this.assetusedForm.getControl('latitude')?.setValue('');
      this.assetusedForm.getControl('longtitude')?.setValue('');
      this.assetusedForm.getControl('cerificateDate')?.setValue('');
      this.assetusedForm.getControl('certificateNo')?.setValue('');
      this.assetusedForm.getControl('usedIn')?.setValue('');
      this.assetusedForm.getControl('image')?.setValue('');
      this.assetusedForm.getControl('note')?.setValue('');
    }
  }

  setFieldAssetUsedTempValues(value?: AssetUsedViewModel): void {
    if (value) {
      this.assetusedForm.getControl('id')?.setValue(value.assetUsed.id);
      this.assetusedForm.getControl('asset_id')?.setValue(value.assetUsed.asset_id);
      this.assetusedForm.getControl('statusAsalUsul')?.setValue(value.assetUsed.statusAsalUsul);
      this.assetusedForm.getControl('statusHak')?.setValue(value.assetUsed.statusHak);
      this.assetusedForm.getControl('registeredNo')?.setValue(value.assetUsed.registeredNo);
      this.assetusedForm.getControl('luas')?.setValue(value.assetUsed.luas);
      this.assetusedForm.getControl('price')?.setValue(value.assetUsed.price);
      this.assetusedForm.getControl('yearProcurement')?.setValue(value.assetUsed.yearProcurement);
      this.assetusedForm.getControl('address')?.setValue(value.assetUsed.address);
      this.assetusedForm.getControl('latitude')?.setValue(value.assetUsed.latitude);
      this.assetusedForm.getControl('longtitude')?.setValue(value.assetUsed.longtitude);
      this.assetusedForm.getControl('cerificateDate')?.setValue(value.assetUsed.cerificateDate);
      this.assetusedForm.getControl('certificateNo')?.setValue(value.assetUsed.certificateNo);
      this.assetusedForm.getControl('usedIn')?.setValue(value.assetUsed.usedIn);
      this.assetusedForm.getControl('image')?.setValue(value.assetUsed.image);
      this.assetusedForm.getControl('note')?.setValue(value.assetUsed.note);
    }
  }

  setFieldAssetValue(field: string, value?: any): void {
    this.assetForm.getControl(field)?.setValue(value);
  }

  setFieldAssetUsedValue(field: string, value?: any): void {
    this.assetusedForm.getControl(field)?.setValue(value);
  }

  getFieldValue() {
    this.asset = {
      id: this.fieldAsset.id?.value,
      subUnitOrganitation_id: this.fieldAsset.subUnitOrganitation_id?.value,
      category_id: this.fieldAsset.category_id?.value,
    } as AssetModel;
  }

  getAssetUsedFieldValue() {
    this.assetused = {
      id: this.fieldAssetUsed.id?.value,
      asset_id: this.fieldAssetUsed.asset_id?.value,
      statusAsalUsul: this.fieldAssetUsed.statusAsalUsul?.value,
      statusHak: this.fieldAssetUsed.statusHak?.value,
      registeredNo: this.fieldAssetUsed.registeredNo?.value,
      luas: this.fieldAssetUsed.luas?.value,
      price: this.fieldAssetUsed.price?.value,
      yearProcurement: this.fieldAssetUsed.yearProcurement?.value,
      address: this.fieldAssetUsed.address?.value,
      latitude: this.fieldAssetUsed.latitude?.value,
      longtitude: this.fieldAssetUsed.longtitude?.value,
      cerificateDate: this.fieldAssetUsed.cerificateDate?.value,
      certificateNo: this.fieldAssetUsed.certificateNo?.value,
      usedIn: this.fieldAssetUsed.usedIn?.value,
      image: this.fieldAssetUsed.image?.value,
      note: this.fieldAssetUsed.note?.value,
    } as AssetUsedModel;
  }

  get isInsertAssetData() {
    return this.asset.id == null || this.asset.id == 0;
  }

  get isInsertAssetUsedData() {
    return this.assetused.id == null || this.assetused.id == 0;
  }

}
