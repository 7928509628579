export interface BreadcumbModel {
  values: BreadcumbItemModel[];
}

export interface BreadcumbItemModel {
  routeName: string;
  breadcumbName: string;
}

export class BreadcumbResultModel {
  constructor(currentURL: string, breadcumb: string, breadcumbLast: string) {
    this.currentURL = currentURL;
    this.breadcumb = breadcumb;
    this.breadcumbLast = breadcumbLast;
  }

  public currentURL: string = '';
  public breadcumb: string = '';
  public breadcumbLast: string = '';
}

