import { Component, OnInit } from '@angular/core';
import { APIResponse, PaginationModel } from 'src/app/app-core/models/api-response.model';
import { AuthService } from 'src/app/app-core/services/auth.service';
import { HttpService } from 'src/app/app-core/services/http.service';
import { NotificationService } from 'src/app/app-core/services/notification.service';
import { AssetModel } from 'src/app/app-model/asset.model';
import { APIROUTE } from '../../api-route.config';
import { GridFilterParamModel } from 'src/app/app-core/models/grid-extension.model';
import { AssetPresenter } from './../asset.presenter';
import { AssetUsedModel, AssetUsedViewModel } from 'src/app/app-model/assetused.model';
import { ModalExtension } from 'src/app/app-core/extensions/bootstrap/modal.extension';
import { ObjectExtension } from 'src/app/app-core/extensions/object.extension';
import { GridExtension } from 'src/app/app-core/extensions/grid.extension';
import { CategoryModel } from 'src/app/app-model/category.model';
import { UnitOrganitationModel } from 'src/app/app-model/unitorganitation.model';
import { SubunitorganitationModel } from 'src/app/app-model/subunitorganitation.model';
import { PageContextModel, URLUtility } from 'src/app/app-core/utilities/url.utility';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, catchError, forkJoin, map } from 'rxjs';
import { Stepper } from 'src/app/app-core/default-view/stepper/stepper.model';
import { StorageConfiguration } from 'src/app/app-core/configurations/storage.configuration';
import { StatusModel } from 'src/app/app-model/status.model';
import { ServerConfiguration } from 'src/app/app-core/configurations/server.configuration';
import * as uuid from 'uuid';
import { AssetKIBAModel } from 'src/app/app-model/assetkiba.model';
import { CONSTANT_STORAGE } from 'src/app/app-core/constants/global.constant';

@Component({
  selector: 'app-asset-view',
  templateUrl: './asset-view.component.html',
  styleUrls: ['./asset-view.component.css']
})
export class AssetViewComponent extends AssetPresenter implements OnInit {
  steppers: Array<Stepper> = StorageConfiguration.getDataAssetStepper;
  formStepId: number = 1;
  maxFormStepId: number = 2;
  urlContext: URLUtility | undefined;
  pageParam: PageContextModel = {} as PageContextModel;
  asset_Id: string = '';
  unitOrganitation: UnitOrganitationModel = {} as UnitOrganitationModel;
  categories: CategoryModel[] = [] as CategoryModel[];
  unitOrganitations: UnitOrganitationModel[] = [] as UnitOrganitationModel[];
  subUnitOrganitationTemps: SubunitorganitationModel[] = [] as SubunitorganitationModel[];
  subUnitOrganitations: SubunitorganitationModel[] = [] as SubunitorganitationModel[];
  status: StatusModel[] = [] as StatusModel[];
  assetKIBAs: AssetKIBAModel[] = [] as AssetKIBAModel[];
  assetusedTemps: AssetUsedViewModel[] = [] as AssetUsedViewModel[];
  isLoading: boolean = false;
  isAdministratorPrivillage: boolean = false;
  isShowSearch: boolean = false;
  filter: GridFilterParamModel = {} as GridFilterParamModel;
  isFileFromSelector: boolean = false;
  file: File | null = null;

  constructor(private _service: HttpService, public _notification: NotificationService, private _authService: AuthService, private _router: Router, private _actRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.urlContext = new URLUtility(this._router, this._actRoute);
    this.pageParam = this.urlContext.getParam('id');
    this.asset_Id = this.pageParam.param;
    this.isAdministratorPrivillage = this._authService.isAdministratorPrivillage;
    this.initialize();
  }

  initialize() {
    this.assets = [] as AssetModel[];
    this.asset = {id:0, subUnitOrganitation_id:0, category_id:0} as AssetModel;
    this.assetuseds = [] as AssetUsedModel[];
    this.assetused = {} as AssetUsedModel;
    this.categories = [] as CategoryModel[];
    this.unitOrganitations = [] as UnitOrganitationModel[];
    this.subUnitOrganitations = [] as SubunitorganitationModel[];
    this.subUnitOrganitationTemps = [] as SubunitorganitationModel[];
    this.status = [] as StatusModel[];
    this.unitOrganitation = {id: 0} as UnitOrganitationModel;
    this.assetusedTemps = [] as AssetUsedViewModel[];
    this.assetKIBAs = [] as AssetKIBAModel[];

    this.isFileFromSelector = false;
    this.isLoading = false;
    this.initializeForm();
  }

  initializeForm() {
    this.initializeParamater();
    if (this.pageParam.isPageEdit) this.initializeFormEdit();
  }

  initializeParamater() {
    this.isLoading = this._service.setLoading(true);
    var httpParameters = forkJoin([this.getCategory(), this.getUnitOrganitation(), this.getSubUnitOrganitation(), this.getStatus()]);
    httpParameters.subscribe({
      next: ([responseCategory, responseUnit, responseSubUnit, responseStatus]) => {
        this.categories = responseCategory.data;
        this.unitOrganitations = responseUnit.data;
        this.subUnitOrganitationTemps = responseSubUnit.data;
        this.status = responseStatus.data;
        this.isLoading = this._service.setLoading(false);
      },
      error: (error) => {
        this.isLoading = this._service.setLoading(false);
      }
    });
  }

  initializeFormEdit() {
    console.log('initializeFormEdit Start');
    this.isLoading = this._service.setLoading(true);
    this.assetusedTemps = [];
    var httpAssetResponses = forkJoin([this.getAsset(parseInt(this.asset_Id)), this.getAssetKIBA(parseInt(this.asset_Id))]);
    httpAssetResponses.subscribe({
      next: ([responseAsset, responseAssetKIBA]) => {
        this.asset = responseAsset.data;
        this.assetKIBAs = responseAssetKIBA.data;
        this.assetuseds.forEach(x => {
          var assetUsed = new AssetUsedViewModel(uuid.v4(), x);
          this.assetusedTemps.push(assetUsed);
        })
        console.log('initializeFormEdit Finish');
        this.isLoading = this._service.setLoading(false);
      },
      error: (error) => {
        this.isLoading = this._service.setLoading(false);
      }
    });
  }

  onSubmit() {
    this.assetForm.submitted = true;
    if (this.assetForm.invalid) {
      this._notification.warning('Save Data', 'Please fill mandatory field!');
      return;
    }

    this.assetusedForm.submitted = true;
    if (this.assetusedForm.invalid) {
      this._notification.warning('Save Data', 'Please fill mandatory field!');
      return;
    }

    this.getFieldValue();

    if (!this.asset) {
      throw Error('Data Model, Data Model undefined')
    }

    if (!this.assetKIBAs) {
      throw Error('Data Model, Data Model undefined')
    }
    console.log(this.asset, this.assetKIBAs);
    // this.isInsertAssetData ? this.insertData() : this.updateData();
  }

  insertData() {
    this.isLoading = this._service.setLoading(true);
    this.asset.isDeleted = false;
    this._service.post<APIResponse<AssetModel>>(APIROUTE.ASSET, this.asset).subscribe((response) => {
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Information', 'Success Save Data');
      this.onCancel();
    }, error => {
      this._notification.error('Information', 'Save data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  updateData() {
    this.isLoading = this._service.setLoading(true);
    this.asset.isDeleted = false;
    this._service.put<APIResponse<AssetModel>>(APIROUTE.ASSET, this.asset.id.toString(), this.asset).subscribe((response) => {
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Information', 'Success Save Data');
      this.onCancel();
    }, error => {
      this._notification.error('Information', 'Save data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  onChangeUnit(event: any) {
    var id = event.target.value;
    this.subUnitOrganitations = this.subUnitOrganitationTemps.filter(x => x.id == id);
    console.log(this.subUnitOrganitations);
  }

  get buildQueryOption() {
    var gridExtension = new GridExtension();
    return gridExtension.paging(this.pagination.page, this.pagination.limit).filter(this.filter.field, this.filter.value).build();
  }

  onBack() {
    if (this.formStepId !== 1) {
      this.formStepId = this.formStepId - 1;
    }
  }
  onStepNext() {

    // if(this.formStepId == 1){
    //   this.assetForm.submitted = true;
    //   if (this.assetForm.invalid) {
    //     this._notification.warning('Save Data', 'Please fill mandatory field!');
    //     return;
    //   }
    // }

    if (this.maxFormStepId !== this.formStepId) {
      this.formStepId = this.formStepId + 1;
    }
  }

  onDataChange($event:any, type: number) {
    switch(type) {
      case 1:
        this.assetKIBAs = $event;
        StorageConfiguration.saveTempAsset(this.assetKIBAs, CONSTANT_STORAGE.ASSETKIBA);
        break;
    }
  }

  onCancel = () => this.urlContext?.redirect('/asset/');
  getAsset = (id: number) => this._service.find<APIResponse<AssetModel>>(APIROUTE.ASSET, id.toString());
  getAssetUsed = (assetId: number) => this._service.find<APIResponse<AssetUsedModel[]>>(APIROUTE.ASSETUSED_ASSET, assetId.toString());
  getAssetKIBA = (assetId: number) => this._service.find<APIResponse<AssetKIBAModel[]>>(APIROUTE.ASSETKIBA_ASSET, assetId.toString());
  getCategory = () => this._service.get<APIResponse<CategoryModel[]>>(APIROUTE.CATEGORY);
  getUnitOrganitation = () => this._service.get<APIResponse<UnitOrganitationModel[]>>(APIROUTE.UNITORGANITATION);
  getSubUnitOrganitation = () => this._service.get<APIResponse<SubunitorganitationModel[]>>(APIROUTE.SUBUNITORGANITATION);
  getStatus = () => this._service.get<APIResponse<StatusModel[]>>(APIROUTE.STATUS);
}

