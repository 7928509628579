import { AssetComponent } from './app-view/asset/asset.component';
import { GroupComponent } from './app-core/default-view/group/group.component';
import { UserProfileComponent } from './app-core/default-view/user-profile/user-profile.component';
import { SettingsComponent } from './app-core/default-view/settings/settings.component';
import { UsersComponent } from './app-core/default-view/users/users.component';
import { LoginActivate } from './app-core/services/login-activate.service';
import { LoginComponent } from './app-core/default-view/login/login.component';
import { DashboardComponent } from './app-core/default-view/dashboard/dashboard.component';
import { isUseHashTagInRoute, SERVER_ENVIRONMENT } from './app-core/configurations/parameter.configuration';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { siteAuthGuard, siteGuard } from './app-core/services/site.guard';
import { RegionComponent } from './app-view/region/region.component';
import { AssetFormComponent } from './app-view/asset/asset-form/asset-form.component';
import { AssetViewComponent } from './app-view/asset/asset-view/asset-view.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate:[siteGuard], data: {Breadcumb: 'Home'}  },
  {
    path: 'user',
    redirectTo: 'user',
    pathMatch: 'full'
  },
  { path: 'user', component: UsersComponent, canActivate:[siteGuard], data: {Breadcumb: 'User Management'} },
  { path: 'user/:id', component: UserProfileComponent, canActivate:[siteGuard], data: {Breadcumb: 'User Management'} },
  { path: 'group', component: GroupComponent, canActivate:[siteGuard], data: {Breadcumb: 'Group Management'} },
  { path: 'setting', component: SettingsComponent, canActivate:[siteGuard], data: {Breadcumb: 'Setting'} },
  { path: 'region', component: RegionComponent, canActivate:[siteGuard], data: {Breadcumb: 'Region'} },
  { path: 'asset', component: AssetComponent, canActivate:[siteGuard], data: {Breadcumb: 'Asset Management'} },
  { path: 'asset-form/:id', component: AssetFormComponent, canActivate:[siteGuard], data: {Breadcumb: 'Asset Management'} },
  { path: 'asset-view/:id', component: AssetViewComponent, canActivate:[siteGuard], data: {Breadcumb: 'Asset Management'} },
  { path: 'login', component: LoginComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
