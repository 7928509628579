export class GridExtensionModel {
  constructor(paging: GridPaginationParamModel | null | undefined, filter: GridFilterParamModel | null | undefined){
    this.paging = paging;
    this.filter = filter;
  }

  paging: GridPaginationParamModel | null | undefined;
  filter: GridFilterParamModel | null | undefined;
}

export class GridPaginationParamModel {
  constructor(pageIndex: number, pageSize: number){
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
  }

  pageIndex: number = 0;
  pageSize: number = 0;
}

export class GridFilterParamModel {
  constructor(field: string, value: string, filterOption: Array<GridFilterFieldModel> | null | undefined = null){
    this.field = field;
    this.value = value;
    this.filterOption = filterOption;
  }

  field: string = '';
  value: string = '';
  filterOption: Array<GridFilterFieldModel>  | null | undefined;
}

export class GridFilterFieldModel {
  constructor(key: string, title: string, dataType: string, optionValues: Array<GridFilterOptionValueModel> | null | undefined = null){
    this.key = key;
    this.title = title;
    this.dataType = dataType;
    this.optionValues = optionValues;
  }

  key: string = '';
  title: string = '';
  dataType: string = '';
  optionValues: Array<GridFilterOptionValueModel> | null | undefined;
}

export class GridFilterOptionValueModel {
  constructor(key: string, name: string){
    this.key = key;
    this.name = name;
  }

  key: string = '';
  name: string = '';
}
