import { AuthService } from './../../services/auth.service';
import { APIROUTE } from 'src/app/app-view/api-route.config';
import { DashboardDataCountModel, GeneralDashboarModel } from './../../models/general.model';
import { APIResponse } from './../../models/api-response.model';
import { UserProfileModel } from './../../models/user-profile.model';
import { StorageConfiguration } from './../../configurations/storage.configuration';
import { ServerConfiguration } from 'src/app/app-core/configurations/server.configuration';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/app-core/services/http.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  isLoading: boolean = false;
  userProfile: UserProfileModel = {} as UserProfileModel;
  dashboardDataCount: DashboardDataCountModel = {} as DashboardDataCountModel;
  dashboardDataCounts: Array<GeneralDashboarModel> = [] as GeneralDashboarModel[];

  constructor(private _service: HttpService, private _router: Router, private _authService: AuthService) { }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.userProfile = {} as UserProfileModel;
    this.dashboardDataCount = {} as DashboardDataCountModel;
    this.dashboardDataCounts = [] as GeneralDashboarModel[];
    this.getUserLogin();
    this.getData();
  }

  getData() {
    this.getDataCountDashboard();
  }

  getDataCountDashboard() {
    // this.isLoading = this._service.setLoading(true);
    // this.dashboardDataCounts = [];
    // this._service.get<APIResponse<DashboardDataCountModel>>(APIROUTE.DASHBOARD_DATA_COUNT).subscribe((response) =>  {
    //   this.dashboardDataCount = response.data;
    //   if(!response.error) {
    //     this.onSuccessGetCountDataDashboard(this.dashboardDataCount);
    //   }
    //   this.isLoading = this._service.setLoading(false);
    // }, error => {
    //   this.isLoading = this._service.setLoading(false);
    // });
  }

  onSuccessGetCountDataDashboard(dashboardCount: DashboardDataCountModel){
    var dashboardData: GeneralDashboarModel = {} as GeneralDashboarModel;
    dashboardData.key = 'airport';
    dashboardData.title = 'Profile';
    dashboardData.group = 'Arport';
    dashboardData.icon = 'bxs-plane';
    dashboardData.color = 'bxs-plane';
    dashboardData.value = dashboardCount.airport;
    this.dashboardDataCounts.push(dashboardData);

    dashboardData = {} as GeneralDashboarModel;
    dashboardData.key = 'airportProgress';
    dashboardData.title = 'Progress';
    dashboardData.group = 'Arport';
    dashboardData.icon = 'bx-run';
    dashboardData.color = 'bxs-plane';
    dashboardData.value = dashboardCount.airportProgress;
    this.dashboardDataCounts.push(dashboardData);

    dashboardData = {} as GeneralDashboarModel;
    dashboardData.key = 'airportMonitoring';
    dashboardData.title = 'Monitoring';
    dashboardData.group = 'Arport';
    dashboardData.icon = 'bx-repost';
    dashboardData.color = 'bxs-plane';
    dashboardData.value = dashboardCount.airportMonitoring;
    this.dashboardDataCounts.push(dashboardData);

    dashboardData = {} as GeneralDashboarModel;
    dashboardData.key = 'province';
    dashboardData.title = 'Province';
    dashboardData.group = 'Region';
    dashboardData.icon = 'bxs-data';
    dashboardData.color = 'bxs-plane';
    dashboardData.value = dashboardCount.province;
    this.dashboardDataCounts.push(dashboardData);

    dashboardData = {} as GeneralDashboarModel;
    dashboardData.key = 'district';
    dashboardData.title = 'District';
    dashboardData.group = 'Region';
    dashboardData.icon = 'bxs-data';
    dashboardData.color = 'bxs-plane';
    dashboardData.value = dashboardCount.district;
    this.dashboardDataCounts.push(dashboardData);

    dashboardData = {} as GeneralDashboarModel;
    dashboardData.key = 'user';
    dashboardData.title = 'User';
    dashboardData.group = 'User Management';
    dashboardData.icon = 'bxs-user';
    dashboardData.color = 'bxs-plane';
    dashboardData.value = dashboardCount.user;
    this.dashboardDataCounts.push(dashboardData);
  }

  parseImage(imageName: string) {
    var imageUrl = ServerConfiguration.ParseImage(imageName);
    return imageUrl;
  }

  getUserLogin() {
    if (this.isLoggedIn) {
      var userInfo = this._authService.getAuthInformation();
      if (userInfo) this.userProfile = userInfo.user.userProfile;
    }
  }

  get isLoggedIn() {
    this.userProfile.name
    return this._authService.isLoggedIn;
  }
}
