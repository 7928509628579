import { AuthService } from './../../services/auth.service';
import { ServerConfiguration } from './../../configurations/server.configuration';
import { CONSTANT_APPCONFIG_PARAM } from './../../constants/global.constant';
import { APPConfiguration } from './../../configurations/app-config.configuration';
import { AppConfigModel } from './../../models/configuration.model';
import { LayoutConfiguration } from './../../configurations/layout.configuration';
import { URLConfiguration } from './../../configurations/url.configuration';
import { StringExtension } from './../../extensions/string.extension';
import { StorageConfiguration } from './../../configurations/storage.configuration';
import { BreadcumbModel, BreadcumbItemModel } from './../../models/breadcumb.model';
import { ObjectExtension } from './../../extensions/object.extension';
import { APIResponse } from './../../models/api-response.model';
import { HttpService } from './../../services/http.service';
import { NavigationModel } from './../../models/navigation.model';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CONSTANT_STORAGE } from '../../constants/global.constant';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html'
})
export class AppSidebarComponent implements OnInit {
  navigations: NavigationModel[] = [] as NavigationModel[];
  currentRoute: string = 'dashboard';
  currentRoutes: string[] = [];
  breadcumb: string = 'Home';
  breadcumbLast: string = '';
  isLoading: boolean = false;
  isLogin: boolean = false;
  isAdministratorPrivillage: boolean = false;
  companyLogo: string = '';
  @Output() onBreadcumbChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(public _router: Router, private _service: HttpService, private _authService: AuthService) {
  }

  ngOnInit(): void {
    this.isAdministratorPrivillage = this._authService.isAdministratorPrivillage;
    this.navigations = [] as NavigationModel[];
    LayoutConfiguration.setMenuScroll();
    this.getAppConfiguration();
    this.getCurrentRoute();
    this.getNavigation();
  }

  getCurrentRoute() {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let currUrl = URLConfiguration.parse(event.urlAfterRedirects);
        var breadCumbResult = URLConfiguration.getBreadCumb(currUrl);
        this.breadcumb = breadCumbResult.breadcumb;
        this.breadcumbLast = breadCumbResult.breadcumbLast;
        let query = currUrl.split('/').filter(x => x != '');
        if (query.length > 0) {
          this.currentRoute = query.length > 1 ? query[1] : query[0];
        } else {
          this.currentRoute = 'dashboard';
        }
        this.currentRoutes = query;
      }
    });
  }

  getNavigation() {
    this.isLoading = this._service.setLoading(true);
    var navigations = StorageConfiguration.getLocalData<NavigationModel[]>(CONSTANT_STORAGE.NAVIGATION) ?? [];
    this.navigations = navigations.filter(x => x.isShow);
    this.isLoading = this._service.setLoading(false);
  }

  onNavbarClicked(route: string): void {
    this._router.navigate([route]).then(isChange => {
      //this.onBreadcumbChange.emit(this.breadcumb);
    });
  }

  hasHaveChildren(item: NavigationModel): string {
    var routeActive = '';
    if (item.parent_id != 0) {
      var isExistingParent = this.navigations.filter(x => x.id === item.parent_id).length > 0;
      if (isExistingParent) routeActive = ' open';
    }
    return routeActive;
  }

  hasMenuActive(item: NavigationModel, isMenuSub: boolean): string {
    var routeActive = 'menu-item';
    if (item.name) {
      var routeName = item.name;
      var routeNames = item.name.split('/');

      var names = routeNames.filter(y => y.toLowerCase() === this.currentRoute.toLowerCase());
      if (ObjectExtension.Any(names)) routeName = names[0];

      var currentRoutes = this.currentRoutes.filter(x => x.toLowerCase() === routeName.toLowerCase());
      if (ObjectExtension.Any(currentRoutes) && (routeName === this.currentRoute)) routeActive = routeActive + ' active';
      if (ObjectExtension.Any(currentRoutes) && isMenuSub && (routeName === this.currentRoute)) routeActive = routeActive + ' active';
      if (ObjectExtension.Any(currentRoutes) && !isMenuSub && this.currentRoutes.length > 0) routeActive = routeActive + ' open';
    } else {
      routeActive = 'menu-header small text-uppercase';
    }
    return routeActive;
  }

  toggleCollapsed() {
    LayoutConfiguration.toggleCollapsed();
  }

  getAppConfiguration(){
    var companyLogo = APPConfiguration.getConfiguration(CONSTANT_APPCONFIG_PARAM.COMPANY_LOGO);
    console.log(companyLogo);

    this.companyLogo = companyLogo == '' ? '' : (ServerConfiguration.ParseImage(companyLogo) ?? '');
    console.log(this.companyLogo);
  }
}
