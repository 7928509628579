import { AuthService } from './../../services/auth.service';
import { GroupMemberModel } from './../../models/group-member.model';
import { GridFilterParamModel } from './../../models/grid-extension.model';
import { GridExtension } from './../../extensions/grid.extension';
import { PaginationModel } from './../../models/api-response.model';
import { StorageConfiguration } from 'src/app/app-core/configurations/storage.configuration';
import { Logger } from './../../utilities/logger.utility';
import { StringExtension } from 'src/app/app-core/extensions/string.extension';
import { TooltipPopoverExtension } from './../../extensions/bootstrap/tooltip-popover.extension';
import { ObjectExtension } from './../../extensions/object.extension';
import { UserAccessModel } from './../../models/user-access.model';
import { UserProfileModel } from './../../models/user-profile.model';
import { ModalExtension } from 'src/app/app-core/extensions/bootstrap/modal.extension';
import { APIROUTE } from './../../../app-view/api-route.config';
import { APIResponse } from 'src/app/app-core/models/api-response.model';
import { NotificationService } from './../../services/notification.service';
import { HttpService } from 'src/app/app-core/services/http.service';
import { UserModel } from './../../models/user.model';
import { Component, OnInit } from '@angular/core';
import { UserFormPresenter } from './user_form.presenter';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent extends UserFormPresenter implements OnInit {
  users: UserModel[] | undefined;
  isAdministratorPrivillage: boolean = false;
  isLoading: boolean = false;
  loginId: number = 0;
  isShowSearch: boolean = false;
  filter: GridFilterParamModel = {} as GridFilterParamModel;

  constructor(public _service: HttpService, public _notification:NotificationService, private _authService: AuthService) {
    super();
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.filter = {field: 'name', value: ''} as GridFilterParamModel;
    this.users = [] as UserModel[];
    this.user.userProfile = {} as UserProfileModel;
    this.user.userAccess = {} as UserAccessModel;
    this.user.createdByUser = {} as UserProfileModel;
    this.user.modifiedByUser = {} as UserProfileModel;
    this.isAdministratorPrivillage = this._authService.isAdministratorPrivillage;
    this.loginId = this.userLoginId(this._authService);
    this.isShowSearch = false;
    this.pagination = {
      page: 1,
      count: 0,
      nextPage: 0,
      previousPage: 0,
      totalPage: 0,
      limit: 10
    } as PaginationModel;
    this.getData();
  }

  getData() {
    this.isLoading = this._service.setLoading(true);
    this._service.get<APIResponse<UserModel[]> >(APIROUTE.USER_PROFILE, undefined, this.buildQueryOption).subscribe((response) =>  {
      this.users = response.data;
      this.pagination = response.paging;
      this.isLoading = this._service.setLoading(false);
    });
  }

  onAddNew() {
    this.user.userProfile = {} as UserProfileModel;
    this.user.userAccess = {} as UserAccessModel;
    this.user.createdByUser = {} as UserProfileModel;
    this.user.modifiedByUser = {} as UserProfileModel;
    this.user.groupmember = {} as GroupMemberModel;
    this.userForm.submitted = false;
    this.userForm.reset();
    ModalExtension.Show('formNewModal');
  }

  onEdit(item: UserModel) {
    var user = ObjectExtension.Copy<UserModel>(item);
    this.setFieldValues(user);
    ModalExtension.Show('formNewModal');
  }

  onViewDetail(item: UserModel) {
    this.user = ObjectExtension.Copy<UserModel>(item);
    ModalExtension.Show('formViewModal');
  }

  onSubmit() {
    this.userForm.submitted = true;
    if (this.userForm.invalid) {
      this._notification.warning('Save Data', 'Please fill mandatory field!');
      return;
    }

    this.getFieldValue();

    if(!this.user) {
      throw Error('Data Model, Data Model undefined')
    }
    // this.isInsertData ? this.insertData() : this.updateData();
    this.validateUser();
  }

  validateUser() {
    this.isLoading = this._service.setLoading(true);
    this._service.post<APIResponse<UserModel>>(APIROUTE.USER_PROFILE_VALIDATE, this.user).subscribe((response) =>  {
      this.isLoading = this._service.setLoading(false, true);
      // this._notification.success('Update Data', 'Update data success');
      this.isInsertData ? this.insertData() : this.updateData();
    }, (error: HttpErrorResponse) => {
      Logger.info(error.error);
      if(!ObjectExtension.IsNullOrEmpty(error.error)){
        if(!StringExtension.IsNullOrEmpty(error.error.statusMessage)) {
          this.isLoading = this._service.setLoading(false);
          this._notification.warning('Information', error.error.statusMessage);
        }
      }
      this.isLoading = this._service.setLoading(false);
    });
  }

  insertData() {
    this.isLoading = this._service.setLoading(true);
    this.user.userProfile.isAdmin = false;
    this.user.userProfile.isDeleted = false;
    this._service.post<APIResponse<UserModel>>(APIROUTE.USER_PROFILE, this.user).subscribe((response) =>  {
      ModalExtension.Hide('formNewModal');
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Information', 'Success Save Data');
      this.initialize();
    }, error => {
      this._notification.error('Information', 'Save data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  updateData() {
    this.isLoading = this._service.setLoading(true);
    this.user.userProfile.isAdmin = false;
    this.user.userProfile.isDeleted = false;
    this._service.put<APIResponse<UserModel>>(APIROUTE.USER_PROFILE, this.user.userProfile.id.toString(), this.user).subscribe((response) =>  {
      ModalExtension.Hide('formNewModal');
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Information', 'Success Save Data');
      this.initialize();
    }, error => {
      this._notification.error('Information', 'Save data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  setAsAdmin(user: UserModel) {
    this.user = user;
    this.user.userProfile.isAdmin = true;
    this.isLoading = this._service.setLoading(true);
    this._service.put<APIResponse<UserModel>>(APIROUTE.USER_PROFILE, this.user.userProfile.id.toString(), this.user).subscribe((response) =>  {
      ModalExtension.Hide('formNewModal');
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Update Data', 'Update data success');
      this.initialize();
    }, error => {
      this._notification.error('Update Data', 'Update data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  onDelete(user: UserModel) {
    this.user = user;
    this.user.userProfile.isAdmin = false;
    this.user.userProfile.isDeleted = true;
    this.isLoading = this._service.setLoading(true);
    this._service.put<APIResponse<UserModel>>(APIROUTE.USER_PROFILE, this.user.userProfile.id.toString(), this.user).subscribe((response) =>  {
      ModalExtension.Hide('formNewModal');
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Delete Data', 'Delete data success');
      this.initialize();
    }, error => {
      this._notification.error('Delete Data', 'Delete data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  onInputEmailChange() {
    if(this.field.isUseEmailForUserName) {
      this.setFieldValue('username', this.field.email);
    }
  }
  onActionToogle(elementid: string, icon: string, title: string, action: string) {
    var title = "<i class='bx "+icon+" bx-xs' ></i> <span>"+title+"</span>";
    TooltipPopoverExtension.tooltip(elementid, true, title, action);
  }

  isProfileUserLogin(userId: number) {
    return this.loginId === userId;
  }

  onViewSearch() {
    this.isShowSearch = !this.isShowSearch;
    this.filter.value = '';
  }

  onSearchChange($event: any) {
    if(this.filter.value.length > 3) {
      this.getData();
    }
  }

  onClearSearch() {
    this.filter.value = '';
    this.getData();
  }


  override onFirstPage(pagination: PaginationModel) {
    this.getData();
  }

  override onNextPage(pagination: PaginationModel) {
    this.getData();
  }

  override onPrevPage(pagination: PaginationModel) {
    this.getData();
  }

  override onLastPage(pagination: PaginationModel) {
    this.getData();
  }

  override onLimitPage(pagination: PaginationModel) {
    this.getData();
  }

  onClose() {
    this.userForm.submitted = false;
    this.userForm.reset();
    ModalExtension.Hide('formNewModal');
  }

  get buildQueryOption() {
    var gridExtension = new GridExtension();
    return gridExtension.paging(this.pagination.page, this.pagination.limit).filter(this.filter.field, this.filter.value).build();
  }
}
