import { Logger } from './../utilities/logger.utility';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Component } from '@angular/core';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private _http: HttpClient) {}

  public get(imagepath: string) {
    return this._http.get(imagepath, { responseType: 'blob' })
      .pipe(
        switchMap(blob => this.convertBlobToBase64(blob))
      );
  }

  private convertBlobToBase64(blob: Blob) {
    return Observable.create((observer: { next: (arg0: any) => void; complete: () => void; }) => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
        Logger.info('Image in Base64: ', event.target.result);
        observer.next(event.target.result);
        observer.complete();
      };

      reader.onerror = (event: any) => {
        Logger.info("File could not be read: " + event.target.error.code);
        observer.next(event.target.error.code);
        observer.complete();
      };
    });
  }
}
