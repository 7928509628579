import { CONSTANT_STORAGE, CONSTANT_APPCONFIG_PARAM } from './../constants/global.constant';
import { StorageConfiguration } from 'src/app/app-core/configurations/storage.configuration';
import { AppConfigModel } from './../models/configuration.model';
// @dynamic
export class APPConfiguration {

  public static getConfigurations() : AppConfigModel[] | null {
    var appConfigs = StorageConfiguration.getLocalData<AppConfigModel[]>(CONSTANT_STORAGE.APP_CONFIG);
    return appConfigs;
  }

  public static getConfiguration(name: string): string {
    var result = '';
    var appConfigs = APPConfiguration.getConfigurations();
    if(appConfigs != null) {
      if(appConfigs.length > 0){
        var isExisting = appConfigs.filter(x => x.name === name && x.isDeleted == false).length > 0;
        if(isExisting) {
          var appConfig = appConfigs.filter(x => x.name === name && x.isDeleted == false)[0];
          result = appConfig.value;
        }
      }
    }

    return result;
  }
}
