import { ValidatorFn, UntypedFormControl, UntypedFormGroup, AbstractControl, Validators, AbstractControlOptions, AsyncValidatorFn } from '@angular/forms';
import { AppFormBase } from './app-form.base';
import { Injectable } from '@angular/core';

export class AppFormBuilder extends UntypedFormGroup {
    public submitted = false;

    constructor(controls: { [key: string]: AbstractControl; },
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super(controls, validatorOrOpts, asyncValidator);
    }

    create(controls: [{ id: string, value: string | boolean, validator?: ValidatorFn | ValidatorFn[], options?: { key: string, value: string }[] }]) {
        const group: any = {};
        controls.forEach(formControl => {
            if (formControl.validator) {
                group[formControl.id] = new UntypedFormControl(formControl.value || '', formControl.validator);
            } else {
                group[formControl.id] = new UntypedFormControl(formControl.value || '');
            }
        });

        return new UntypedFormGroup(group);
    }

    getControl(key: string) { return this.get(key) ?? null; }
    getValue(key: string) {
        const control = this.getControl(key);
        if (control) {
            return control.value;
        }

        return null;
    }

    isInvalid(control: AbstractControl | null): boolean {
        if (control) {
            const error = control.invalid;
            return this.submitted && error;
        }

        return false;
    }

    isValidate(control: AbstractControl | null, errorType: string): boolean {
        if (control) {
            const errorRequired = Boolean(control.errors?.[errorType]);
            return errorRequired;
        }

        return false;
    }

    controlName(key: string) { return this.getControl(key) ?? null; }
}
