<!-- Layout wrapper -->
<div class="layout-wrapper layout-content-navbar" *ngIf="isLoggedIn">


  <div class="layout-container">
    <!-- Menu -->


    <app-sidebar></app-sidebar>
    <!-- / Menu -->

    <!-- Layout container -->
    <div class="layout-page">
      <!-- Navbar -->
      <nav class="layout-navbar container-fluid navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">

        <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
          <a (click)="toggleCollapsed()" class="nav-item nav-link px-0 me-xl-4 cursor-pointer">
            <i class="bx bx-menu bx-sm"></i>
          </a>
        </div>

        <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">

          <!-- Breadcumb -->
          <app-breadcumb></app-breadcumb>
          <!-- /Breadcumb -->
          <ul class="navbar-nav flex-row align-items-center ms-auto">
            <!-- User -->
            <app-user-information></app-user-information>
            <!-- /User -->
          </ul>

        </div>
      </nav>

      <!-- / Navbar -->

      <!-- Content wrapper -->
      <div class="content-wrapper">
        <!-- Content -->

        <div class="container-fluid flex-grow-1 container-p-y">
          <router-outlet></router-outlet>
        </div>
        <!-- / Content -->

        <!-- Footer -->
        <footer class="content-footer footer bg-light">
          <div class="container-fluid d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
            <div class="mb-2 mb-md-0">{{companyName}}</div>
            <div class="mb-2 mb-md-0">{{appVersion}}</div>
          </div>
        </footer>
        <!-- / Footer -->

        <div class="content-backdrop fade"></div>
      </div>
      <!-- Content wrapper -->
    </div>
    <!-- / Layout page -->
  </div>


<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: white" > Loading... </p>
</ngx-spinner>

  <!-- Overlay -->
  <div class="layout-overlay layout-menu-toggle" (click)="toggleCollapsed()"></div>
</div>

<div id="login" *ngIf="!isLoggedIn">
  <router-outlet></router-outlet>
  <!-- <app-login></app-login> -->
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true">
    <p style="color: white" > Loading... </p>
  </ngx-spinner>
</div>
<!-- <div class="modal fade show" id="logimodal" tabindex="-1" aria-labelledby="logimodal" aria-modal="true" role="dialog">
  <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <app-login></app-login>
    </div>
  </div>
</div> -->

