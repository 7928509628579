<aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme" (click)="toggleCollapsed()">
  <div class="app-brand demo mb-3" style="height: auto !important;">
    <a href="index.html" class="app-brand-link">
      <span class="col-md-6 offset-md-2">
        <img
          width="130"
          alt="Company Logo"
          [src]="companyLogo | safeUrl"
        />
    </span>
    </a>

    <!-- <a id="layout-menu-toggle" href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
      <i class="bx bx-chevron-left bx-sm align-middle"></i>
    </a> -->
    <!-- <a (click)="toggleShowCollapsed()" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none cursor-pointer" id="layout-menu-toggle">
      <i class="bx bx-chevron-left bx-sm align-middle"></i>
    </a> -->
  </div>
  <div class="menu-inner-shadow"></div>
  <ul id="menu-inner" class="menu-inner py-1 overflow-auto" *ngIf="!isLoading">
    <!-- Dashboard -->
    <li class="menu-item" [ngClass]="{'active': currentRoute === 'dashboard' || currentRoute === 'home'}">
      <a (click)="onNavbarClicked('/')" class="menu-link">
        <i class="menu-icon tf-icons bx bx-home-circle"></i>
        <div data-i18n="Analytics">Dashboard</div>
      </a>
    </li>

    <li *ngFor="let item of navigations; let no = index" [ngClass]="hasMenuActive(item, false)">
      <span *ngIf="item.name === null" class="menu-header-text">{{item.title}}</span>
      <a *ngIf="item.name !== null" (click)="onNavbarClicked(item.route)" class="menu-link" [ngClass]="{'menu-toggle': item.childrens.length > 0}">
        <i class="menu-icon tf-icons bx {{item.icon}}"></i>
        <div data-i18n="Boxicons">{{item.title}}</div>
      </a>
      <ul class="menu-sub" *ngIf="item.name !== null && item.childrens.length > 0">
        <li *ngFor="let itemChildren of item.childrens; let no = index" [ngClass]="hasMenuActive(itemChildren, true)">
          <a (click)="onNavbarClicked(itemChildren.route)" class="menu-link">
            <div data-i18n="Perfect Scrollbar">{{itemChildren.title}}</div>
          </a>
        </li>
      </ul>
    </li>

    <!-- <li class="menu-item" [ngClass]="{'active': currentRoute === 'setting' || currentRoute === 'setting'}">
      <a (click)="onNavbarClicked('/setting')" class="menu-link">
        <i class="menu-icon tf-icons bx bx-cog"></i>
        <div data-i18n="Analytics">Setting</div>
      </a>
    </li> -->
  </ul>
</aside>

