import { AbstractControl, UntypedFormControl, Validators } from "@angular/forms";
import { AppFormBuilder } from "src/app/app-core/forms/app-from.builder";
import { AssetKIBAModel } from "src/app/app-model/assetkiba.model";

export abstract class AssetKIBAPresenter {
  assetKIBAs: AssetKIBAModel[] = [] as AssetKIBAModel[];
  assetKIBA: AssetKIBAModel = {} as AssetKIBAModel;

  assetKIBAForm: AppFormBuilder = new AppFormBuilder({
    id: new UntypedFormControl(0),
    assetid: new UntypedFormControl(0),
    kodebarang: new UntypedFormControl('', [Validators.required]),
    namabarang: new UntypedFormControl('', [Validators.required]),
    noregister: new UntypedFormControl('', [Validators.required]),
    tahunpengadaan: new UntypedFormControl('', [Validators.required]),
    asalusul: new UntypedFormControl('', [Validators.required]),
    statushak: new UntypedFormControl('', [Validators.required]),
    luas: new UntypedFormControl('', [Validators.required]),
    harga: new UntypedFormControl('', [Validators.required]),
    alamat: new UntypedFormControl(''),
    tanggalsertifikat: new UntypedFormControl(''),
    nosertifikat: new UntypedFormControl(''),
    penggunaan: new UntypedFormControl(''),
    image: new UntypedFormControl(''),
    keterangan: new UntypedFormControl(''),
  });

  get fieldAsset() {
    return {
      id: this.assetKIBAForm.getControl('id') ?? null,
      assetid: this.assetKIBAForm.getControl('assetid') ?? null,
      kodebarang: this.assetKIBAForm.getControl('kodebarang') ?? null,
      namabarang: this.assetKIBAForm.getControl('namabarang') ?? null,
      noregister: this.assetKIBAForm.getControl('noregister') ?? null,
      tahunpengadaan: this.assetKIBAForm.getControl('tahunpengadaan') ?? null,
      asalusul: this.assetKIBAForm.getControl('asalusul') ?? null,
      statushak: this.assetKIBAForm.getControl('statushak') ?? null,
      luas: this.assetKIBAForm.getControl('luas') ?? null,
      harga: this.assetKIBAForm.getControl('harga') ?? null,
      alamat: this.assetKIBAForm.getControl('alamat') ?? null,
      tanggalsertifikat: this.assetKIBAForm.getControl('tanggalsertifikat') ?? null,
      nosertifikat: this.assetKIBAForm.getControl('nosertifikat') ?? null,
      penggunaan: this.assetKIBAForm.getControl('penggunaan') ?? null,
      image: this.assetKIBAForm.getControl('image') ?? null,
      keterangan: this.assetKIBAForm.getControl('keterangan') ?? null,
    };
  }

  setFieldValues(value?: AssetKIBAModel): void {
    if (value) {
      this.assetKIBAForm.getControl('id')?.setValue(value.id);
      this.assetKIBAForm.getControl('assetid')?.setValue(value.assetid);
      this.assetKIBAForm.getControl('kodebarang')?.setValue(value.kodebarang);
      this.assetKIBAForm.getControl('namabarang')?.setValue(value.namabarang);
      this.assetKIBAForm.getControl('noregister')?.setValue(value.noregister);
      this.assetKIBAForm.getControl('tahunpengadaan')?.setValue(value.tahunpengadaan);
      this.assetKIBAForm.getControl('asalusul')?.setValue(value.asalusul);
      this.assetKIBAForm.getControl('statushak')?.setValue(value.statushak);
      this.assetKIBAForm.getControl('luas')?.setValue(value.luas);
      this.assetKIBAForm.getControl('harga')?.setValue(value.harga);
      this.assetKIBAForm.getControl('alamat')?.setValue(value.alamat);
      this.assetKIBAForm.getControl('tanggalsertifikat')?.setValue(value.tanggalsertifikat);
      this.assetKIBAForm.getControl('nosertifikat')?.setValue(value.nosertifikat);
      this.assetKIBAForm.getControl('penggunaan')?.setValue(value.penggunaan);
      this.assetKIBAForm.getControl('image')?.setValue(value.image);
      this.assetKIBAForm.getControl('keterangan')?.setValue(value.keterangan);
    } else {
      this.assetKIBAForm.getControl('id')?.setValue(null);
      this.assetKIBAForm.getControl('assetid')?.setValue(null);
      this.assetKIBAForm.getControl('kodebarang')?.setValue('');
      this.assetKIBAForm.getControl('namabarang')?.setValue('');
      this.assetKIBAForm.getControl('noregister')?.setValue('');
      this.assetKIBAForm.getControl('tahunpengadaan')?.setValue(null);
      this.assetKIBAForm.getControl('asalusul')?.setValue('');
      this.assetKIBAForm.getControl('statushak')?.setValue('');
      this.assetKIBAForm.getControl('luas')?.setValue('');
      this.assetKIBAForm.getControl('harga')?.setValue('');
      this.assetKIBAForm.getControl('alamat')?.setValue('');
      this.assetKIBAForm.getControl('tanggalsertifikat')?.setValue('');
      this.assetKIBAForm.getControl('nosertifikat')?.setValue('');
      this.assetKIBAForm.getControl('penggunaan')?.setValue('');
      this.assetKIBAForm.getControl('image')?.setValue('');
      this.assetKIBAForm.getControl('keterangan')?.setValue('');
    }
  }

  getFieldValue() {
    this.assetKIBA = {
      id: this.fieldAsset.id?.value,
      assetid: this.fieldAsset.assetid?.value,
      kodebarang: this.isNull(this.fieldAsset.kodebarang, ''),
      namabarang: this.isNull(this.fieldAsset.namabarang, ''),
      noregister: this.isNull(this.fieldAsset.noregister, ''),
      tahunpengadaan: this.isNull(this.fieldAsset.tahunpengadaan, '0'),
      asalusul: this.isNull(this.fieldAsset.asalusul, ''),
      statushak: this.isNull(this.fieldAsset.statushak, ''),
      luas: this.isNull(this.fieldAsset.luas, '0'),
      harga: this.isNull(this.fieldAsset.harga, '0'),
      alamat: this.isNull(this.fieldAsset.alamat, ''),
      tanggalsertifikat: this.isNull(this.fieldAsset.tanggalsertifikat, ''),
      nosertifikat: this.isNull(this.fieldAsset.nosertifikat, ''),
      penggunaan:this.isNull(this.fieldAsset.penggunaan, ''),
      image: this.isNull(this.fieldAsset.image, ''),
      keterangan: this.isNull(this.fieldAsset.keterangan, ''),
    } as AssetKIBAModel;
  }

  setFieldValue(field: string, value?: any): void {
    this.assetKIBAForm.getControl(field)?.setValue(value);
  }

  get isInsertData() {
    return this.assetKIBA.id == null || this.assetKIBA.id == 0;
  }

  isNull(value: AbstractControl | null, replaceValue: string){
    if(value) return value.value;
    return replaceValue;
  }
}
