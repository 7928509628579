<div *ngIf="pageAction.enableActionAddNew || pageAction.enableActionUpdate || pageAction.enableActionDelete">
  <div class="needs-validation">
    <div class="row mb-3">
      <label class="form-label" for="basic-default-company"><strong>User</strong></label>
      <div>
        <select id="floatingInputDistrict" class="form-select form-select-sm" [(ngModel)]="groupMember.user_id" (ngModelChange)="onChangeDropdown($event, 2)"
          [ngClass]="{ 'is-invalid': submitted && groupMember.user_id == 0 }" required>
          <option value="0">Select User</option>
          <option *ngFor="let item of users; let no = index" [value]="item.id">{{item.name}}</option>
        </select>
        <div *ngIf="submitted && groupMember.user_id == 0" class="invalid-feedback">
          <div *ngIf="groupMember.user_id == 0">Field is required!</div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="float-end">
        <button type="button" class="btn btn-success brn-sm" (click)="onAddNewMember()" [disabled]="users.length < 1">Add</button>
      </div>
    </div>
    <div class="row mb-3">
      <small class="text-light fw-semibold">User Member</small>
      <div class="table-wrapper">
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead style="color: #696cff !important;background-color: rgba(105, 108, 255, 0.16) !important;">
              <tr>
                <th style="width: 2% !important;text-align: center !important;">No</th>
                <th>user</th>
                <th style="width: 5%  !important;text-align: center !important;">Actions</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr *ngFor="let item of userMembers; let no = index">
                <th style="width: 2% !important;text-align: center !important;">{{no+1}}</th>
                <td>{{item.name}}</td>
                <th class="text-center">
                  <button type="button" class="cursor-pointer btn btn-icon btn-outline-danger btn-sm" (click)="onRemoveMember(no, item)"><i class="bx bx-trash"></i></button>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!pageAction.enableActionAddNew || !pageAction.enableActionUpdate || !pageAction.enableActionDelete">
  <div class="row mb-3">
    <small class="text-light fw-semibold">User Member</small>
    <div class="table-wrapper">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead style="color: #696cff !important;background-color: rgba(105, 108, 255, 0.16) !important;">
            <tr>
              <th style="width: 2% !important;text-align: center !important;">No</th>
              <th>user</th>
            </tr>
          </thead>
          <tbody class="table-border-bottom-0">
            <tr *ngFor="let item of userMembers; let no = index">
              <th style="width: 2% !important;text-align: center !important;">{{no+1}}</th>
              <td>{{item.name}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
