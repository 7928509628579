import { ObjectExtension } from './../../extensions/object.extension';
import { Component, Input, OnInit, EventEmitter, Output, OnChanges } from '@angular/core';
import { Stepper } from './stepper.model';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css'],
  inputs: ['steppers', 'stepperId']
})
export class StepperComponent implements OnChanges {
  @Input() steppers: Array<Stepper> = [] as Stepper[];
  @Input() stepperId: number = 1;
  stepper: Stepper = {} as Stepper;

  constructor() { }

  ngOnChanges(changes:any) {
    this.stepper = {} as Stepper;
    var currentSteppers = this.steppers.filter(x => x.id == this.stepperId);
    if(ObjectExtension.Any(currentSteppers)) {
      this.stepper = currentSteppers[0];
    }
  }
}
