export const CONSTANT_STORAGE = {
  SERVER: 'server',
  ROUTE: 'route',
  FORM_ASSET_STEPPER: 'form-asset-stepper',
  TOKEN_KEY: 'sipatk',
  USER_KEY: 'USER',
  AUTH_INFORMATION_KEY: 'session',
  BEARER_TOKEN_HEADER_KEY: 'Authorization',
  X_ACCESS_TOKEN_HEADER_KEY: 'x-access-token',
  NAVIGATION: 'NAVIGATION',
  AIRPORT: 'airport',
  ASSET: 'ASSET',
  ASSETKIBA: 'ASSETKIBA',
  ASSETKIBB: 'ASSETKIBB',
  ASSETKIBC: 'ASSETKIBC',
  ASSETKIBD: 'ASSETKIBD',
  ASSETKIBE: 'ASSETKIBE',
  ASSETKIBF: 'ASSETKIBF',
  APP_CONFIG: 'APP_CONFIG',
  REFRESH_TOKEN: 'refreshtoken'
}

export const CONSTANT_STATUS_TO_ID = {
  ACTIVE: 1,
  PENDING: 2,
  BLOCKED: 3,
  NOT_ACTIVE: 4
}

export const CONSTANT_STATUS_TO_NAME = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  BLOCKED: 'BLOCKED',
  NOT_ACTIVE: 'NOT_ACTIVE'
}

export const CONSTANT_STATUS_TO_LABELICON = {
  ACTIVE: 'bg-label-success',
  PENDING: 'bg-label-info',
  BLOCKED: 'bg-label-danger',
  NOT_ACTIVE: 'bg-label-danger'
}

export const STATIC_JSON_DATA = {
  SERVER: '/assets/configurations/server.config.json',
  ROUTE: '/assets/configurations/route.config.json',
  FORM_ASSET_STEPPER: '/assets/configurations/form-asset-stepper.json',
}

export const CONSTANT_MESSAGE = {
  SUCCESS_INSERT: 'Success, insert new data',
  SUCCESS_UPDATE: 'Success, update data',
  SUCCESS_DELETE: 'Success, delete data',
  FAILED_INSERT: 'Failed, insert new data',
  FAILED_UPDATE: 'Failed, update data',
  FAILED_DELETE: 'Failed, delete data',
  MANDATORY_TEXT: 'Please insert mandatory field',
  BLANK_TEXT: 'Cannot save with blank value, please insert value first',
  BLANK_FILE: 'Cannot save blank file, please import file first',
  DUPLICATE_DATA: 'Data sudah tersedia, silahkan memasukan data lainnya',
  DELETE_MIN_USER_IN_MEMBER: 'Unable to delete the member, One user must in members',
  SUBMIT_MIN_USER_IN_MEMBER: 'Cannot save member, you must add user to member first, before submit',
}

export const CONSTANT_FILTER_DATA_TYPE = {
  TEXT: 'text',
  DROPDOWN: 'dropdown',
  DATETIME: 'datetime',
}

export const CONSTANT_APPCONFIG_PARAM = {
  COMPANY_LOGO: 'COMPANY_LOGO',
  COMPANY_NAME: 'COMPANY_NAME',
  APP_VERSION: 'APP_VERSION',
}
