export const PARAMETER_CONFIG = {
  ALERT_ELEMENT : 'error_alert'
}

export const ENVIRONMENT_CONFIG = {
  DEVELOPMENT : 'development',
  TESTING : 'testing',
  PRODUCTION : 'production'
}

export const SERVER_ENVIRONMENT = {
  environment: ENVIRONMENT_CONFIG.TESTING
};

export function isUseHashTagInRoute(): boolean {
  return SERVER_ENVIRONMENT.environment == ENVIRONMENT_CONFIG.DEVELOPMENT ? true : false;
}
