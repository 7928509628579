import { ModalExtension } from './../../extensions/bootstrap/modal.extension';
import { StringExtension } from './../../extensions/string.extension';
import { ObjectExtension } from './../../extensions/object.extension';
import { GridFilterFieldModel } from './../../models/grid-extension.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridFilterParamModel } from '../../models/grid-extension.model';

@Component({
  selector: 'app-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.css']
})
export class GridFilterComponent implements OnInit {
  @Input() filter: GridFilterParamModel = {} as GridFilterParamModel;
  @Output() onFilterChange: EventEmitter<GridFilterParamModel> = new EventEmitter<GridFilterParamModel>();
  @Output() onResetClick: EventEmitter<GridFilterParamModel> = new EventEmitter<GridFilterParamModel>();
  filterOption: GridFilterFieldModel = {} as GridFilterFieldModel;

  constructor() { }

  ngOnInit(): void {
    this.filterOption = {} as GridFilterFieldModel;

    if(!StringExtension.IsNullOrEmpty(this.filter.field)) {
      this.filterOption.key = this.filter.field;
      this.onChangeFilterField(this.filter);
    }
  }

  onChangeFilterField($event: any) {
    if(this.filter.filterOption) {
      var selectedOptions = this.filter.filterOption.filter(x => x.key === this.filterOption.key);
      if(ObjectExtension.Any(selectedOptions)) {
        var selectedOption = selectedOptions[0];
        this.filterOption.title = selectedOption.title;
        this.filterOption.dataType = selectedOption.dataType;
        this.filterOption.optionValues = selectedOption.optionValues;
        this.filter.field = this.filterOption.key;
        this.filter.value = '';
      }
    }
  }

  onSearch() {
    ModalExtension.Hide('ModalFilter');
    this.onFilterChange.emit(this.filter);
  }

  onCancel() {
    this.filter.field = '';
     this.filter.value = '';
     ModalExtension.Hide('ModalFilter');
    this.onResetClick.emit(this.filter);
  }
}
