import { AuthService } from './../../services/auth.service';
import { PaginationBase } from 'src/app/app-core/forms/pagination.base';
import { StorageConfiguration } from 'src/app/app-core/configurations/storage.configuration';
import { UserModel } from './../../models/user.model';
import { UserAccessModel } from './../../models/user-access.model';
import { UserProfileModel } from './../../models/user-profile.model';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AppFormBuilder } from './../../forms/app-from.builder';

export abstract class UserFormPresenter extends PaginationBase {
  userProfile: UserProfileModel = {} as UserProfileModel;
  userAccess: UserAccessModel = {} as UserAccessModel;
  user: UserModel = {} as UserModel;

  userForm: AppFormBuilder = new AppFormBuilder({
    id: new UntypedFormControl(0),
    accessId: new UntypedFormControl(0),
    nik: new UntypedFormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required]),
    username: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
    phone: new UntypedFormControl(''),
    createdDate: new UntypedFormControl(''),
    createdBy: new UntypedFormControl(0),
    modifiedDate: new UntypedFormControl(''),
    modifiedBy: new UntypedFormControl(0),
    key: new UntypedFormControl(''),
    isAdmin: new UntypedFormControl(false),
    isBlocked: new UntypedFormControl(false),
    isUseEmailForUserName: new UntypedFormControl(true),
  });

  get field() {
    return {
      id: this.userForm.getControl('id') ?? null,
      accessId: this.userForm.getControl('accessId') ?? null,
      nik: this.userForm.getControl('nik') ?? null,
      name: this.userForm.getControl('name') ?? null,
      email: this.userForm.getControl('email') ?? null,
      username: this.userForm.getControl('username') ?? null,
      password: this.userForm.getControl('password') ?? null,
      phone: this.userForm.getControl('phone') ?? null,
      createdDate: this.userForm.getControl('createdDate') ?? null,
      createdBy: this.userForm.getControl('createdBy') ?? null,
      modifiedDate: this.userForm.getControl('modifiedDate') ?? null,
      modifiedBy: this.userForm.getControl('modifiedBy') ?? null,
      key: this.userForm.getControl('key') ?? null,
      isAdmin: this.userForm.getControl('isAdmin') ?? null,
      isBlocked: this.userForm.getControl('isBlocked') ?? null,
      isUseEmailForUserName: this.userForm.getControl('isUseEmailForUserName') ?? null,
    };
  }

  setFieldValues(value?: UserModel): void {
    if (value) {
      this.userForm.getControl('id')?.setValue(value.userProfile.id);
      this.userForm.getControl('accessId')?.setValue(value.userAccess.id);
      this.userForm.getControl('nik')?.setValue(value.userProfile.nik);
      this.userForm.getControl('name')?.setValue(value.userProfile.name);
      this.userForm.getControl('email')?.setValue(value.userProfile.email);
      this.userForm.getControl('username')?.setValue(value.userAccess.username);
      this.userForm.getControl('password')?.setValue(value.userAccess.password);
      this.userForm.getControl('phone')?.setValue(value.userProfile.phone);
      this.userForm.getControl('createdDate')?.setValue(value.userProfile.createdDate);
      this.userForm.getControl('createdBy')?.setValue(value.userProfile.createdBy);
      this.userForm.getControl('modifiedDate')?.setValue(value.userProfile.modifiedDate);
      this.userForm.getControl('modifiedBy')?.setValue(value.userProfile.modifiedBy);
      this.userForm.getControl('key')?.setValue(value.userAccess.key);
      this.userForm.getControl('isAdmin')?.setValue(value.userProfile.isAdmin);
      this.userForm.getControl('isBlocked')?.setValue(value.userAccess.isBlocked);
      if(value.userAccess.username == '') {
        this.userForm.getControl('isUseEmailForUserName')?.setValue(true);
        this.userForm.getControl('username')?.setValue(value.userProfile.email);
      }else {
        if(value.userAccess.username !== value.userProfile.email){
          this.userForm.getControl('isUseEmailForUserName')?.setValue(false);
          this.userForm.getControl('username')?.setValue(value.userAccess.username);
        }else{
          this.userForm.getControl('isUseEmailForUserName')?.setValue(true);
          this.userForm.getControl('username')?.setValue(value.userProfile.email);
        }
      }
    } else {
      this.userForm.getControl('id')?.setValue(null);
      this.userForm.getControl('accessId')?.setValue(null);
      this.userForm.getControl('nik')?.setValue('');
      this.userForm.getControl('name')?.setValue('');
      this.userForm.getControl('email')?.setValue('');
      this.userForm.getControl('username')?.setValue('');
      this.userForm.getControl('password')?.setValue('');
      this.userForm.getControl('phone')?.setValue('');
      this.userForm.getControl('createdDate')?.setValue('');
      this.userForm.getControl('createdBy')?.setValue(null);
      this.userForm.getControl('modifiedDate')?.setValue('');
      this.userForm.getControl('modifiedBy')?.setValue(null);
      this.userForm.getControl('key')?.setValue('');
      this.userForm.getControl('isBlocked')?.setValue(false);
      this.userForm.getControl('isAdmin')?.setValue(false);
      this.userForm.getControl('isUseEmailForUserName')?.setValue(true);
    }
  }

  setFieldValue(field: string, value?: any): void {
    this.userForm.getControl(field)?.setValue(value);
  }

  getFieldValue() {
    console.log(this.field.isUseEmailForUserName?.value);
    this.user = {} as UserModel;
    this.user = {
      userProfile: {
        id: this.field.id?.value,
        nik: this.field.nik?.value,
        name: this.field.name?.value,
        email: this.field.email?.value,
        phone: this.field.phone?.value,
        isAdmin: this.field.isAdmin?.value,
        createdDate: this.field.createdDate?.value,
        createdBy: this.field.createdBy?.value,
        modifiedDate: this.field.modifiedDate?.value,
        modifiedBy: this.field.modifiedBy?.value,
      } as UserProfileModel,
      userAccess: {
        id: this.field.accessId?.value,
        username: this.field.isUseEmailForUserName?.value ? this.field.email?.value : this.field.username?.value,
        password: this.field.password?.value,
        key: this.field.key?.value,
        isLogin: false,
        isBlocked: this.field.isBlocked?.value,
        lastAccess: '',
        failedLogin: 0,
        userProfile_Id: this.field.id?.value,
      } as UserAccessModel
    } as UserModel;
  }

  get isInsertData() {
    return this.user.userProfile.id == null || this.user.userProfile.id == 0;
  }

  userLoginId(authService: AuthService) {
    var isLogging = authService.isLoggedIn;
    if(isLogging) {
      var auth = authService.getAuthInformation();
      if(auth != null){
        return auth.user.userProfile.id;
      }
    }
    return 0;
  }
}
