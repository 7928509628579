import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PermissionsService } from '../services/permissions.service';

/*
*https://angular.io/api/router/CanActivateFn
*/

export const siteAuthGuard: CanActivateFn = () => {
  return inject(PermissionsService).canAuthActivate();
};

export const siteGuard: CanActivateFn = (route, state) => {
  return inject(PermissionsService).canActivate(state.url);
};


