
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import Swal, { SweetAlertIcon } from "sweetalert2";

@Injectable({
  providedIn: 'root',
})
export class NotificationService {


  constructor(private toastService: ToastrService) {}

  public info(title?: string, message?: string) {
    this.toastService.info(message, title);
  }
  public success(title?: string, message?: string) {
    this.toastService.success(message, title);
  }
  public warning(title?: string, message?: string) {
    this.toastService.warning(message, title);
  }
  public error(title?: string, message?: string) {
    this.toastService.error(message, title);
  }
  public confirmation(icon?: SweetAlertIcon, title?: string, message?: string, isHtml: boolean = false) {
    var swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-outline-primary'
      },
      buttonsStyling: true,
    });

    return swalWithBootstrapButtons.fire({
      backdrop: true,
      title: title,
      text: isHtml ? undefined : message,
      html: isHtml ? message : undefined,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: false
    });
  }

}
