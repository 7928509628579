import { ObjectExtension } from 'src/app/app-core/extensions/object.extension';
import { SERVER_ENVIRONMENT, ENVIRONMENT_CONFIG } from './../configurations/parameter.configuration';

// @dynamic

export class Logger {

  public static info(message?: any, ...optionalParams: any[]) {
    if (SERVER_ENVIRONMENT.environment == ENVIRONMENT_CONFIG.DEVELOPMENT){
      if(ObjectExtension.Any(optionalParams)) {
        console.log(message, optionalParams);
      }else{
        console.log(message);
      }
    }
  }

  public static warning(message?: any, ...optionalParams: any[]) {
    if (SERVER_ENVIRONMENT.environment == ENVIRONMENT_CONFIG.DEVELOPMENT){
      if(ObjectExtension.Any(optionalParams)) {
        console.warn(message, optionalParams);
      }else{
        console.warn(message);
      }
    }
  }

  public static error(message?: any, ...optionalParams: any[]) {
    if (SERVER_ENVIRONMENT.environment == ENVIRONMENT_CONFIG.DEVELOPMENT){
      if(ObjectExtension.Any(optionalParams)) {
        console.error(message, optionalParams);
      }else{
        console.error(message);
      }
    }
  }

  public static processTime(message?: any, ...optionalParams: any[]) {
    if (SERVER_ENVIRONMENT.environment == ENVIRONMENT_CONFIG.DEVELOPMENT){
      if(ObjectExtension.Any(optionalParams)) {
        console.timeLog(message, optionalParams);
      }else{
        console.timeLog(message);
      }
    }
  }
}
