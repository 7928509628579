import { AuthService } from './../services/auth.service';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from '../services/http.service';
import { Router } from '@angular/router';


@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private _httpService: HttpService,private _authService: AuthService, private _router: Router) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });

        if (request.url.indexOf('/refresh_token') > -1) {
          return next.handle(request);
        }

        let authReq = request;
        const token = this._authService.getToken();
        if (token != null) {
          authReq = request.clone({
            withCredentials: true
          });
        }

        return next.handle(authReq);
    }
}

export const HttpRequestInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }
];
