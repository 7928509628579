<div class="col-lg-12">
  <div class="row">
    <div class="col-3 mb-3 d-flex pb-1 bg-primary rounded-2 p-3 pb-3 me-1" *ngFor="let item of dashboardDataCounts">
      <div class="avatar flex-shrink-0 me-3">
        <i class="bx {{item.icon}} text-white rounded" style="font-size: 32px !important;"></i>
      </div>
      <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
        <div class="me-2">
          <small class="d-block mb-1 text-white">{{item.group}}</small>
          <h6 class="mb-0 text-white">{{item.title}}</h6>
        </div>
        <div class="user-progress d-flex align-items-center gap-1">
          <h6 class="mb-0 text-white">{{item.value}}</h6>
        </div>
      </div>
    </div>
  </div>
</div>
