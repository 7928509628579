import { TooltipPopoverExtension } from './../../extensions/bootstrap/tooltip-popover.extension';
import { CONSTANT_MESSAGE } from 'src/app/app-core/constants/global.constant';
import { GroupMemberModel } from './../../models/group-member.model';
import { HttpErrorResponse } from '@angular/common/http';
import { StringExtension } from 'src/app/app-core/extensions/string.extension';
import { ObjectExtension } from 'src/app/app-core/extensions/object.extension';
import { ModalExtension } from 'src/app/app-core/extensions/bootstrap/modal.extension';
import { APIROUTE } from './../../../app-view/api-route.config';
import { APIResponse } from 'src/app/app-core/models/api-response.model';
import { NotificationService } from 'src/app/app-core/services/notification.service';
import { HttpService } from 'src/app/app-core/services/http.service';
import { GroupModel } from './../../models/group.model';
import { Component, OnInit } from '@angular/core';
import { UserProfileModel } from '../../models/user-profile.model';
import { Logger } from '../../utilities/logger.utility';
import { GridActionModel } from '../../models/general.model';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {
  groups: GroupModel[] =  [] as GroupModel[];
  group: GroupModel = {} as GroupModel;
  isLoading: boolean = false;
  submitted: boolean = false;
  isShowGroupMemberForm: boolean = false;
  key: number = 0;
  pageAction: GridActionModel = {enableActionAddNew: false, enableActionUpdate: false, enableActionDelete:false} as GridActionModel;
  groupMembers:GroupMemberModel[] = [] as GroupMemberModel[]
  constructor(public _service: HttpService, public _notification:NotificationService) {
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.groupMembers = [] as GroupMemberModel[];
    this.groups = [] as GroupModel[];
    this.group = {
      id: 0,
      name: '',
      description: '',
      isDeleted: false,
      created_by: 0,
      created: '',
      modified_by: 0,
      modified: '',
      can_create: false,
      can_update: false,
      can_delete: false,
      can_view: false,
      can_view_mobile: false
  } as GroupModel;
    this.group.user_created = {} as UserProfileModel;
    this.group.user_modified = {} as UserProfileModel;
    this.submitted = false;
    this.isShowGroupMemberForm = false;
    this.key = 0;
    this.getData();
  }

  getData() {
    this.isLoading = this._service.setLoading(true);
    this._service.get<APIResponse<GroupModel[]> >(APIROUTE.GROUP).subscribe((response) =>  {
      this.groups = response.data;
      this.isLoading = this._service.setLoading(false);
    });
  }

  onAddNew() {
    this.group = {
      id: 0,
      name: '',
      description: '',
      isDeleted: false,
      created_by: 0,
      created: '',
      modified_by: 0,
      modified: '',
      can_create: false,
      can_update: false,
      can_delete: false,
      can_view: false,
      can_view_mobile: false
  } as GroupModel;
    this.group.user_created = {} as UserProfileModel;
    this.group.user_modified = {} as UserProfileModel;
    ModalExtension.Show('formNewModal');
  }

  onEdit(item: GroupModel) {
    this.group = ObjectExtension.Copy<GroupModel>(item);
    ModalExtension.Show('formNewModal');
  }

  onViewDetail(item: GroupModel) {
    this.group = ObjectExtension.Copy<GroupModel>(item);
    ModalExtension.Show('formViewModal');
  }

  onSubmit() {
    this.submitted = true;
    if (StringExtension.IsNullOrEmpty(this.group.name)) {
      this._notification.warning('Save Data', 'Please fill mandatory field!');
      return;
    }

    if(!this.group) {
      throw Error('Data Model, Data Model undefined')
    }

    // this.isInsertData ? this.insertData() : this.updateData();
    this.validateUser();
  }

  validateUser() {
    this.isLoading = this._service.setLoading(true);
    this._service.post<APIResponse<any>>(APIROUTE.GROUP_VALIDATE_FORM, this.group).subscribe((response) =>  {
      this.isLoading = this._service.setLoading(false, true);
      // this._notification.success('Update Data', 'Update data success');
      this.group.id == 0 ? this.insertData() : this.updateData();
    }, (error: HttpErrorResponse) => {
      Logger.info(error.error);
      if(!ObjectExtension.IsNullOrEmpty(error.error)){
        if(!StringExtension.IsNullOrEmpty(error.error.statusMessage)) {
          this.isLoading = this._service.setLoading(false);
          this._notification.warning('Information', error.error.statusMessage);
        }
      }
      this.isLoading = this._service.setLoading(false);
    });
  }

  insertData() {
    this.isLoading = this._service.setLoading(true);
    this._service.post<APIResponse<GroupModel>>(APIROUTE.GROUP, this.group).subscribe((response) =>  {
      ModalExtension.Hide('formNewModal');
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Information', 'Success Save Data');
      this.initialize();
    }, error => {
      this._notification.error('Information', 'Save data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  updateData() {
    this.isLoading = this._service.setLoading(true);
    this._service.put<APIResponse<GroupModel>>(APIROUTE.GROUP, this.group.id.toString(), this.group).subscribe((response) =>  {
      ModalExtension.Hide('formNewModal');
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Information', 'Success Save Data');
      this.initialize();
    }, error => {
      this._notification.error('Information', 'Save data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  onDelete(item: GroupModel) {
    this.isLoading = this._service.setLoading(true);
    this._service.delete<APIResponse<GroupModel>>(APIROUTE.GROUP, item.id.toString()).subscribe((response) =>  {
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Delete Data', 'Delete data success');
      this.initialize();
    }, error => {
      this._notification.error('Delete Data', 'Delete data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  onAddNewGroupMember() {
    this.isShowGroupMemberForm = true;
    this.key = 0;
    this.pageAction  = {enableActionAddNew: true, enableActionUpdate: true, enableActionDelete:true} as GridActionModel;
    ModalExtension.Show('formNewMemberModal');
  }

  onEditGroupMember(item:GroupModel) {
    this.group = ObjectExtension.Copy<GroupModel>(item);
    this.isShowGroupMemberForm = true;
    this.key = item.id;
    this.pageAction  = {enableActionAddNew: true, enableActionUpdate: true, enableActionDelete:true} as GridActionModel;
    ModalExtension.Show('formNewMemberModal');
  }

  onViewGroup(item:GroupModel) {
    this.group = ObjectExtension.Copy<GroupModel>(item);
    this.isShowGroupMemberForm = true;
    this.key = item.id;
    this.pageAction  = {enableActionAddNew: false, enableActionUpdate: false, enableActionDelete:false} as GridActionModel;
    ModalExtension.Show('formViewGroupModal');
  }

  onCloseGroupMember() {
    this.isShowGroupMemberForm = false;
    ModalExtension.Hide('formNewMemberModal');
  }

  onCloseViewGroup() {
    this.isShowGroupMemberForm = false;
    ModalExtension.Hide('formViewGroupModal');
  }

  onSubmitGroupMember() {
    Logger.info('onSubmitGroupMember', this.groupMembers);
    if(this.groupMembers.length < 1) {
      this._notification.warning('Information', CONSTANT_MESSAGE.SUBMIT_MIN_USER_IN_MEMBER);
      return;
    }

    this.isLoading = this._service.setLoading(true);
    this._service.post<APIResponse<GroupMemberModel>>(APIROUTE.GROUP_MEMBER, this.groupMembers).subscribe((response) =>  {
      ModalExtension.Hide('formNewMemberModal');
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Information', 'Success Save Data');
      this.initialize();
    }, error => {
      this._notification.error('Information', 'Save data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  onDataGroupMemberChange($event: any) {
    this.groupMembers = $event;
  }

  getArrayNumbers(value:number) {
    return new Array<number>(value);
  }

  onActionToogle(elementid: string, icon: string, title: string, action: string) {
    var title = "<i class='bx "+icon+" bx-xs' ></i> <span>"+title+"</span>";
    TooltipPopoverExtension.tooltip(elementid, true, title, action);
  }

  onPermittedDelete(item: GroupModel){
    var groupNotPermittedDelete = [1,2,3];
    var isNotPermitterd = groupNotPermittedDelete.filter(x => x == item.id).length > 0;
    return !isNotPermitterd;
  }
}
