import { BreadcumbResultModel } from './../models/breadcumb.model';
import { ServerApiModel } from './../models/server-configuration.model';
import { SERVER_ENVIRONMENT } from './parameter.configuration';
import { ObjectExtension } from '../extensions/object.extension';
import { ServerConfigurationModel } from '../models/server-configuration.model';
import * as serverConfigJson from '../../../assets/configurations/server.config.json';
import * as routeConfigJson from '../../../assets/configurations/route.config.json';
import { StringExtension } from '../extensions/string.extension';
import { CONSTANT_STORAGE } from '../constants/global.constant';
import { BreadcumbItemModel } from '../models/breadcumb.model';
import { NavigationModel } from '../models/navigation.model';
import { StorageConfiguration } from './storage.configuration';
// @dynamic
export class URLConfiguration {

  public static getBreadCumb(currentUrl: string) {
    var breadcumb = 'Home';
    var breadcumbLast = '';
    var breadcumbItemList = this.getBreadCumbFromNavigation();
    let breadcumbValues = breadcumbItemList.filter(x => x.routeName == currentUrl);
    if (currentUrl !== '') {
      if (currentUrl !== '/') {
        if (breadcumbValues.length > 0) {
          let queryBreadcumb = breadcumbValues[0].breadcumbName.split('/').filter(x => x != '');
          breadcumb = breadcumb + ' / ' + queryBreadcumb.join(' / ');
          if (queryBreadcumb.length > 1) {
            var lastIndex = queryBreadcumb.length - 1;
            breadcumbLast = queryBreadcumb[lastIndex];
            breadcumb = breadcumb.replace(breadcumbLast, '');
          }
        }
      }
    }

    return new BreadcumbResultModel(currentUrl, breadcumb, breadcumbLast);
  }

  public static parse(url: string) {
    let urlParams: string[];
    if (url.includes("?")) {
      url = url.substr(0, url.indexOf('?'));
    } else {
      urlParams = url.split('/').filter(x => x != '');
      if (urlParams.length > 1) {
        var urlSegments = urlParams.filter(segment => segment.length < 30);

        url = '/'+urlSegments.join('/');
      }
    }

    return url.replace('#','');
  }

  public static getBreadCumbFromNavigation() {
    var breadcumbItemList: BreadcumbItemModel[] = [];
    var tempNavigations = StorageConfiguration.getLocalData<NavigationModel[]>(CONSTANT_STORAGE.NAVIGATION);
    if (tempNavigations != null) {
      tempNavigations.forEach(nav => {
        if(nav.route !== null) {
          var item = {
            routeName: nav.route.replace('/:id', ''),
            breadcumbName: nav.breadcumb
          } as BreadcumbItemModel;
          breadcumbItemList.push(item);
          nav.childrens.forEach(nacChild => {
            if(nacChild.route !== null) {
              var itemChild = {
                routeName: nacChild.route.replace('/:id', ''),
                breadcumbName: nacChild.breadcumb
              } as BreadcumbItemModel;
              breadcumbItemList.push(itemChild);
            }
          });
        }
      })
    }

    return breadcumbItemList;
  }

}
