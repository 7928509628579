import { URLConfiguration } from './../../configurations/url.configuration';
import { map } from 'rxjs/operators';
import { NavigationModel } from './../../models/navigation.model';
import { CONSTANT_STORAGE } from './../../constants/global.constant';
import { StorageConfiguration } from './../../configurations/storage.configuration';
import { BreadcumbModel, BreadcumbItemModel } from './../../models/breadcumb.model';
import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcumb',
  templateUrl: './breadcumb.component.html'
})
export class BreadcumbComponent implements OnInit {
  breadcumb: string = 'Home';
  breadcumbLast: string = '';

  constructor(public _router: Router) { }

  ngOnInit(): void {
    this.getCurrentRoute();
  }

  getCurrentRoute() {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var url = URLConfiguration.parse(event.urlAfterRedirects);
        var breadCumbResult = URLConfiguration.getBreadCumb(url);
        this.breadcumb = breadCumbResult.breadcumb;
        this.breadcumbLast = breadCumbResult.breadcumbLast;
      }
    });
  }

}
