<div class="row">
  <h5 class="py-1">Penggunaan Asset</h5>
  <div class="col-12 mb-2">
    <button class="btn btn-sm btn-primary" type="button" id="button-addon2" (click)="onAddNew()"><i class="bx bx-add-to-queue"></i>&nbsp; Add New</button>
  </div>
  <div class="col-12">
    <div class="table-wrapper">
      <div class="table-responsive-sm">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th style="width: 2% !important;text-align: center !important;">Actions</th>
              <th>Image</th>
              <th>Kode Barang</th>
              <th>Nama Barang</th>
              <th>Registered No</th>
              <th>Luas (M2)</th>
              <th>Tahun Pengadaan</th>
              <th>Alamat</th>
              <th>Hak</th>
              <th>Tanggal Sertifikat</th>
              <th>No Sertifikat</th>
              <th>Penggunaan</th>
              <th>Asal Usul</th>
              <th>Harga</th>
              <th>Keterangan</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of assetKIBATemps; let no = index">
              <td style="width: 2% !important;text-align: center !important;">
                <a class="cursor-pointer" (click)="onEdit(item)"><i class="bx bx-edit-alt me-3"></i></a>
                <a class="cursor-pointer" (click)="onDelete(item)"><i class="bx bx-trash"></i></a>
              </td>
              <td><a [href]="parseImage(item.asset.image)" target="_blank">{{item.asset.image}}</a></td>
              <td class="td-text-small" style="width: 80px !important;">{{item.asset.kodebarang}}</td>
              <td class="td-text-small" style="min-width: 250px !important;">{{item.asset.namabarang}}</td>
              <td class="td-text-small" style="width: 150px !important;">{{item.asset.noregister}}</td>
              <td class="td-text-small" style="width: 100px !important;">{{item.asset.luas}}</td>
              <td class="td-text-small" class="text-center" style="width: 100px !important;">{{item.asset.tahunpengadaan}}</td>
              <td class="td-text-small" style="min-width: 250px !important;">{{item.asset.alamat}}</td>
              <td class="td-text-small" style="min-width: 100px !important;">{{item.asset.statushak}}</td>
              <td class="td-text-small" style="min-width: 100px !important;">{{item.asset.tanggalsertifikat | date}}</td>
              <td class="td-text-small" style="min-width: 150px !important;">{{item.asset.nosertifikat}}</td>
              <td class="td-text-small" style="width: 100px !important;">{{item.asset.penggunaan}}</td>
              <td class="td-text-small" style="min-width: 100px !important;">{{item.asset.asalusul}}</td>
              <td class="td-text-small" style="width: 100px !important;">{{item.asset.harga}}</td>
              <td class="td-text-small" style="min-width: 150px !important;">{{item.asset.keterangan}}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colspan="14" *ngIf="assetKIBATemps != undefined && assetKIBATemps.length < 1"><span class="text-muted text-center">Data Not Found</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="formNewAssetKIBAModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Penggunaan Asset</h5>
        <button type="button" class="btn-close" (click)="onCancel()"></button>
      </div>
      <div class="modal-body">
        <div [formGroup]="assetKIBAForm" class="needs-validation">
          <div class="row">
            <div class="col-4 mb-3">
              <div class="mb-3">
                <label for="kodebarang"><strong>Kode Barang</strong> &nbsp;<sup style="color: red;">*</sup></label>
                <input type="text" formControlName="kodebarang" class="form-control form-control-sm" placeholder="Put text here"
                  [ngClass]="{ 'is-invalid': assetKIBAForm.isInvalid(fieldAsset.kodebarang) }" required>
                <div *ngIf="assetKIBAForm.isValidate(fieldAsset.kodebarang, 'required')" class="invalid-feedback">
                  <div *ngIf="assetKIBAForm.isInvalid(fieldAsset.kodebarang)">Field is required!</div>
                </div>
              </div>
              <div class="mb-3">
                <label for="namabarang"><strong>Nama Barang</strong> &nbsp;<sup style="color: red;">*</sup></label>
                <input type="text" formControlName="namabarang" class="form-control form-control-sm" placeholder="Put text here"
                  [ngClass]="{ 'is-invalid': assetKIBAForm.isInvalid(fieldAsset.namabarang) }" required>
                <div *ngIf="assetKIBAForm.isValidate(fieldAsset.namabarang, 'required')" class="invalid-feedback">
                  <div *ngIf="assetKIBAForm.isInvalid(fieldAsset.namabarang)">Field is required!</div>
                </div>
              </div>
              <div class="mb-3">
                <label for="noregister"><strong>No Register</strong> &nbsp;<sup style="color: red;">*</sup></label>
                <input type="text" formControlName="noregister" class="form-control form-control-sm" placeholder="Put text here"
                  [ngClass]="{ 'is-invalid': assetKIBAForm.isInvalid(fieldAsset.noregister) }" required>
                <div *ngIf="assetKIBAForm.isValidate(fieldAsset.noregister, 'required')" class="invalid-feedback">
                  <div *ngIf="assetKIBAForm.isInvalid(fieldAsset.noregister)">Field is required!</div>
                </div>
              </div>
              <div class="mb-3">
                <label for="luas"><strong>Luas</strong> &nbsp;<sup style="color: red;">*</sup></label>
                <input type="text" formControlName="luas" class="form-control form-control-sm" placeholder="Put text here"
                  [ngClass]="{ 'is-invalid': assetKIBAForm.isInvalid(fieldAsset.luas) }" required>
                <div *ngIf="assetKIBAForm.isValidate(fieldAsset.luas, 'required')" class="invalid-feedback">
                  <div *ngIf="assetKIBAForm.isInvalid(fieldAsset.luas)">Field is required!</div>
                </div>
              </div>
            </div>
            <div class="col-4 mb-3">
              <div class="mb-3">
                <label for="tahunpengadaan"><strong>Tahun Pengadaan</strong> &nbsp;<sup style="color: red;">*</sup></label>
                <input type="text" formControlName="tahunpengadaan" class="form-control form-control-sm" placeholder="Put text here"
                  [ngClass]="{ 'is-invalid': assetKIBAForm.isInvalid(fieldAsset.tahunpengadaan) }" required>
                <div *ngIf="assetKIBAForm.isValidate(fieldAsset.tahunpengadaan, 'required')" class="invalid-feedback">
                  <div *ngIf="assetKIBAForm.isInvalid(fieldAsset.tahunpengadaan)">Field is required!</div>
                </div>
              </div>
              <div class="mb-3">
                <label for="address"><strong>Alamat</strong></label>
                <textarea id="note" formControlName="alamat" class="form-control form-control-sm" style="height: 30px !important;"></textarea>
              </div>
              <div class="mb-3">
                <label for="statushak"><strong>Hak</strong> &nbsp;<sup style="color: red;">*</sup></label>
                <input type="text" formControlName="statushak" class="form-control form-control-sm" placeholder="Put text here"
                  [ngClass]="{ 'is-invalid': assetKIBAForm.isInvalid(fieldAsset.statushak) }" required>
                <div *ngIf="assetKIBAForm.isValidate(fieldAsset.statushak, 'required')" class="invalid-feedback">
                  <div *ngIf="assetKIBAForm.isInvalid(fieldAsset.statushak)">Field is required!</div>
                </div>
              </div>
              <div class="mb-3">
                <label for="tanggalsertifikat"><strong>Tanggal Sertifikat</strong></label>
                <input type="text" formControlName="tanggalsertifikat" class="form-control form-control-sm" placeholder="Put text here">
              </div>
            </div>
            <div class="col-4 mb-3">
              <div class="mb-3">
                <label for="nosertifikat"><strong>No Sertifikat</strong></label>
                <input type="text" formControlName="lunosertifikatas" class="form-control form-control-sm" placeholder="Put text here">
              </div>
              <div class="mb-3">
                <label for="penggunaan"><strong>penggunaan</strong></label>
                <input type="text" formControlName="penggunaan" class="form-control form-control-sm" placeholder="Put text here">
              </div>
              <div class="mb-3">
                <label for="asalusul"><strong>Asal Usul</strong></label>
                <input type="text" formControlName="asalusul" class="form-control form-control-sm" placeholder="Put text here">
              </div>
              <div class="mb-3">
                <label for="harga"><strong>Harga</strong></label>
                <input type="text" formControlName="harga" class="form-control form-control-sm" placeholder="Put text here" maxlength="255">
              </div>
            </div>
            <div class="col-4 mb-3">
              <div class="mb-3">
                <label for="image"><strong>Image</strong></label>
                <div class="row mb-3" *ngIf="assetKIBA.image">
                  <img *ngIf="isFileFromSelector" [src]="assetKIBA.image | safeUrl" class="img-fluid" alt="..." style="max-width: 30%;">
                  <img *ngIf="!isFileFromSelector" [src]="parseImage(assetKIBA.image) | safeUrl" style="max-width: 30%;" class="img-fluid" alt="...">
                </div>
                <div class="row mb-3">
                  <div class="button-wrapper">
                    <label for="upload" class="btn btn-sm btn-primary me-2 mb-4" tabindex="0">
                      <span class="d-none d-sm-block">Import Image</span>
                      <i class="bx bx-upload d-block d-sm-none"></i>
                      <input type="file" id="upload" class="account-file-input" hidden="" accept="image/png, image/jpeg" (change)="onFileChange($event)">
                    </label>
                    <p class="text-muted mb-0">Allowed JPG, GIF or PNG.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 mb-3">
              <div class="mb-3">
                <label for="keterangan"><strong>Keterangan</strong></label>
                <textarea id="keterangan" formControlName="keterangan" class="form-control form-control-sm" style="height: 50px !important;"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="float-end">
          <!-- <button type="button" class="btn btn-secondary" style="margin-right: 5px !important;" (click)="onClose()">Close</button> -->
          <button type="button" class="btn btn-primary" (click)="onSubmit()">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
