<div class="card mb-4">
  <h5 class="card-header">Profile Details</h5>
  <!-- Account -->
  <!-- <div class="card-body">
    <div class="d-flex align-items-start align-items-sm-center gap-4">
      <img
        src="../assets/img/avatars/1.png"
        alt="user-avatar"
        class="d-block rounded"
        height="100"
        width="100"
        id="uploadedAvatar"
      />
      <div class="button-wrapper">
        <label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
          <span class="d-none d-sm-block">Upload new photo</span>
          <i class="bx bx-upload d-block d-sm-none"></i>
          <input
            type="file"
            id="upload"
            class="account-file-input"
            hidden
            accept="image/png, image/jpeg"
          />
        </label>
        <button type="button" class="btn btn-outline-secondary account-image-reset mb-4">
          <i class="bx bx-reset d-block d-sm-none"></i>
          <span class="d-none d-sm-block">Reset</span>
        </button>

        <p class="text-muted mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p>
      </div>
    </div>
  </div> -->
  <hr class="my-0" />
  <div class="card-body">
    <div [formGroup]="userForm" class="needs-validation">
      <div class="row">
        <div class="mb-3 col-md-6">
          <label class="form-label" for="basic-default-company">NIK &nbsp;<sup style="color: red;">*</sup></label>
          <input type="text" formControlName="nik" class="form-control" id="basic-default-company" readonly required
            [ngClass]="{ 'is-invalid': userForm.isInvalid(field.nik) }">
          <div *ngIf="userForm.isValidate(field.nik, 'required')" class="invalid-feedback">
            <div *ngIf="userForm.isInvalid(field.nik)">Field is required!</div>
          </div>
        </div>
        <div class="mb-3 col-md-6">
          <label for="firstName" class="form-label">Name &nbsp;<sup style="color: red;">*</sup></label>
          <input type="text" formControlName="name" class="form-control" id="basic-default-company" required
                [ngClass]="{ 'is-invalid': userForm.isInvalid(field.name) }">
          <div *ngIf="userForm.isValidate(field.name, 'required')" class="invalid-feedback">
            <div *ngIf="userForm.isInvalid(field.name)">Field is required!</div>
          </div>
        </div>
        <div class="mb-3 col-md-6">
          <label for="email" class="form-label">Phone</label>
          <input type="text" formControlName="phone" class="form-control" id="basic-default-company">
        </div>
        <div class="mb-3 col-md-6">
          <label for="email" class="form-label">Email &nbsp;<sup style="color: red;">*</sup></label>
          <input type="text" formControlName="email" class="form-control" id="basic-default-company" (change)="onInputEmailChange()" required
                [ngClass]="{ 'is-invalid': userForm.isInvalid(field.email) }">
              <div *ngIf="userForm.isValidate(field.email, 'required')" class="invalid-feedback">
                <div *ngIf="userForm.isInvalid(field.email)">Field is required!</div>
              </div>
        </div>
        <div class="mb-3 col-md-6">
          <label class="form-label" for="phoneNumber">Username &nbsp;<sup style="color: red;">*</sup></label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="defaultCheckisUseEmailForUserName" formControlName="isUseEmailForUserName">
            <label class="form-check-label" for="defaultCheckisUseEmailForUserName"> Use email for username </label>
          </div>
          <div *ngIf="!field.isUseEmailForUserName?.value">
            <input type="text" formControlName="username" class="form-control" id="basic-default-company"  required
            [ngClass]="{ 'is-invalid': !field.isUseEmailForUserName?.value && userForm.isInvalid(field.username) }">
            <div *ngIf="!field.isUseEmailForUserName?.value && userForm.isValidate(field.username, 'required')" class="invalid-feedback">
              <div *ngIf="!field.isUseEmailForUserName?.value && userForm.isInvalid(field.username)">Field is required!</div>
            </div>
          </div>
        </div>
        <div class="mb-3 col-md-6">
          <label for="address" class="form-label">Password &nbsp;<sup style="color: red;">*</sup></label>
          <input type="password" formControlName="password" class="form-control" id="basic-default-company" required
                [ngClass]="{ 'is-invalid': userForm.isInvalid(field.password) }">
              <div *ngIf="userForm.isValidate(field.password, 'required')" class="invalid-feedback">
                <div *ngIf="userForm.isInvalid(field.password)">Field is required!</div>
              </div>
        </div>
        <!-- <div class="mb-3 col-md-6">
          <label class="form-label" for="basic-default-company">Is Admin</label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="defaultCheck3" formControlName="isAdmin">
            <label class="form-check-label" for="defaultCheck3"> Set Permission Administrator </label>
          </div>
        </div> -->
        <!-- <div class="mb-3 col-md-6">
          <label for="state" class="form-label">Is Blocked &nbsp;<sup style="color: red;">*</sup></label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="defaultCheck3" formControlName="isBlocked">
          </div>
        </div> -->
      </div>
      <div class="mt-2">
        <div class="float-end">
          <button type="reset" class="btn btn-outline-secondary me-2" (click)="initialize()">Cancel</button>
          <button type="submit" class="btn btn-primary" (click)="onSubmit()">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  <!-- /Account -->
</div>
