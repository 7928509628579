import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  tabIndex: number = 1;
  constructor() { }

  ngOnInit(): void {
  }

  initialize() {
    this.tabIndex = 1;
  }

  onSelectTab(index: number) {
    this.tabIndex = index;
  }
}
