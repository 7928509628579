import { UserProfileModel } from "../app-core/models/user-profile.model";
import { AssetModel } from "./asset.model";
import { CategoryModel } from "./category.model";
import { StatusModel } from "./status.model";

export interface AssetUsedModel {
  id: number;
  asset_id: number;
  statusAsalUsul_id: number;
  statusHak_id: number;
  registeredNo: string;
  luas: string;
  price: string;
  yearProcurement: number;
  address: string;
  latitude: string;
  longtitude: string;
  cerificateDate: string;
  certificateNo: string;
  usedIn: string;
  image: string;
  note: string;
  isDeleted: boolean;
  createdDate: string;
  createdBy: number;
  modifiedDate: string;
  modifiedBy: number;
  statusHak: StatusModel;
  statusAsalUsul: StatusModel;
  asset: AssetModel;
  userProfileCreated: UserProfileModel;
  userProfileModified: UserProfileModel;
}


export class AssetUsedViewModel {
  constructor(_uniqueValue: string, _assetUsed: AssetUsedModel){
    this.uniqueValue = _uniqueValue;
    this.assetUsed = _assetUsed;
  }
  uniqueValue!: string;
  assetUsed!: AssetUsedModel
}
