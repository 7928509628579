import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";


@Injectable({
    providedIn: 'root',
})
export class PermissionsService {

    constructor(private _router: Router, private _authService: AuthService) { }

    //for Auth
    canAuthActivate(): boolean {
        if (this._authService.isLoggedIn) {
            this._router.navigate(['/']);
        }
        return !this._authService.isLoggedIn;
    }

    canActivate(url: string): boolean {
      console.log(this._authService.isLoggedIn)
        if (!this._authService.isLoggedIn) {
            this._router.navigate(['/login'], { queryParams: { returnUrl: url } });
        }
        return this._authService.isLoggedIn;
    }

}
