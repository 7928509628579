<div class="card">
  <div class="card-header d-flex align-items-center justify-content-between border-bottom">
    <h5 class="card-title m-0 me-2">Asset Management</h5>
    <div class="card-action-element">
      <ul class="list-inline mb-0">
        <li class="list-inline-item">
          <a (click)="onViewSearch()" class="card-reload cursor-pointer"><i class="tf-icons bx bx-search"></i></a>
        </li>
        <li class="list-inline-item">
          <a (click)="initialize()" class="card-reload cursor-pointer"><i class="tf-icons bx bx-rotate-left scaleX-n1-rtl"></i></a>
        </li>
        <li class="list-inline-item">
          <a (click)="onAddNew()" class="card-reload cursor-pointer text-primary"><i class="tf-icons bx bx-plus scaleX-n1-rtl"></i> Add New</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="card-body p-0">
    <div class="p-3" *ngIf="isShowSearch">
      <div class="input-group">
        <!-- <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search"></i></span> -->
        <input type="text" [(ngModel)]="filter.value" class="form-control" placeholder="Search Name..." aria-label="Search Name..." aria-describedby="basic-addon-search31">
        <button class="btn btn-outline-primary" type="button" (click)="onSearchChange($event)"><i class="bx bx-search"></i></button>
        <button class="btn btn-outline-danger" type="button" (click)="onClearSearch()"><i class="bx bx-trash"></i></button>
      </div>
    </div>
    <div class="table-wrapper">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead style="color: #696cff !important;background-color: rgba(105, 108, 255, 0.16) !important;">
            <tr>
              <th style="width: 2% !important;text-align: center !important;">No</th>
              <th class="text-center">Unit Organitation</th>
              <th class="text-center">Sub Unit Organitation</th>
              <th class="text-center">Category</th>
              <th class="text-center">Modified</th>
              <th class="text-center">Modified By</th>
              <th style="width: 2%  !important;text-align: center !important;" *ngIf="isAdministratorPrivillage">Actions</th>
            </tr>
          </thead>
          <tbody class="table-border-bottom-0">
            <tr *ngFor="let item of assets; let no = index">
              <td style="width: 2% !important;text-align: center !important;">{{no+1}}</td>
              <td class="text-start">
                <span>{{item.subUnitOrganitation.unitorganitation.name}}</span>
              </td>
              <td class="text-start">
                <span>{{item.subUnitOrganitation.name}}</span>
              </td>
              <td class="text-start">
                <span>{{item.category.code}} - {{item.category.name}}</span>
              </td>
              <td class="text-center text-muted"><small>{{(item.userProfileModified ? item.modifiedDate : item.createdDate) | date:'yyyy-MM-dd'}}</small></td>
              <td class="text-muted"><small>{{item.userProfileModified ? item.userProfileModified.name : item.userProfileCreated.name}}</small></td>
              <td style="width: 2%  !important;text-align: center !important;" *ngIf="isAdministratorPrivillage">
                <div class="dropdown">
                  <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <i class="bx bx-dots-vertical-rounded"></i>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" (click)="onViewDetail(item)"><i class="bi bi-eye me-1"></i> View Detail</a>
                    <a class="dropdown-item" (click)="onEdit(item)"><i class="bx bx-edit-alt me-1"></i> Edit</a>
                    <a class="dropdown-item" (click)="onDelete(item)"><i class="bx bx-trash me-1"></i> Delete</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div>
    <app-pagination
        [pagination]="pagination"
        (onFirstPageChange)="onFirstPage($event)"
        (onPrevPageChange)="onPrevPage($event)"
        (onNextPageChange)="onNextPage($event)"
        (onLastPageChange)="onLastPage($event)"
        (onLimitPageChange)="onLimitPage($event)">
      </app-pagination>
  </div>
</div>
