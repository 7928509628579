// @dynamic
export class LayoutConfiguration {

  public static setTheme() {
    localStorage.setItem('templateCustomizer-vertical-menu-template--Style', 'light');
    localStorage.setItem('templateCustomizer-vertical-menu-template--LayoutType', 'fixed');
    localStorage.setItem('templateCustomizer-vertical-menu-template--Theme', 'theme-semi-dark');
  }

  public static hideLoaderScreen() {
    var loadScreen = document.getElementById('load_screen');
    if (loadScreen !== null) {
      loadScreen.style.display = 'none';
    }
  }

  public static toggleCollapsed() {
    var animate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    this.setCollapsed(!this.isCollapsed());
  }

  public static setCollapsed(collapsed: any) {
    if (this.isSmallScreen()) {
      if (collapsed) {
        this.removeClass('layout-menu-expanded');
      } else {
        setTimeout(() => {
          this.addClass('layout-menu-expanded');
        }, this.redrawLayoutMenu() ? 5 : 0);
      }
    }
  }

  public static isCollapsed() {
    if (this.isSmallScreen()) {
      return !this.hasClass('layout-menu-expanded');
    }

    return this.hasClass('layout-menu-collapsed');
  }

  public static isSmallScreen() {
    return (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < this.LAYOUT_BREAKPOINT;
  }

  public static hasClass(cls: any) {
    var el = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.ROOT_EL;
    var result = false;

    cls.split(' ').forEach((c: any) => {
      if (el.classList.contains(c)) result = true;
    });

    return result;
  }

  public static addClass(cls: any) {

    var el = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.ROOT_EL;
    if (el.length !== undefined) {
      // Add classes to multiple elements
      el.forEach((e: any) => {
        cls.split(' ').forEach((c: any) => {
          return e.classList.add(c);
        });
      });
    } else {
      // Add classes to single element
      cls.split(' ').forEach((c: any) => {
        return el.classList.add(c);
      });
    }
  }

  public static removeClass(cls: any) {
    var el = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.ROOT_EL;
    if (el.length !== undefined) {
      // Remove classes to multiple elements
      el.forEach((e: any) => {
        cls.split(' ').forEach((c: any) => {
          return e.classList.remove(c);
        });
      });
    } else {
      // Remove classes to single element
      cls.split(' ').forEach((c: any) => {
        return el.classList.remove(c);
      });
    }
  }

  public static redrawLayoutMenu() {
    var layoutMenu = this.getLayoutMenu();
    if (layoutMenu) {
      var inner = this.getMenuInner();
      if (inner != null) {
        var scrollTop = inner.scrollTop;
        var pageScrollTop = document.documentElement.scrollTop;
        layoutMenu.style.display = 'none';
        layoutMenu.style.display = '';
        inner.scrollTop = scrollTop;
        document.documentElement.scrollTop = pageScrollTop;
        return true;
      }
    }

    return false;
  }

  public static setMenuScroll() {
    var inner = this.getMenuInner();
    if (inner != null) {
      if(!inner.classList.contains('overflow-auto')){
        inner.classList.add('overflow-auto');
      }
    }
  }

  public static getLayoutMenu() {
    return document.getElementById('layout-menu');
  }

  public static getMenuInner() {
    return document.getElementById('menu-inner');
  }

  private static LAYOUT_BREAKPOINT = 1200;
  private static ROOT_EL = typeof window !== 'undefined' ? document.documentElement : null;
}
