import { UserProfileModel } from './../../models/user-profile.model';
import { CONSTANT_MESSAGE } from 'src/app/app-core/constants/global.constant';
import { UserModel } from 'src/app/app-core/models/user.model';
import { GroupMemberModel } from './../../models/group-member.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { APIROUTE } from 'src/app/app-view/api-route.config';
import { ModalExtension } from '../../extensions/bootstrap/modal.extension';
import { ObjectExtension } from '../../extensions/object.extension';
import { StringExtension } from '../../extensions/string.extension';
import { APIResponse } from '../../models/api-response.model';
import { GroupModel } from '../../models/group.model';
import { HttpService } from '../../services/http.service';
import { NotificationService } from '../../services/notification.service';
import { Logger } from '../../utilities/logger.utility';
import { NavigationModel } from '../../models/navigation.model';
import { GridActionModel } from '../../models/general.model';

@Component({
  selector: 'app-group-member',
  templateUrl: './group-member.component.html',
  styleUrls: ['./group-member.component.css']
})
export class GroupMemberComponent implements OnInit {
  groupMembers: GroupMemberModel[] = [] as GroupMemberModel[];
  groupMember: GroupMemberModel = {} as GroupMemberModel;
  groups: GroupModel[] = [] as GroupModel[];
  group: GroupModel = {} as GroupModel;
  users: UserProfileModel[] = [] as UserProfileModel[];
  userMembers: UserProfileModel[] = [] as UserProfileModel[];
  user: UserProfileModel = {} as UserProfileModel;
  isLoading: boolean = false;
  submitted: boolean = false;
  @Input() pageAction: GridActionModel = {} as GridActionModel;
  @Input() selectedGroup: GroupModel = {} as GroupModel;
  @Output() onDataChange: EventEmitter<GroupMemberModel[]> = new EventEmitter<GroupMemberModel[]>();

  constructor(public _service: HttpService, public _notification: NotificationService) {
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.groupMembers = [] as GroupMemberModel[];
    this.groupMember = { id: 0, group_id: 0, user_id: 0 } as GroupMemberModel;
    this.groupMember.group = { id: 0, name: '', description: '', isDeleted: false, created_by: 0, created: '', modified_by: 0, modified: '' } as GroupModel;
    this.groupMember.user = { id: 0, name: '', nik: '', phone: '', email: '', isAdmin: false, isDeleted: false, createdBy: '0', createdDate: '', modifiedBy: '0', modifiedDate: '' } as UserProfileModel;
    this.groupMember.navigations = [] as NavigationModel[];
    this.groups = [] as GroupModel[];
    this.group = { id: 0, name: '', description: '', isDeleted: false, created_by: 0, created: '', modified_by: 0, modified: '' } as GroupModel;
    this.group.user_created = {} as UserProfileModel;
    this.group.user_modified = {} as UserProfileModel;
    this.users = [] as UserProfileModel[];
    this.userMembers = [] as UserProfileModel[];
    this.user = { id: 0, name: '', nik: '', phone: '', email: '', isAdmin: false, isDeleted: false, createdBy: '0', createdDate: '', modifiedBy: '0', modifiedDate: '' } as UserProfileModel;
    this.submitted = false;
    this.getData();
  }

  getData() {
    this.isLoading = this._service.setLoading(true);
    this._service.get<APIResponse<GroupMemberModel[]>>(APIROUTE.GROUP_MEMBER).subscribe((response) => {
      this.groupMembers = response.data.filter(x => x.group_id === this.selectedGroup.id);
      this.isLoading = this._service.setLoading(false, true);
      if(this.pageAction.enableActionAddNew || this.pageAction.enableActionUpdate || this.pageAction.enableActionDelete){
        this.getDataUsers();
      }else{
        this.setDataToView();
      }
    }, error => {
      this.groupMembers = [] as GroupMemberModel[];
      this.userMembers = [] as UserProfileModel[];
      this.submitted = false;
      this.isLoading = this._service.setLoading(false);
    });
  }

  getDataUsers() {
    this.isLoading = this._service.setLoading(true);
    this._service.post<APIResponse<UserProfileModel[]>>(APIROUTE.GROUP_MEMBER_USER_NO_MEMBER).subscribe((response) => {
      this.users = response.data;
      this.setDataToForm();
      this.isLoading = this._service.setLoading(false);
    }, error => {
      this.users = [] as UserProfileModel[];
      this.submitted = false;
      this.setDataToForm();
      this.isLoading = this._service.setLoading(false);
    });
  }

  setDataToForm() {
    if (this.pageAction.enableActionUpdate) {
      this.groupMembers.forEach(member => {
        if(!member.user.isDeleted){
          this.userMembers.push(member.user);
          var user = this.users.filter(x => x.id == member.user.id);
          if (ObjectExtension.Any(user)) {
            this.users.splice(this.users.indexOf(user[0]), 1);
          }
        }
      });
      this.group = this.selectedGroup;
      this.groupMember.group_id = this.group.id;
      this.groupMember.group = this.group;
      var members = this.groupMembers.filter(x => x.group_id == this.group.id && !x.user.isDeleted);
      if (ObjectExtension.Any(members)) {
        this.groupMember = members[0];
      }
    }
  }

  setDataToView() {
    this.isLoading = this._service.setLoading(true);
    this.groupMembers.forEach(member => {
      if(!member.user.isDeleted){
        this.userMembers.push(member.user);
      }
    });
    this.group = this.selectedGroup;
    this.groupMember.group_id = this.group.id;
    this.groupMember.group = this.group;
    var members = this.groupMembers.filter(x => x.group_id == this.group.id);
    if (ObjectExtension.Any(members)) {
      this.groupMember = members[0];
    }
    this.isLoading = this._service.setLoading(false);
  }

  onSubmit() {
    this.submitted = true;
    if (StringExtension.IsNullOrEmpty(this.group.name)) {
      this._notification.warning('Save Data', 'Please fill mandatory field!');
      return;
    }

    if (!this.group) {
      throw Error('Data Model, Data Model undefined')
    }

    this.insertData();
  }

  insertData() {
    this.isLoading = this._service.setLoading(true);
    this._service.post<APIResponse<GroupModel>>(APIROUTE.GROUP_MEMBER, this.groupMember).subscribe((response) => {
      ModalExtension.Hide('formNewModal');
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Information', 'Success Save Data');
      this.initialize();
    }, error => {
      this._notification.error('Information', 'Save data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  onChangeDropdown($event: any, type: number) {
    switch (type) {
      case 1:
        this.groupMember.group_id = parseInt($event);
        this.userMembers = [];
        var members = this.groupMembers.filter(x => x.group_id == this.groupMember.group_id);
        members.forEach(member => {
          this.userMembers.push(member.user);
        });
        this.group = this.groups.filter(x => x.id == this.groupMember.group_id)[0];
        this.groupMember.group = this.group;
        break;
      case 2:
        this.groupMember.user_id = parseInt($event);
        var userNew = this.users.filter(x => x.id === this.groupMember.user_id);
        if (ObjectExtension.Any(userNew)) {
          this.user = userNew[0];
          this.groupMember.user = this.user;
        }
        break;
    }
  }

  onAddNewMember() {
    this.submitted = true;

    if (this.groupMember.user_id == 0) {
      this._notification.warning('Add Member', 'Please fill mandatory field!');
      return;
    }

    // var member = this.groupMembers.filter(x => x.group_id == this.groupMember.group_id && x.user_id == this.groupMember.user_id);
    // if (ObjectExtension.Any(member)) {
    //   this.groupMembers.splice(this.groupMembers.indexOf(member[0]), 1);
    // }

    var userMember = this.userMembers.filter(x => x.id == this.groupMember.user_id);
    if (ObjectExtension.Any(userMember)) {
      this.userMembers.splice(this.userMembers.indexOf(userMember[0]), 1);
    }

    var user = this.users.filter(x => x.id == this.groupMember.user_id);
    if (ObjectExtension.Any(user)) {
      this.users.splice(this.users.indexOf(user[0]), 1);
    }

    this.userMembers.push(this.groupMember.user);

    this.groupMembers = [];
    this.userMembers.forEach(member => {
      var groupMember = {
        id: 0,
        group_id: this.groupMember.group_id,
        user_id: member.id,
        group: this.groupMember.group,
        user: member
      } as GroupMemberModel;
      this.groupMembers.push(groupMember);
    });
    this.onDataChange.emit(this.groupMembers);
    this.reset();
  }

  onRemoveMember(index: number, user: UserProfileModel) {
    // if(this.userMembers.length == 1) {
    //   this._notification.warning('Information', CONSTANT_MESSAGE.DELETE_MIN_USER_IN_MEMBER);
    //   return;
    // }
    this.removeMember(index, user);
    this.users.push(user);
    this.reset();
    this.onDataChange.emit(this.groupMembers);
  }

  removeMember(index: number, user: UserProfileModel) {
    var selectedIndex = this.userMembers.indexOf(user);
    this.userMembers.splice(selectedIndex, 1);
    var groupId = this.groupMember.group_id;
    var member = this.groupMembers.filter(x => x.group_id == groupId && x.user_id == user.id);
    if (ObjectExtension.Any(member)) {
      var selectedGroupMembersIndex = this.groupMembers.indexOf(member[0]);
      this.groupMembers.splice(selectedGroupMembersIndex, 1);
    }
  }

  reset() {
    // this.groupMember = { id: 0, group_id: 0, user_id: 0, can_create: false, can_update: false, can_delete: false, can_view: true, } as GroupMemberModel;
    // this.groupMember.group = { id: 0, name: '', description: '', isDeleted: false, created_by: 0, created: '', modified_by: 0, modified: '' } as GroupModel;
    // this.groupMember.user = { id: 0, name: '', nik: '', phone: '', email: '', isAdmin: false, isDeleted: false, createdBy: '0', createdDate: '', modifiedBy: '0', modifiedDate: '' } as UserProfileModel;
    // this.groupMember.navigations = [] as NavigationModel[];
    // this.group = { id: 0, name: '', description: '', isDeleted: false, created_by: 0, created: '', modified_by: 0, modified: '' } as GroupModel;
    // this.group.user_created = {} as UserProfileModel;
    // this.group.user_modified = {} as UserProfileModel;
    //this.user = { id: 0, name: '', nik: '', phone: '', email: '', isAdmin: false, isDeleted: false, createdBy: '0', createdDate: '', modifiedBy: '0', modifiedDate: '' } as UserProfileModel;
    // this.pageAction = new GridActionModel(false, false, false, false, false);
    ModalExtension.Hide('formNewModal');
  }
}
