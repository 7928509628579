  <div class="bs-stepper wizard-numbered mt-2">
    <div class="overflow-auto">
      <div class="bs-stepper-header">
        <ng-container *ngFor="let item of steppers; let no = index">
          <div class="step" [ngClass]="{'active': stepperId === item.id}">
            <button type="button" class="step-trigger" aria-selected="true">
              <span class="bs-stepper-circle">{{item.number}}</span>
              <span class="bs-stepper-label mt-1">
                <span class="bs-stepper-title">{{item.text}}</span>
                <span class="bs-stepper-subtitle">{{item.textDetail}}</span>
              </span>
            </button>
          </div>
          <div class="line" *ngIf="steppers.length !== (no+1)">
            <i class="bx bx-chevron-right"></i>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="bs-stepper-content">
      <div class="content-header mb-3">
        <h6 class="mb-0">{{stepper.text}}</h6>
        <small>{{stepper.textDetail}}</small>
      </div>
      <div class="row g-3">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
