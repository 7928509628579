<div class="row">
  <h5 class="py-1">Penggunaan Asset</h5>
  <div class="col-12">
    <div class="table-wrapper">
      <div class="table-responsive-sm">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th rowspan="2" class="text-md-center text-black">No</th>
              <!-- <th rowspan="2" class="text-md-center text-black">Image</th> -->
              <th rowspan="2" class="text-md-center text-black">Kode Barang</th>
              <th rowspan="2" class="text-md-center text-black">Nama Barang</th>
              <th rowspan="2" class="text-md-center text-black">Registered No</th>
              <th rowspan="2" class="text-md-center text-black">Luas (M2)</th>
              <th rowspan="2" class="text-md-center text-black">Tahun Pengadaan</th>
              <th rowspan="2" class="text-md-center text-black">Alamat</th>
              <th colspan="3" class="text-md-center text-black">Sertifikat</th>
              <th rowspan="2" class="text-md-center text-black">Penggunaan</th>
              <th rowspan="2" class="text-md-center text-black">Asal Usul</th>
              <th rowspan="2" class="text-md-center text-black">Harga</th>
              <th rowspan="2" class="text-md-center text-black">Keterangan</th>
            </tr>
            <tr>
              <th class="text-center">Hak</th>
              <th class="text-center">Tanggal</th>
              <th class="text-center">Nomor</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of assetKIBATemps; let no = index">
              <td class="td-text-small text-center" style="width: 50px !important;">{{no+1}}</td>
              <!-- <td class="td-text-small" style="width: 50px !important;"><a [href]="parseImage(item.asset.image)" target="_blank">{{item.asset.image}}</a></td> -->
              <td class="td-text-small" style="width: 80px !important;">{{item.asset.kodebarang}}</td>
              <td class="td-text-small" style="min-width: 250px !important;">{{item.asset.namabarang}}</td>
              <td class="td-text-small" style="width: 150px !important;">{{item.asset.noregister}}</td>
              <td class="td-text-small" style="width: 100px !important;">{{item.asset.luas}}</td>
              <td class="td-text-small" class="text-center" style="width: 100px !important;">{{item.asset.tahunpengadaan}}</td>
              <td class="td-text-small" style="min-width: 250px !important;">{{item.asset.alamat}}</td>
              <td class="td-text-small" style="min-width: 100px !important;">{{item.asset.statushak}}</td>
              <td class="td-text-small" style="min-width: 100px !important;">{{item.asset.tanggalsertifikat | date}}</td>
              <td class="td-text-small" style="min-width: 150px !important;">{{item.asset.nosertifikat}}</td>
              <td class="td-text-small" style="width: 100px !important;">{{item.asset.penggunaan}}</td>
              <td class="td-text-small" style="min-width: 100px !important;">{{item.asset.asalusul}}</td>
              <td class="td-text-small" style="width: 100px !important;">{{item.asset.harga}}</td>
              <td class="td-text-small" style="min-width: 150px !important;">{{item.asset.keterangan}}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colspan="12" *ngIf="assetKIBATemps != undefined && assetKIBATemps.length < 1"><span class="text-muted text-center">Data Not Found</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
