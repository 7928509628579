<h4 class="fw-bold py-3 mb-2">Group Management</h4>
<p>A group provided access to predefined menus and features so that depending on <br> assigned role an administrator can have access to what user needs.</p>
<div class="demo-inline-spacing">
  <button type="button" (click)="onAddNew()" class="btn btn-primary">Add New</button>
  <!-- <button type="button" (click)="onAddNewGroupMember()" class="btn btn-secondary">Add Group Member</button> -->
</div>
<hr>
<div class="row g-4">
  <div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let item of groups; let no = index">
    <div class="card">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h6 class="card-title me-2 ps-2 pt-2">Total <strong>{{item.total_member}}</strong> users</h6>
        <div class="dropdown">
          <button type="button" class="btn p-0" data-bs-toggle="dropdown">
            <i class="bx bx-dots-vertical-rounded"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end">
            <a class="dropdown-item" (click)="onViewGroup(item)"><i class="bx bxs-eyedropper me-1"></i> View Group</a>
            <a class="dropdown-item" (click)="onEdit(item)"><i class="bx bx-edit-alt me-1"></i> Update Group</a>
            <a *ngIf="onPermittedDelete(item)" class="dropdown-item" (click)="onDelete(item)"><i class="bx bx-trash me-1"></i> Delete Group</a>
            <a class="dropdown-item" (click)="onEditGroupMember(item)"><i class="bx bxs-user-plus me-1"></i> Set Members</a>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <div class="card-title d-flex align-items-start justify-content-between">
          <h6 class="fw-normal">Total <strong>{{item.total_member}}</strong> users</h6>
          <ul class="list-unstyled d-flex align-items-center">
            <li id="actiontoogle-setmember-{{no}}"
              (mouseover)="onActionToogle('actiontoogle-setmember-'+no, 'bxs-user-plus', 'Set Members', 'show')"
              (mouseleave)="onActionToogle('actiontoogle-setmember-'+no, 'bxs-user-plus', 'Set Members', 'hide')">
              <a class="cursor-pointer link-primary" (click)="onEditGroupMember(item)"><i class="bx bxs-user-plus me-2"></i></a>
            </li>
            <li id="actiontoogle-editgroup-{{no}}"
            (mouseover)="onActionToogle('actiontoogle-editgroup-'+no, 'bxs-edit-alt', 'Update Group', 'show')"
            (mouseleave)="onActionToogle('actiontoogle-editgroup-'+no, 'bxs-edit-alt', 'Update Group', 'hide')">
              <a class="cursor-pointer link-primary" (click)="onEdit(item)"><i class="bx bx-edit-alt me-2"></i></a>
            </li>
            <li *ngIf="onPermittedDelete(item)" id="actiontoogle-deletegroup-{{no}}"
            (mouseover)="onActionToogle('actiontoogle-deletegroup-'+no, 'bxs-trash', 'Delete Group', 'show')"
            (mouseleave)="onActionToogle('actiontoogle-deletegroup-'+no, 'bxs-trash', 'Delete Group', 'hide')">
              <a class="cursor-pointer" (click)="onDelete(item)" class="text-danger"><i class="bx bx-trash"></i></a>
            </li>
          </ul>
        </div>
        <div class="d-flex justify-content-between align-items-end">
          <div class="role-heading">
            <h4 class="mb-1">{{item.name}}</h4>
            <small class="text-muted mb-1">{{item.description}}</small>
          </div>
        </div> -->
        <div class="d-flex justify-content-between align-items-end">
          <div class="role-heading">
            <h6 class="mb-1">{{item.name}}</h6>
            <small class="text-muted mb-1">{{item.description}}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="card">
  <div class="card-header d-flex align-items-center justify-content-between border-bottom">
    <h5 class="card-title m-0 me-2">
      Group Management
    </h5>
    <div class="card-action-element">
      <ul class="list-inline mb-0">
        <li class="list-inline-item">
          <a (click)="initialize()" class="card-reload cursor-pointer"><i class="tf-icons bx bx-rotate-left scaleX-n1-rtl"></i></a>
        </li>
      </ul>
    </div>
  </div>
  <div class="card-body p-0">
    <div class="table-wrapper">
      <div class="table-responsive">
        <table class="table table-sm table-bordered table-striped table-hover">
          <thead style="color: #696cff !important;background-color: rgba(105, 108, 255, 0.16) !important;">
            <tr>
              <th class="remove-spacing text-center" style="width: 2% !important;text-align: center !important;">No</th>
              <th class="remove-spacing text-center">Name</th>
              <th class="remove-spacing text-center">Description</th>
              <th class="remove-spacing text-center" style="width: 10% !important;">Total Member</th>
              <th class="remove-spacing text-center" style="width: 15% !important;">Modified By</th>
              <th class="remove-spacing text-center" style="width: 10% !important;">Modified</th>
              <th class="remove-spacing text-center" style="width: 2% !important;">Actions</th>
            </tr>
          </thead>
          <tbody class="table-border-bottom-0">
            <tr *ngFor="let item of groups; let no = index">
              <th class="remove-spacing text-center" style="width: 2% !important;">{{no+1}}</th>
              <td>{{item.name}}</td>
              <td>{{item.description.length > 30 ? (item.description.substring(0, 29) + '...') : item.description}}</td>
              <td class="remove-spacing text-center">{{item.total_member}}</td>
              <td>{{(item.modified == null ? item.user_created.name : item.user_modified.name)}}</td>
              <td class="remove-spacing text-center">{{(item.modified == null ?item.created:item.modified) | date:'yyyy-MM-dd'}}</td>
              <td class="remove-spacing text-center" style="width: 2%  !important;">
                <div class="dropdown">
                  <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <i class="bx bx-dots-vertical-rounded"></i>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" (click)="onEditGroupMember(item)"><i class="bx bxs-user-plus me-1"></i> Set Member</a>
                    <hr class="dropdown-divider">
                    <a class="dropdown-item" (click)="onEdit(item)"><i class="bx bx-edit-alt me-1"></i> Edit</a>
                    <a class="dropdown-item" (click)="onDelete(item)"><i class="bx bx-trash me-1"></i> Delete</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card-footer demo-inline-spacing">
    <button type="button" (click)="onAddNew()" class="btn btn-primary">Add New</button>
  </div>
</div> -->

<div class="modal fade" id="formNewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">User Management</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="needs-validation">
          <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-end" for="basic-default-company"></label>
            <div class="col-sm-10">
              <div class="form-label"><strong>Permission</strong></div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="defaultCheck1" [(ngModel)]="group.can_create">
                <label class="form-check-label" for="defaultCheck1"> Create </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="defaultCheck2" [(ngModel)]="group.can_update">
                <label class="form-check-label" for="defaultCheck2"> Update </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="defaultCheck3" [(ngModel)]="group.can_delete">
                <label class="form-check-label" for="defaultCheck3"> Delete </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="defaultCheck4" [(ngModel)]="group.can_view">
                <label class="form-check-label" for="defaultCheck4"> View </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="defaultCheck4" [(ngModel)]="group.can_view_mobile">
                <label class="form-check-label" for="defaultCheck4"> Mobile Access </label>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-end" for="basic-default-company"><strong>Name</strong> &nbsp;<sup style="color: red;">*</sup></label>
            <div class="col-sm-10">
              <input type="text" [(ngModel)]="group.name" class="form-control" id="basic-default-company" maxlength="50" required
                [ngClass]="{ 'is-invalid': submitted && group.name == '' }">
              <div *ngIf="submitted && group.name == ''" class="invalid-feedback">
                <div *ngIf="group.name == ''">Field is required!</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-end" for="basic-default-company"><strong>Description</strong></label>
            <div class="col-sm-10">
              <textarea [(ngModel)]="group.description" class="form-control form-control-sm" style="height: 150px !important;"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="float-end">
          <button type="button" class="btn btn-primary" (click)="onSubmit()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="formNewMemberModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">
          Group Member
          <div class="form-text">
            <strong>Group</strong> : {{group.name}}
          </div>
        </h5>
        <button type="button" class="btn-close" (click)="onCloseGroupMember()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <app-group-member *ngIf="isShowGroupMemberForm" [pageAction]="pageAction" [selectedGroup]="group"  (onDataChange)="onDataGroupMemberChange($event)"></app-group-member>
      </div>
      <div class="modal-footer">
        <div class="float-end">
          <button type="button" class="btn btn-primary" (click)="onSubmitGroupMember()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="formViewGroupModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Group</h5>
        <button type="button" class="btn-close" (click)="onCloseViewGroup()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row mb-2">
          <label class="col-sm-2 col-form-label text-end" for="basic-default-company"><strong>Name</strong></label>
          <div class="col-sm-10 pt-2">{{group.name}}</div>
        </div>
        <div class="row mb-3">
          <label class="col-sm-2 col-form-label text-end" for="basic-default-company"><strong>Description</strong></label>
          <div class="col-sm-10 pt-2">{{group.description}}</div>
        </div>
        <hr>
        <div class="row mb-3">
          <div class="col-12">
            <div class="form-label"><strong>Permission</strong></div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="defaultCheck1" [(ngModel)]="group.can_create" disabled>
              <label class="form-check-label" for="defaultCheck1"> Create </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="defaultCheck2" [(ngModel)]="group.can_update" disabled>
              <label class="form-check-label" for="defaultCheck2"> Update </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="defaultCheck3" [(ngModel)]="group.can_delete" disabled>
              <label class="form-check-label" for="defaultCheck3"> Delete </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="defaultCheck4" [(ngModel)]="group.can_view" disabled>
              <label class="form-check-label" for="defaultCheck4"> View </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="defaultCheck4" [(ngModel)]="group.can_view_mobile" disabled>
              <label class="form-check-label" for="defaultCheck4"> Mobile Access </label>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <app-group-member *ngIf="isShowGroupMemberForm" [pageAction]="pageAction" [selectedGroup]="group"  (onDataChange)="onDataGroupMemberChange($event)"></app-group-member>
        </div>
      </div>
    </div>
  </div>
</div>
