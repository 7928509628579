import { AuthService } from './app-core/services/auth.service';
import { APPConfiguration } from './app-core/configurations/app-config.configuration';
import { CONSTANT_APPCONFIG_PARAM } from './app-core/constants/global.constant';
import { LayoutConfiguration } from './app-core/configurations/layout.configuration';
import { ObjectExtension } from './app-core/extensions/object.extension';
import { APIResponse } from './app-core/models/api-response.model';
import { HttpService } from './app-core/services/http.service';
import { NavigationModel } from './app-core/models/navigation.model';
import { UserProfileModel } from './app-core/models/user-profile.model';
import { StorageConfiguration } from './app-core/configurations/storage.configuration';
import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'checkin_airline';
  currentRoute = 'dashboard';
  breadcumb: string = 'Home';
  breadcumbLast: string = '';
  companyName: string = '';
  appVersion: string = '';
  isShowActiveparentMenu: boolean = false;
  userProfile: UserProfileModel = {} as UserProfileModel;
  navigations: NavigationModel[] = [] as NavigationModel[];
  isLoading: boolean = true;

  constructor(private _service: HttpService, private _router: Router, private _authService: AuthService) { }

  ngOnInit() {
    this.userProfile = {} as UserProfileModel;
    this.getAppConfiguration();
    this.getUserLogin();
    LayoutConfiguration.hideLoaderScreen();
  }

  onBreadcumbChange($event: any) {
    this.breadcumb = $event;
  }

  get isLoggedIn() {
    return this._authService.isLoggedIn;
  }

  onLogout() {
    this._authService.signOut();
  }

  getUserLogin() {
    if (this.isLoggedIn) {
      var userInfo = this._authService.getAuthInformation();
      if (userInfo) this.userProfile = userInfo.user.userProfile;
    }
  }

  toggleCollapsed() {
    LayoutConfiguration.toggleCollapsed();
  }

  getAppConfiguration(){
    this.appVersion = 'App Version : '+ APPConfiguration.getConfiguration(CONSTANT_APPCONFIG_PARAM.APP_VERSION);
    this.companyName = '© 2022 ' + APPConfiguration.getConfiguration(CONSTANT_APPCONFIG_PARAM.COMPANY_NAME);
  }
}
