import { PaginationModel } from './../models/api-response.model';

export abstract class PaginationBase {

  pagination: PaginationModel = {
    page: 1,
    count: 0,
    nextPage: 0,
    previousPage: 0,
    totalPage: 0,
    limit: 10,
  } as PaginationModel;

  onFirstPage(pagination: PaginationModel) {
    this.pagination = pagination;
  }

  onNextPage(pagination: PaginationModel) {
    this.pagination = pagination;
  }

  onPrevPage(pagination: PaginationModel) {
    this.pagination = pagination;
  }

  onLastPage(pagination: PaginationModel) {
    this.pagination = pagination;
  }

  onLimitPage(pagination: PaginationModel) {
    this.pagination = pagination;
  }
}
