import { StorageConfiguration } from '../configurations/storage.configuration';
import { CONSTANT_STORAGE } from '../constants/global.constant';
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';    // for Spring Boot back-end
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HttpService } from '../services/http.service';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  constructor(private _httpService: HttpService,private _authService: AuthService, private _router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(

      catchError((error: HttpErrorResponse) => {
          //if unauthorized
          if (error.status === 401) {
              return this.handle401Error(req, next, error);
          } else {
              console.error(`Error Code: ${error.status},  Message: ${error.message}`);
              return throwError(() => error);
          }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, errorMessage: HttpErrorResponse){
    if (!this.isRefreshing) {
        this.isRefreshing = true;

        //if unauthorized and user currently logged
        if (this._authService.isLoggedIn) {
            //try to refresh token
            return this._authService.refreshToken().pipe(
                switchMap(() => {
                    this.isRefreshing = false;
                    return next.handle(request);
                }),
                catchError((error) => {
                    this.isRefreshing = false;
                    if (error.status === 401){
                        this._authService.signOut();
                    }

                    console.error(`Error Code: ${error.status},  Message: ${error.message}`);

                    return throwError(() => error);
                })
            );
        }
    }

    return next.handle(request);
  }

}

export const bearerInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: BearerInterceptor, multi: true }
];
