import { FormAirport } from './../default-view/stepper/stepper.model';
import { Stepper } from 'src/app/app-core/default-view/stepper/stepper.model';
import { CryptographyUtility } from './../utilities/cryptography.utility';
import { NavigationModel } from './../models/navigation.model';
import { CONSTANT_STORAGE } from '../constants/global.constant';
import { UserLoginResultModel } from '../models/user.model';
// @dynamic

export class StorageConfiguration {

  public static getLocalData<T>(key: string): T | null {
    var dataEncrypted = window.sessionStorage.getItem(key);
    if (dataEncrypted) {
      var dataDecrypted = CryptographyUtility.decryption<T>(dataEncrypted);
      return dataDecrypted;
    }
    return null;
  }

  public static getDataTempAsset<T>(keyName: string): T | null {
    var dataEncrypted = window.sessionStorage.getItem(keyName);
    if (dataEncrypted) {
      var dataDecrypted = CryptographyUtility.decryption<T>(dataEncrypted);
      return dataDecrypted;
    }
    return null;
  }

  public static saveLocalData(key: string, items: any): void {
    window.sessionStorage.removeItem(key);

    var dataEncrypted = CryptographyUtility.encryption(JSON.stringify(items));
    window.sessionStorage.setItem(key, dataEncrypted);
  }

  public static saveTempAsset(items: any, keyName: string): void {
    window.sessionStorage.removeItem(keyName);

    var dataEncrypted = CryptographyUtility.encryption(JSON.stringify(items));
    window.sessionStorage.setItem(keyName, dataEncrypted);
  }

  public static get getDataAssetStepper(): Stepper[] {
    var formConfig = StorageConfiguration.getLocalData<FormAirport>(CONSTANT_STORAGE.FORM_ASSET_STEPPER);
    if(formConfig == null) return [];
    var form = formConfig;
    return form.steppers;
  }
}
