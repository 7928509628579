import { UserLoginResultModel } from './../models/user.model';
import { CryptographyUtility } from './../utilities/cryptography.utility';
import { CONSTANT_STORAGE } from './../constants/global.constant';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable, tap } from 'rxjs';
import { APIROUTE } from 'src/app/app-view/api-route.config';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private _cookieService: CookieService, private _httpService: HttpService){}

  public get isLoggedIn(): boolean {
    return this.getToken() ? true : false;
  }

  public getToken(): string | null {
    var dataEncrypted = this._cookieService.get(CONSTANT_STORAGE.TOKEN_KEY);
    if(dataEncrypted) {
      // var dataDecrypted = CryptographyUtility.decryptionString(dataEncrypted);
      return dataEncrypted;
    }

    return null;
  }

  public get isAdministratorPrivillage(): boolean {
    if(this.isLoggedIn) {
      var auth = this.getAuthInformation();
      if(auth != null){
        return auth.user.groupmember.group_id == 1 ||
               auth.user.groupmember.group.can_create ||
               auth.user.groupmember.group.can_update ||
               auth.user.groupmember.group.can_delete;
      }
    }
    return false;
  }

  public getAuthInformation(): UserLoginResultModel | null {
    var dataEncrypted = this._cookieService.get(CONSTANT_STORAGE.AUTH_INFORMATION_KEY);
    if (dataEncrypted) {
      var dataDecrypted = CryptographyUtility.decryption<UserLoginResultModel>(dataEncrypted);
      return dataDecrypted;
    }
    return null;
  }

  public saveToken(token: string): void {
    this._cookieService.delete(CONSTANT_STORAGE.TOKEN_KEY);

    var dataEncrypted = CryptographyUtility.encryption(token);
    this._cookieService.set(CONSTANT_STORAGE.TOKEN_KEY, token, 1);
  }

  public saveAuthInformation(user: UserLoginResultModel): void {
    this._cookieService.delete(CONSTANT_STORAGE.AUTH_INFORMATION_KEY);

    var dataEncrypted = CryptographyUtility.encryption(JSON.stringify(user));
    this._cookieService.set(CONSTANT_STORAGE.AUTH_INFORMATION_KEY, dataEncrypted, 1, undefined, undefined, true, undefined);
  }

  public clearAuth(): void {
    this._cookieService.deleteAll();
  }

  public signOut(): void {
    localStorage.clear();
    sessionStorage.clear();
    this._cookieService.deleteAll();
    window.location.reload();
  }

  public refreshToken(): Observable<any>{
      return this._httpService.get<any>(APIROUTE.USER_PROFILE).pipe(
          tap((tokens: any) => {
          //this.storeTokens(tokens);
      }));
  }
}
