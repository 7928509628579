import { StringExtension } from './string.extension';
import { GridExtensionModel, GridPaginationParamModel, GridFilterParamModel } from './../models/grid-extension.model';
// @dynamic
export class GridExtension {
  private pagingParam: GridPaginationParamModel | null | undefined;
  private filterParam: GridFilterParamModel | null | undefined;

  paging(pageIndex: number, pageSize: number): GridExtension {
    this.pagingParam = new GridPaginationParamModel(pageIndex, pageSize);
    return this;
  }

  filter(field: string, value: string): GridExtension {
    this.filterParam = new GridFilterParamModel(field, value);
    return this;
  }

  build() {
    var gridParam: GridExtensionModel = {} as GridExtensionModel;

    if(this.pagingParam){
      gridParam.paging = this.pagingParam;
    }

    if(this.filterParam){
      gridParam.filter = this.filterParam;
    }

    var queryParams = [];
    var queryOption = '';

    if(gridParam.paging) {
      if(gridParam.paging.pageSize > 0) {
        queryParams.push('page='+gridParam.paging.pageIndex);
        queryParams.push('limit='+gridParam.paging.pageSize);
      }
    }
    if(gridParam.filter) {
      if(!StringExtension.IsNullOrEmpty(gridParam.filter.value)) {
        queryParams.push('filterBy=('+gridParam.filter.field+','+gridParam.filter.value+')');
      }
    }

    if(queryParams.length > 0) {
      queryOption = '?'+queryParams.join('&');
    }

    return queryOption;
  }

}
