// @dynamic
export class ObjectExtension {
  public static IsNullOrEmpty(value: any): boolean {
    if (value == undefined) return true;
    else if (value == null) return true;
    else return false;
  }
  public static Any(values: any[]): boolean {
    if (this.IsNullOrEmpty(values)) return false;
    else if (values.length < 1) return false;
    else if (values.length > 0) return true;
    else return true;
  }

  public static Count(values: any[]): number {
    if (!this.Any(values)) return 0;

    return values.length;
  }

  public static GroupBy(array: Array<any>) {
    var groupings: Array<string> = [];
    array.forEach(x => {
      var isExisting = groupings.filter(y => y.toLowerCase() == x.toLowerCase()).length > 0;
      if(!isExisting) {
        groupings.push(x);
      }
    });
    return groupings;
  }

  public static Copy<T>(item: T) {
    return JSON.parse(JSON.stringify(item)) as T;
  }
}
