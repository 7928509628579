<div class="container-xxl">
  <div class="authentication-wrapper authentication-basic container-p-y">
    <div class="authentication-inner">
      <!-- Register -->
      <div class="card">
        <div class="card-body">
          <!-- Logo -->
          <div class="app-brand justify-content-center">
            <a href="index.html" class="app-brand-link gap-2">
              <span class="app-brand-logo demo">
                <img
                  width="130"
                  alt="Company Logo"
                  src="./assets/images/logo.png"
                />
              </span>
            </a>
          </div>
          <!-- /Logo -->
          <h4 class="mb-2">Welcome! 👋</h4>
          <p class="mb-4">Please sign-in to your account</p>

          <form id="formAuthentication" class="mb-3" action="index.html" method="POST">
            <div class="mb-3">
              <label for="email" class="form-label">Email or Username</label>
              <input
                type="text"
                class="form-control"
                id="emailLogin"
                name="email-username"
                placeholder="Enter your email or username"
                [(ngModel)]="userLoginParam.username"
                autofocus
              />
            </div>
            <div class="mb-3">
              <div class="d-flex justify-content-between">
                <label class="form-label" for="password">Password</label>
                <!-- <a href="auth-forgot-password-basic.html">
                  <small>Forgot Password?</small>
                </a> -->
              </div>
              <div class="input-group input-group-merge">
                <input
                  [type]="inputType"
                  id="passwordLogin"
                  class="form-control"
                  name="password"
                  placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                  aria-describedby="password"
                  [(ngModel)]="userLoginParam.password"
                />
                <a class="input-group-text cursor-pointer" (click)="onShowOrHidePassword()"><i class="bx " [ngClass]="inputIconType"></i></a>
              </div>
            </div>
            <!-- <div class="mb-3">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="remember-me" />
                <label class="form-check-label" for="remember-me"> Remember Me </label>
              </div>
            </div> -->
            <div class="mb-3">
              <button [attr.disabled]="isLoading ? true : null" class="btn btn-primary d-grid w-100" type="submit" (click)="onSignIn()">Sign in</button>
            </div>
          </form>
        </div>
      </div>
      <!-- /Register -->
    </div>
  </div>
</div>
