import { UserProfileModel } from './../../../app-core/models/user-profile.model';
import { Component, OnInit } from '@angular/core';
import { APIResponse, PaginationModel } from 'src/app/app-core/models/api-response.model';
import { AuthService } from 'src/app/app-core/services/auth.service';
import { HttpService } from 'src/app/app-core/services/http.service';
import { NotificationService } from 'src/app/app-core/services/notification.service';
import { AssetModel } from 'src/app/app-model/asset.model';
import { APIROUTE } from '../../api-route.config';
import { GridFilterParamModel } from 'src/app/app-core/models/grid-extension.model';
import { AssetPresenter } from './../asset.presenter';
import { AssetUsedModel, AssetUsedViewModel } from 'src/app/app-model/assetused.model';
import { ModalExtension } from 'src/app/app-core/extensions/bootstrap/modal.extension';
import { ObjectExtension } from 'src/app/app-core/extensions/object.extension';
import { GridExtension } from 'src/app/app-core/extensions/grid.extension';
import { CategoryModel } from 'src/app/app-model/category.model';
import { UnitOrganitationModel } from 'src/app/app-model/unitorganitation.model';
import { SubunitorganitationModel } from 'src/app/app-model/subunitorganitation.model';
import { PageContextModel, URLUtility } from 'src/app/app-core/utilities/url.utility';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, catchError, forkJoin, map } from 'rxjs';
import { Stepper } from 'src/app/app-core/default-view/stepper/stepper.model';
import { StorageConfiguration } from 'src/app/app-core/configurations/storage.configuration';
import { StatusModel } from 'src/app/app-model/status.model';
import { ServerConfiguration } from 'src/app/app-core/configurations/server.configuration';
import * as uuid from 'uuid';
import { AssetKIBAModel } from 'src/app/app-model/assetkiba.model';
import { CONSTANT_STORAGE } from 'src/app/app-core/constants/global.constant';

@Component({
  selector: 'app-asset-form',
  templateUrl: './asset-form.component.html',
  styleUrls: ['./asset-form.component.css']
})
export class AssetFormComponent extends AssetPresenter implements OnInit {
  steppers: Array<Stepper> = StorageConfiguration.getDataAssetStepper;
  formStepId: number = 1;
  maxFormStepId: number = 2;
  urlContext: URLUtility | undefined;
  pageParam: PageContextModel = {} as PageContextModel;
  asset_Id: string = '';
  unitOrganitation: UnitOrganitationModel = {} as UnitOrganitationModel;
  categories: CategoryModel[] = [] as CategoryModel[];
  unitOrganitations: UnitOrganitationModel[] = [] as UnitOrganitationModel[];
  subUnitOrganitationTemps: SubunitorganitationModel[] = [] as SubunitorganitationModel[];
  subUnitOrganitations: SubunitorganitationModel[] = [] as SubunitorganitationModel[];
  status: StatusModel[] = [] as StatusModel[];
  assetKIBAs: AssetKIBAModel[] = [] as AssetKIBAModel[];
  assetusedTemps: AssetUsedViewModel[] = [] as AssetUsedViewModel[];
  isLoading: boolean = false;
  isAdministratorPrivillage: boolean = false;
  isShowSearch: boolean = false;
  filter: GridFilterParamModel = {} as GridFilterParamModel;
  isFileFromSelector: boolean = false;
  isShowKIBA: boolean = false;
  file: File | null = null;

  constructor(private _service: HttpService, public _notification: NotificationService, private _authService: AuthService, private _router: Router, private _actRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.urlContext = new URLUtility(this._router, this._actRoute);
    this.pageParam = this.urlContext.getParam('id');
    this.asset_Id = this.pageParam.param;
    this.isAdministratorPrivillage = this._authService.isAdministratorPrivillage;
    this.initialize();
  }

  initialize() {
    this.assets = [] as AssetModel[];
    this.asset = {id:0, subUnitOrganitation_id:0, category_id:0} as AssetModel;
    this.assetuseds = [] as AssetUsedModel[];
    this.assetused = {} as AssetUsedModel;
    this.categories = [] as CategoryModel[];
    this.unitOrganitations = [] as UnitOrganitationModel[];
    this.subUnitOrganitations = [] as SubunitorganitationModel[];
    this.subUnitOrganitationTemps = [] as SubunitorganitationModel[];
    this.status = [] as StatusModel[];
    this.unitOrganitation = {id: 0} as UnitOrganitationModel;
    this.assetusedTemps = [] as AssetUsedViewModel[];
    this.assetKIBAs = [] as AssetKIBAModel[];

    this.isShowKIBA = false;
    this.isFileFromSelector = false;
    this.isLoading = false;
    this.initializeForm();
  }

  initializeForm() {
    this.initializeParamater();
    if (this.pageParam.isPageEdit) this.initializeFormEdit();
  }

  initializeParamater() {
    this.isLoading = this._service.setLoading(true);
    var httpParameters = forkJoin([this.getCategory(), this.getUnitOrganitation(), this.getSubUnitOrganitation(), this.getStatus()]);
    httpParameters.subscribe({
      next: ([responseCategory, responseUnit, responseSubUnit, responseStatus]) => {
        this.categories = responseCategory.data;
        this.unitOrganitations = responseUnit.data;
        this.subUnitOrganitationTemps = responseSubUnit.data;
        this.status = responseStatus.data;
        var subUnitOrganitationTemp = this.subUnitOrganitationTemps[0]
        var asset = {
          id: 0,
          subUnitOrganitation_id: subUnitOrganitationTemp.id,
          subUnitOrganitation: subUnitOrganitationTemp,
          category_id: 0,
          category: {} as CategoryModel,
          isDeleted: false,
          createdDate: '',
          createdBy: 0,
          modifiedBy: 0,
          modifiedDate: '',
          userProfileCreated: {} as UserProfileModel,
          userProfileModified: {} as UserProfileModel
        } as AssetModel
        this.initializeAsset(asset);
        this.isLoading = this._service.setLoading(false);
      },
      error: (error) => {
        this.isLoading = this._service.setLoading(false);
      }
    });
  }

  initializeFormEdit() {
    console.log('initializeFormEdit');
    this.isLoading = this._service.setLoading(true);
    this.getAsset(parseInt(this.asset_Id)).subscribe({
      next: (response) => {
        this.initializeAsset(response.data);
        this.isLoading = this._service.setLoading(false);
      },
      error: (error) => {
        this.isLoading = this._service.setLoading(false);
      }
    });
  }

  initializeAsset(item: AssetModel) {
    this.asset = item;
    this.isShowKIBA = item.category.code == 'KIB A';
    this.subUnitOrganitations = this.subUnitOrganitationTemps.filter(x => x.id == item.subUnitOrganitation.unitOrganitation_id);
    this.setFieldAssetValues(item);
    this.initializeAssetKIB(item);
  }

  initializeAssetKIB(asset: AssetModel) {
    this.isLoading = this._service.setLoading(true);
    var httpAssetResponses = forkJoin([this.getAssetKIBA(asset.id)]);
    httpAssetResponses.subscribe({
      next: ([responseAssetKIBA]) => {
        this.initializeAssetKIBA(responseAssetKIBA.data);
        console.log('onChangeKIB Finish');
        this.isLoading = this._service.setLoading(false);
      },
      error: (error) => {
        this.isLoading = this._service.setLoading(false);
      }
    });
  }

  initializeAssetKIBA(items: AssetKIBAModel[]) {
    this.assetKIBAs = items;
    StorageConfiguration.saveTempAsset(this.assetKIBAs, CONSTANT_STORAGE.ASSETKIBA);
  }

  onSubmit() {
    this.assetForm.submitted = true;
    if (this.assetForm.invalid) {
      this._notification.warning('Save Data', 'Please fill mandatory field!');
      return;
    }

    // this.assetusedForm.submitted = true;
    // if (this.assetusedForm.invalid) {
    //   this._notification.warning('Save Data', 'Please fill mandatory field!');
    //   return;
    // }

    this.getFieldValue();

    if (!this.asset) {
      throw Error('Data Model, Data Model undefined')
    }

    if (!this.assetKIBAs) {
      throw Error('Data Model, Data Model undefined')
    }
    console.log(this.asset, this.assetKIBAs);
    var payload = {
      "asset": this.asset,
      "KIBA": this.assetKIBAs,
    }

    console.log(payload);
    this.isInsertAssetData ? this.insertData(payload) : this.updateData(payload);
  }

  insertData(payload: any) {
    this.isLoading = this._service.setLoading(true);
    this.asset.isDeleted = false;
    this._service.post<APIResponse<AssetModel>>(APIROUTE.ASSET, payload).subscribe((response) => {
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Information', 'Success Save Data');
      this.onCancel();
    }, error => {
      this._notification.error('Information', 'Save data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  updateData(payload: any) {
    this.isLoading = this._service.setLoading(true);
    this.asset.isDeleted = false;
    this._service.postPut<APIResponse<AssetModel>>(APIROUTE.ASSET, this.asset.id.toString(), payload).subscribe((response) => {
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Information', 'Success Save Data');
      this.onCancel();
    }, error => {
      this._notification.error('Information', 'Save data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  onChangeUnit(event: any) {
    var id = event.target.value;
    this.subUnitOrganitations = this.subUnitOrganitationTemps.filter(x => x.id == id);
  }

  onChangeKIB(event: any) {
    var id = event.target.value;
    this.isLoading = this._service.setLoading(true);
    this.getAssetByKIB(parseInt(id)).subscribe({
      next: (response) => {
        this.initializeAsset(response.data);
        this.isLoading = this._service.setLoading(false);
      },
      error: (error) => {
        this.isLoading = this._service.setLoading(false);
      }
    });
  }

  showByKIB() {
    var isShowKIBA = false;
    var categories = this.categories.filter(x => x.id == this.fieldAsset.category_id?.value);
    if(categories.length > 0){
      if(categories[0].code == 'KIB A') isShowKIBA = true;
    }

    return isShowKIBA;
  }

  get buildQueryOption() {
    var gridExtension = new GridExtension();
    return gridExtension.paging(this.pagination.page, this.pagination.limit).filter(this.filter.field, this.filter.value).build();
  }

  onBack() {
    if (this.formStepId !== 1) {
      this.formStepId = this.formStepId - 1;
    }
  }
  onStepNext() {

    // if(this.formStepId == 1){
    //   this.assetForm.submitted = true;
    //   if (this.assetForm.invalid) {
    //     this._notification.warning('Save Data', 'Please fill mandatory field!');
    //     return;
    //   }
    // }

    if (this.maxFormStepId !== this.formStepId) {
      this.formStepId = this.formStepId + 1;
    }
  }

  onDataChange($event:any, type: number) {
    switch(type) {
      case 1:
        this.assetKIBAs = $event;
        StorageConfiguration.saveTempAsset(this.assetKIBAs, CONSTANT_STORAGE.ASSETKIBA);
        break;
    }
  }

  onCancel = () => this.urlContext?.redirect('/asset/');
  getAsset = (id: number) => this._service.find<APIResponse<AssetModel>>(APIROUTE.ASSET, id.toString());
  getAssetByKIB = (id: number) => this._service.find<APIResponse<AssetModel>>(APIROUTE.ASSET_KIB, id.toString());
  getAssetUsed = (assetId: number) => this._service.find<APIResponse<AssetUsedModel[]>>(APIROUTE.ASSETUSED_ASSET, assetId.toString());
  getAssetKIBA = (assetId: number) => this._service.find<APIResponse<AssetKIBAModel[]>>(APIROUTE.ASSETKIBA_ASSET, assetId.toString());
  getCategory = () => this._service.get<APIResponse<CategoryModel[]>>(APIROUTE.CATEGORY);
  getUnitOrganitation = () => this._service.get<APIResponse<UnitOrganitationModel[]>>(APIROUTE.UNITORGANITATION);
  getSubUnitOrganitation = () => this._service.get<APIResponse<SubunitorganitationModel[]>>(APIROUTE.SUBUNITORGANITATION);
  getStatus = () => this._service.get<APIResponse<StatusModel[]>>(APIROUTE.STATUS);
}

