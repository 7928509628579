export const APIROUTE = {
  NAVIGATION : "navigation",
  UPLOAD_FILE : "uploadFile",
  UPLOAD_FILE_FROM_EDITOR : "uploadfromeditor",
  AUTH_LOGIN : "login",
  AUTH_LOGIN_INFORMATION : "loginInformation",
  USER_PROFILE : "userprofile",
  USER_PROFILE_VALIDATE : "userprofilevalidate",
  USER_ACCESS : "useraccess",
  GROUP: 'group',
  GROUP_MEMBER: 'groupmember',
  GROUP_VALIDATE_FORM: 'groupvalidateform',
  GROUP_MEMBER_USER_NO_MEMBER: 'groupmember_usernotinmember',
  APP_CONFIG: 'appconfig',
  DASHBOARD_DATA_COUNT: 'getdatacountdashboard',
  ASSET: 'asset',
  ASSETUSED: 'assetused',
  ASSETUSED_ASSET: 'assetused_asset',
  CATEGORY: 'category',
  BIDANG: 'bidang',
  KABKOTA: 'kabkota',
  BIDANG_KABKOTA: 'bidang_kabkota',
  KABKOTA_PROVINCE: 'kabkota_province',
  PROVINCE: 'province',
  REGION: 'region',
  STATUS: 'status',
  UNITORGANITATION: 'unitorganitation',
  SUBUNITORGANITATION: 'subunitorganitation',
  ASSETKIBA: 'assetkiba',
  ASSETKIBA_ASSET: 'assetkiba_asset',
  ASSET_KIB: 'asset_kib'
}

export const UPLOADMODULE = {
  DEFAULT: 0,
}
