import { AuthService } from './app-core/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { APP_VIEW_COMPONENTS } from './app-view/app-view.declaration';
import { LoginActivate } from './app-core/services/login-activate.service';
import { DEFAULT_VIEW_COMPONENTS } from './app-core/default-view/default-view.declaration';
import { bearerInterceptorProviders } from './app-core/interceptors/bearer.interceptor';
import { SafeHtmlPipe, SafeUrlPipe } from './app-core/common/safe-html.pipe';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppCoreModule } from './app-core/app-core.module';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientXsrfModule } from '@angular/common/http';

import { NgxSpinnerModule } from "ngx-spinner";
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ToastrModule } from 'ngx-toastr';
import { initializeAppFactory } from './app-core/common/initialize-app.factory';
import { SearchPipe } from './app-core/common/search.pipe';
import { HttpRequestInterceptorProviders } from './app-core/interceptors/http-request.interceptor';
import { AssetComponent } from './app-view/asset/asset.component';
import { AssetFormComponent } from './app-view/asset/asset-form/asset-form.component';
import { AssetkibaFormComponent } from './app-view/asset/assetkiba/assetkiba-form/assetkiba-form.component';
import { AssetkibaViewComponent } from './app-view/asset/assetkiba/assetkiba-view/assetkiba-view.component';
import { AssetViewComponent } from './app-view/asset/asset-view/asset-view.component';

@NgModule({
  declarations: [
    AppComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    SearchPipe,
    ...DEFAULT_VIEW_COMPONENTS,
    ...APP_VIEW_COMPONENTS,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'My-Xsrf-Cookie',
      headerName: 'My-Xsrf-Header',
    }),
    AppRoutingModule,
    AppCoreModule,
    NgxSpinnerModule,
    AngularEditorModule,
    ToastrModule.forRoot({
      newestOnTop: true,
      tapToDismiss: true,
      timeOut: 3000,
      extendedTimeOut: 1000,
      positionClass: 'toast-top-right',
    }),
    SweetAlert2Module.forRoot()
  ],
  providers: [
    bearerInterceptorProviders,
    HttpRequestInterceptorProviders,
    LoginActivate,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [HttpClient, AuthService],
      multi: true
    },
    CookieService,

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
