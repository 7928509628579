import { StorageConfiguration } from 'src/app/app-core/configurations/storage.configuration';
import { HttpService } from './../../app-core/services/http.service';
import { Component, OnInit } from '@angular/core';
import { ProvinceModel } from 'src/app/app-model/province.model';
import { KabkotaModel } from 'src/app/app-model/kabkota.model';
import { BidangModel } from 'src/app/app-model/bidang.model';
import { GridActionModel } from 'src/app/app-core/models/general.model';
import { NotificationService } from 'src/app/app-core/services/notification.service';
import { AuthService } from 'src/app/app-core/services/auth.service';
import { RegionModel } from 'src/app/app-model/region.model';
import { APIResponse } from 'src/app/app-core/models/api-response.model';
import { APIROUTE } from '../api-route.config';
import { ModalExtension } from 'src/app/app-core/extensions/bootstrap/modal.extension';
import { ObjectExtension } from 'src/app/app-core/extensions/object.extension';
import { StringExtension } from 'src/app/app-core/extensions/string.extension';


@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent implements OnInit {
  provinces: Array<ProvinceModel> = [] as ProvinceModel[];
  kabkotas: Array<KabkotaModel> = [] as KabkotaModel[];
  bidangs: Array<BidangModel> = [] as BidangModel[];
  region: RegionModel = {} as RegionModel;
  actionGrid: GridActionModel = {} as GridActionModel;
  provinceId: number = 0;
  kabkotaid: number = 0;
  bidangid: number = 0;
  searchProvince: string = '';
  searchKabKota: string = '';
  searchBidang: string = '';
  isShowSearchProvince: boolean = false;
  isShowsearchBidang: boolean = false;
  isShowsearchKabKota: boolean = false;
  isLoading: boolean = false;
  submitted: boolean = false;
  duplicatData: boolean = false;
  actionType: number = 0;
  isAdministratorPrivillage: boolean = false;

  constructor(private _service: HttpService, public _notification:NotificationService,private _authService: AuthService) { }

  ngOnInit(): void {
    this.isAdministratorPrivillage = this._authService.isAdministratorPrivillage;
    this.initialize();
  }

  initialize() {
    this.provinces = [] as ProvinceModel[];
    this.kabkotas = [] as KabkotaModel[];
    this.bidangs = [] as BidangModel[];
    this.region = {} as RegionModel;
    this.region.province = {id:0, name: ''} as ProvinceModel;
    this.region.kabkota = {id:0, name: '', province_id:0} as KabkotaModel;
    this.region.bidang = {id:0, name: '', kabKota_id:0} as BidangModel;
    this.provinceId = 0;
    this.kabkotaid = 0;
    this.bidangid = 0;
    this.searchProvince = '';
    this.searchKabKota = '';
    this.searchBidang = '';
    this.actionGrid = new GridActionModel(true, true, false, false, true);
    this.submitted = false;
    this.duplicatData = false;
    this.isShowSearchProvince = false;
    this.isShowsearchBidang = false;
    this.isShowsearchKabKota = false;
    this.getDataProvince();
  }

  getDataProvince() {
    this.isLoading = this._service.setLoading(true);
    this._service.get<APIResponse<ProvinceModel[]>>(APIROUTE.PROVINCE).subscribe((response) =>  {
      this.provinces = response.data;
      if(this.provinces.length > 0){
        var provinceId = this.provinces[0].id;
        this.getDataKabkotaByProvinceId(provinceId);
      }
      this.isLoading = this._service.setLoading(false);
    }, error => {
      this.isLoading = this._service.setLoading(false);
    });
  }

  getDataKabkotaByProvinceId(provinceId: number) {
    this.provinceId = provinceId;
    this.region.province = this.provinces.filter(x => x.id == provinceId)[0];
    this.isLoading = this._service.setLoading(true);
    this._service.get<APIResponse<KabkotaModel[]>>(APIROUTE.KABKOTA_PROVINCE, provinceId.toString()).subscribe((response) =>  {
      this.kabkotas = response.data;
      if(this.kabkotas.length > 0){
        var kabkotaid = this.kabkotas[0].id;
        this.getDataBidangByKabkotaid(kabkotaid);
      }
      this.isLoading = this._service.setLoading(false);
    }, error => {
      this.kabkotas = [] as KabkotaModel[];
      this.bidangs = [] as BidangModel[];
      this.bidangid = 0;
      this.kabkotaid = 0;
      this.isLoading = this._service.setLoading(false);
    });
  }

  getDataBidangByKabkotaid(kabkotaid: number) {
    this.kabkotaid = kabkotaid;
    this.region.kabkota = this.kabkotas.filter(x => x.id == kabkotaid)[0];
    this.isLoading = this._service.setLoading(true);
    this._service.get<APIResponse<BidangModel[]>>(APIROUTE.BIDANG_KABKOTA, kabkotaid.toString()).subscribe((response) =>  {
      this.bidangs = response.data;
      this.isLoading = this._service.setLoading(false);
    }, error => {
      this.bidangs = [] as BidangModel[];
      this.kabkotaid = 0;
      this.isLoading = this._service.setLoading(false);
    });
  }

  onSelectedBidang(bidangid: number) {
    this.bidangid = bidangid;
    this.region.bidang = this.bidangs.filter(x => x.id == bidangid)[0];
  }

  hasMenuActive(type: number, id: number): string {
    if(type == 1) return this.provinceId == id ? 'table-active':'';
    if(type == 2) return this.bidangid == id ? 'table-active':'';
    if(type == 3) return this.kabkotaid == id ? 'table-active':'';
    else return '';
  }

  onAddNew(type:number) {
    this.submitted = false;
    this.actionType = type;
    switch(type) {
      case 1:
        this.region.province = {id:0, name: ''} as ProvinceModel;
        break;
      case 2:
        this.region.kabkota = {id:0, name: '', province_id:this.region.province.id} as KabkotaModel;
        break;
      case 3:
        this.region.bidang = {id:0, name: '', kabKota_id:this.region.kabkota.id} as BidangModel;
        break;
    }

    ModalExtension.Show('formNewModal');
  }

  onEdit(id: number, type: number) {
    this.actionType = type;
    this.submitted = false;

    this.resetRegion(this.actionType);

    ModalExtension.Show('formNewModal');
  }

  onSubmit() {
    this.submitted = true;
    if(this.actionType === 1){
      if(StringExtension.IsNullOrEmpty(this.region.province.name)) {
        this._notification.warning('Info', 'Field Province cannot be empty');
        return;
      }else{
        var existing = this.provinces.filter(x => x.name.toLowerCase().trim() === this.region.province.name.toLowerCase().trim());
        if(ObjectExtension.Any(existing)) {
          this.duplicatData = true;
          this._notification.warning('Information', 'Data province sudah tersedia, silahkan memasukan data lainnya');
          return;
        }
      }
    }
    if(this.actionType === 2){
      if(StringExtension.IsNullOrEmpty(this.region.kabkota.name)) {
        this._notification.warning('Info', 'Field Kab/Kota cannot be empty');
        return;
      }else{
        var existingDistrict = this.kabkotas.filter(x => x.name.toLowerCase().trim() === this.region.kabkota.name.toLowerCase().trim());
        if(ObjectExtension.Any(existingDistrict)) {
          this.duplicatData = true;
          this._notification.warning('Information', 'Data Kab/Kota sudah tersedia, silahkan memasukan data lainnya');
          return;
        }
      }
    }

    if(this.actionType === 3){
      if(StringExtension.IsNullOrEmpty(this.region.bidang.name)) {
        this._notification.warning('Info', 'Field Bidang cannot be empty');
        return;
      }else{
        var existinbidang = this.bidangs.filter(x => x.name.toLowerCase() === this.region.bidang.name.toLowerCase());
        if(ObjectExtension.Any(existinbidang)) {
          this.region.bidang = existinbidang[0];
        }
      }
    }

    this.insert();
  }

  insert() {
    this.isLoading = this._service.setLoading(true);
    this._service.post(APIROUTE.REGION, this.region).subscribe((response) =>  {
      ModalExtension.Hide('formNewModal');
      this._notification.success('Info', 'Insert Data success');
      this.isLoading = this._service.setLoading(false);
      this.initialize();
    }, error => {
      this._notification.error('Info', 'Insert Data failed');
      this.isLoading = this._service.setLoading(false);
    });
  }

  onCancel() {
    this.resetRegion(this.actionType);
    this.submitted = false;
    this.duplicatData = false;
    ModalExtension.Hide('formNewModal');
  }

  resetRegion(type:number){
    switch(type) {
      case 1:
        this.region.province = {id:0, name: ''} as ProvinceModel;
        break;
      case 2:
        this.region.kabkota = {id:0, name: '', province_id:this.region.province.id} as KabkotaModel;
        break;
      case 3:
        this.region.bidang = {id:0, name: '', kabKota_id:this.region.kabkota.id} as BidangModel;
        break;
    }
  }

  onViewSearch(type: number) {
    switch(type) {
      case 1:
        this.isShowSearchProvince = !this.isShowSearchProvince;
        break;
      case 2:
        this.isShowsearchBidang = !this.isShowsearchBidang;
        break;
      case 3:
        this.isShowsearchKabKota = !this.isShowsearchKabKota;
        break;
    }
  }
}
