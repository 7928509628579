export interface RowModel<T> {
  row: T;
  columns: T[];
}

export interface FieldModel<T> {
  itemValue: T;
  id: number;
  parent_id: number;
  name: string;
  enableActionEdit: boolean;
}
export class GridActionModel {
  constructor(enableActionReload: boolean = true, enableActionAddNew: boolean = false, enableActionUpdate: boolean = false, enableActionDelete: boolean = false, enableActionSearch: boolean = true) {
    this.enableActionReload = enableActionReload;
    this.enableActionAddNew = enableActionAddNew;
    this.enableActionUpdate = enableActionUpdate;
    this.enableActionDelete = enableActionDelete;
    this.enableActionSearch = enableActionSearch;
  }

  enableActionReload: boolean = true;
  enableActionAddNew: boolean = false;
  enableActionUpdate: boolean = false;
  enableActionDelete: boolean = false;
  enableActionSearch: boolean = true;
}

export interface DashboardDataCountModel {
  airport: number;
  airportMonitoring: number;
  airportProgress: number;
  province: number;
  district: number;
  user: number;
}

export interface GeneralDashboarModel {
  key: string;
  title: string;
  group: string;
  color: string;
  icon: string;
  value: number;
}
