import { StringExtension } from 'src/app/app-core/extensions/string.extension';
import { NavigationModel } from './../models/navigation.model';
import { APIResponse } from './../models/api-response.model';
import { AirportModel, AppConfigModel } from './../models/configuration.model';
import { UserLoginParamModel } from './../models/user.model';
import { APIROUTE } from './../../app-view/api-route.config';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { ServerConfiguration } from '../configurations/server.configuration';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private _http: HttpClient, private _spinner: NgxSpinnerService) {}

  public getUsingUrl<TResult>(url: string) {
    return this._http.get<TResult>(url);
  }
  public get<TResult>(apiRoute?: string, param?: string, queryOption?: string) {
    var queryParams = [];
    var url = ServerConfiguration.RouteUrl(apiRoute, param, queryOption);

    return this._http.get<TResult>(url);
  }

  public findUsingUrl<TResult>(url: string) {
    return this._http.get<TResult>(url);
  }
  public find<TResult>(apiRoute?: string, param?: string) {
    var url = ServerConfiguration.RouteUrl(apiRoute, param);
    return this._http.get<TResult>(url);
  }

  public postUsingUrl<TResult>(url: string, param: any) {
    return this._http.post<TResult>(url, param);
  }
  public post<TResult>(apiRoute?: string, param?: any) {
    var url = ServerConfiguration.RouteUrl(apiRoute);
    return this._http.post<TResult>(url, param);
  }
  public postPut<TResult>(apiRoute: string, key: string, param: any) {
    var url = ServerConfiguration.RouteUrl(apiRoute, key);
    return this._http.post<TResult>(url, param);
  }

  public putUsingUrl<TResult>(url: string, key: string, param: any) {
    return this._http.put<TResult>(url+'/'+key, param);
  }
  public put<TResult>(apiRoute: string, key: string, param: any) {
    var url = ServerConfiguration.RouteUrl(apiRoute, key);
    return this._http.put<TResult>(url, param);
  }

  public deleteUsingUrl<TResult>(url: string) {
    return this._http.delete<TResult>(url);
  }
  public delete<TResult>(apiRoute?: string, param?: string) {
    var url = ServerConfiguration.RouteUrl(apiRoute, param);
    return this._http.delete<TResult>(url);
  }

  public uploadFile<TResult>(file: File, module: number) {
    if(file == null) {
      return new Observable<TResult>();
    }
    var url = ServerConfiguration.RouteUrl(APIROUTE.UPLOAD_FILE, '');
    const formData = new FormData();
    formData.append("file", file, file.name);
    return this._http.post<TResult>(url, formData)
  }

  public authLogin<TResult>(username: string, password: string) {
    var url = ServerConfiguration.RouteUrl(APIROUTE.AUTH_LOGIN);
    var userparam = {
      username: username,
      password: password,
    } as UserLoginParamModel;
    return this._http.post<TResult>(url, userparam);
  }

  public authLoginInformation<TResult>() {
    var url = ServerConfiguration.RouteUrl(APIROUTE.AUTH_LOGIN_INFORMATION);
    return this._http.get<TResult>(url);
  }

  public getNavigations() {
    var url = ServerConfiguration.RouteUrl(APIROUTE.NAVIGATION);
    return this._http.get<APIResponse<NavigationModel[]>>(url);
  }

  public getAppConfigs() {
    var url = ServerConfiguration.RouteUrl(APIROUTE.APP_CONFIG);
    return this._http.get<APIResponse<AppConfigModel[]>>(url);
  }

  public setLoading(value: boolean, isUseNextProcess: boolean = false): boolean {
    if(value) this._spinner.show();
    else if(!isUseNextProcess) this._spinner.hide();

    return value;
  }
}
