<div>
  <div class="row">
    <div class="col-4">
      <div class="card">
        <div class="card-header d-flex align-items-center justify-content-between border-bottom">
          <h5 class="card-title m-0 me-2">
            Province
            <div class="form-text">
              Select Province To See District
            </div>
          </h5>
          <div class="card-action-element">
            <ul class="list-inline mb-0">
              <li class="list-inline-item" *ngIf="actionGrid.enableActionReload">
                <a (click)="initialize()" class="card-reload cursor-pointer"><i class="tf-icons bx bx-rotate-left scaleX-n1-rtl"></i></a>
              </li>
              <li class="list-inline-item" *ngIf="provinces.length >= 10">
                <a (click)="onViewSearch(1)" class="card-reload cursor-pointer"><i class="tf-icons bx bx-search"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body p-0" *ngIf="provinces.length > 0">
          <div class="p-3" *ngIf="isShowSearchProvince">
            <div class="input-group input-group-merge">
              <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search"></i></span>
              <input type="text" [(ngModel)]="searchProvince" class="form-control" placeholder="Search..." aria-label="Search..." aria-describedby="basic-addon-search31">
            </div>
          </div>
          <div class="table-wrapper">
            <table class="table table-bordered">
              <tbody>
                <tr *ngFor="let item of provinces | filter:searchProvince; let no = index" class="cursor-pointer"
                  [ngClass]="hasMenuActive(1, item.id)" (click)="getDataKabkotaByProvinceId(item.id)">
                <td style="width: 100% !important;padding:15px !important;">{{item.name}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="card-header d-flex align-items-center justify-content-between border-bottom">
          <h5 class="card-title m-0 me-2">
            Kab / Kota
            <div *ngIf="provinceId !== 0" class="form-text">
              Province - {{region.province.name}}
            </div>
            <div *ngIf="provinceId == 0" class="form-text">
              To view Kab / Kota Please Select Province First.
            </div>
          </h5>
          <div class="card-action-element">
            <ul class="list-inline mb-0">
              <li class="list-inline-item" *ngIf="actionGrid.enableActionReload">
                <a (click)="initialize()" class="card-reload cursor-pointer"><i class="tf-icons bx bx-rotate-left scaleX-n1-rtl"></i></a>
              </li>
              <li class="list-inline-item" *ngIf="provinceId !== 0 && kabkotas.length >= 10">
                <a (click)="onViewSearch(2)" class="card-reload cursor-pointer"><i class="tf-icons bx bx-search"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body p-0" *ngIf="kabkotas.length > 0">
          <div class="p-3" *ngIf="isShowsearchKabKota">
            <div class="input-group input-group-merge">
              <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search"></i></span>
              <input type="text" [(ngModel)]="searchKabKota" class="form-control" placeholder="Search..." aria-label="Search..." aria-describedby="basic-addon-search31">
            </div>
          </div>
          <div class="table-wrapper">
            <table class="table table-bordered">
              <tbody>
                <tr *ngFor="let item of kabkotas | filter:searchKabKota; let no = index" class="cursor-pointer"
                  [ngClass]="hasMenuActive(2, item.id)" (click)="getDataBidangByKabkotaid(item.id)">
                <td style="width: 100% !important;padding:15px !important;">{{item.name}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="card-header d-flex align-items-center justify-content-between border-bottom">
          <h5 class="card-title m-0 me-2">
            Bidang
            <div *ngIf="provinceId !== 0" class="form-text">
              Kab / Kota - {{region.kabkota.name}}
            </div>
            <div *ngIf="kabkotaid == 0" class="form-text">
              To view Bidang Please Select Kab / Kota First.
            </div>
          </h5>
          <div class="card-action-element">
            <ul class="list-inline mb-0">
              <li class="list-inline-item" *ngIf="actionGrid.enableActionReload">
                <a (click)="initialize()" class="card-reload cursor-pointer"><i class="tf-icons bx bx-rotate-left scaleX-n1-rtl"></i></a>
              </li>
              <li class="list-inline-item" *ngIf="kabkotaid !== 0 && bidangs.length >= 10">
                <a (click)="onViewSearch(3)" class="card-reload cursor-pointer"><i class="tf-icons bx bx-search"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body p-0" *ngIf="bidangs.length > 0">
          <div class="p-3" *ngIf="isShowsearchBidang">
            <div class="input-group input-group-merge">
              <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search"></i></span>
              <input type="text" [(ngModel)]="searchBidang" class="form-control" placeholder="Search..." aria-label="Search..." aria-describedby="basic-addon-search31">
            </div>
          </div>
          <div class="table-wrapper">
            <table class="table table-bordered">
              <tbody>
                <tr *ngFor="let item of bidangs | filter:searchBidang; let no = index" class="cursor-pointer"
                  [ngClass]="hasMenuActive(2, item.id)" (click)="onSelectedBidang(item.id)">
                <td style="width: 100% !important;padding:15px !important;">{{item.name}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="formNewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Region</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="needs-validation">
          <div class="row mb-3" *ngIf="actionType == 1">
            <label class="col-sm-2 col-form-label" for="basic-default-company">Province &nbsp;<sup style="color: red;">*</sup></label>
            <div class="col-sm-10">
              <input type="text" [(ngModel)]="region.province.name" class="form-control" id="basic-default-company" maxlength="50" required
              [ngClass]="{ 'is-invalid': submitted && (region.province.name == '' || duplicatData) }">
              <div *ngIf="submitted && region.province.name == ''" class="invalid-feedback">
                <div *ngIf="region.province.name == ''">Field is required!</div>
              </div>
              <div *ngIf="submitted && duplicatData" class="invalid-feedback">
                <div *ngIf="duplicatData">Data province sudah tersedia, silahkan memasukan data lainnya!</div>
              </div>
            </div>
          </div>
          <div class="row mb-3" *ngIf="actionType == 2">
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label" for="basic-default-company">Province</label>
              <div class="col-sm-10">
                <input type="text" [(ngModel)]="region.province.name" class="form-control form-control-plaintext" id="basic-default-company" disable>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label" for="basic-default-company">Kab / Kota &nbsp;<sup style="color: red;">*</sup></label>
              <div class="col-sm-10">
                <input type="text" [(ngModel)]="region.kabkota.name" class="form-control" id="basic-default-company" maxlength="50" required
                [ngClass]="{ 'is-invalid': submitted && (region.kabkota.name == '' || duplicatData) }">
                <div *ngIf="submitted && region.kabkota.name == ''" class="invalid-feedback">
                  <div *ngIf="region.kabkota.name == ''">Field is required!</div>
                </div>
                <div *ngIf="submitted && duplicatData" class="invalid-feedback">
                  <div *ngIf="duplicatData">Data Kab / Kota sudah terdaftar, silahkan memasukan data lainnya!</div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label" for="basic-default-company">Bidang &nbsp;<sup style="color: red;">*</sup></label>
              <div class="col-sm-10">
                <input type="text" [(ngModel)]="region.bidang.name" class="form-control" id="basic-default-company" maxlength="50" required
                [ngClass]="{ 'is-invalid': submitted && (region.bidang.name == '' || duplicatData) }">
                <div *ngIf="submitted && region.bidang.name == ''" class="invalid-feedback">
                  <div *ngIf="region.bidang.name == ''">Field is required!</div>
                </div>
                <div *ngIf="submitted && duplicatData" class="invalid-feedback">
                  <div *ngIf="duplicatData">Data Bidang sudah terdaftar, silahkan memasukan data lainnya!</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="float-end">
          <button type="button" class="btn btn-secondary" style="margin-right: 5px !important;" (click)="onCancel()">Close</button>
          <button type="button" class="btn btn-primary" (click)="onSubmit()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>
