import { UserProfileModel } from "../app-core/models/user-profile.model";

export interface AssetKIBAModel {
  id: number;
  assetid: number;
  kodebarang: string;
  namabarang: string;
  noregister: string;
  luas: string;
  tahunpengadaan: string;
  alamat: string;
  statushak: string;
  tanggalsertifikat: null;
  nosertifikat: string;
  penggunaan: string;
  asalusul: string;
  harga: string;
  keterangan: string;
  image: string;
  createdDate: string;
  createdBy: number;
  modifiedDate: string;
  modifiedBy: number;
  userProfileCreated: UserProfileModel;
  userProfileModified: UserProfileModel;
}

export class AssetKIBAViewModel {
  constructor(_uniqueValue: string, _asset: AssetKIBAModel | null){
    this.uniqueValue = _uniqueValue;
    this.asset = _asset == null ? {} as AssetKIBAModel : _asset;
  }
  uniqueValue!: string;
  asset!: AssetKIBAModel
}
