<div class="col-12">
  <div class="card">
    <div class="card-header">
      <h5 class="card-title" id="staticBackdropLabel">Asset Management</h5>
    </div>
    <div class="card-body">
      <div class="col-md-6 offset-md-3">
        <div class="list-group list-group-flush">
          <div class="mb-3 list-group-item list-group-item-action">
            <div class="row">
              <div class="col-3 text-end"><strong>Unit</strong></div>
              <div class="col-9">{{asset.subUnitOrganitation.unitorganitation.name}}</div>
            </div>
          </div>
          <div class="mb-3 list-group-item list-group-item-action list-group-item-secondary">
            <div class="row">
              <div class="col-3 text-end"><strong>Sub Unit</strong></div>
              <div class="col-9">{{asset.subUnitOrganitation.name}}</div>
            </div>
          </div>
          <div class="mb-3 list-group-item list-group-item-action">
            <div class="row">
              <div class="col-3 text-end"><strong>KIB</strong></div>
              <div class="col-9">{{asset.category.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <app-assetkiba-view [items]="assetKIBAs"></app-assetkiba-view>
      </div>
    </div>
    <div class="card-footer">
      <div class="float-end">
        <button type="button" class="btn btn-secondary" style="margin-right: 5px !important;" (click)="onCancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>
