import { ObjectExtension } from './../object.extension';
import { Tooltip } from 'bootstrap';
import { of } from 'rxjs';
declare var $: any;
declare var bootstrap: any;

// @dynamic
export class TooltipPopoverExtension {
  private static _tooltips: Array<TooltipModel> = [] as TooltipModel[];
  private static _elementid: string | null;
  private static TooltipPopoverElement(elementid: string, html:boolean = false, title:string = '', action: string): Tooltip | null {
    var element = document.getElementById(elementid);
    if(element == null){
      return null;
    }

    var tooltip = new Tooltip(element, {
      offset: [0,4],
      placement: 'top',
      html: html,
      title: title
    });

    this._elementid = elementid;
    return tooltip;
  }

  public static UseTooltip() {
    // Bootstrap tooltip initialization
    Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
    Array.from(document.querySelectorAll('a[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
  }

  public static tooltip(elementid: string, html:boolean = false, title:string = '', action: string) {
    var existings = this._tooltips.filter(x =>x.elementId === elementid);
    if(!ObjectExtension.Any(existings)) {
      var elementid = elementid;
      var tooltip = this.TooltipPopoverElement(elementid, html, title, action);

      this._tooltips.push({elementId: elementid, tooltip: tooltip} as TooltipModel);
    }

    var current = this._tooltips.filter(x =>x.elementId === elementid)[0];

    if(current.tooltip !== null) {
      if(action === 'show') {
        current.tooltip.show();
      }else if(action === 'hide') {
        this._tooltips.forEach(x => {
          x.tooltip.hide();
        });
        this._tooltips.splice(this._tooltips.indexOf(current), 1);
      }
    }
  }
}

export interface TooltipModel {
  tooltip : Tooltip,
  elementId: string
}
