import { StringExtension } from './../extensions/string.extension';
import { CryptographyExtension } from './../extensions/cryptography.extension';
import { Router, ActivatedRoute } from '@angular/router';
// @dynamic

export class URLUtility {

  constructor(private _router: Router | null = null, private _actRoute: ActivatedRoute | null = null) {}

  public redirect(route: string, params: string[] = []) {
    var parameters:string[] = [];
    var tempParameters:string[] = [];
    parameters.push(route);
    params.forEach(x => tempParameters.push(x));

    tempParameters.forEach((param) => {
      if(!StringExtension.IsNullOrEmpty(param)) {
        var encryptedLink = CryptographyExtension.encryptionLink(param);
        parameters.push(encryptedLink);
      }
    });

    this._router?.navigate(parameters);
  }

  public getParam(key: string) {
    var param = this._actRoute?.snapshot.params[key];
    if(param) {
      var decryptedLink = CryptographyExtension.decryptionLink(param);
      var result= new PageContextModel(decryptedLink);

      return result;
    }

    return new PageContextModel('0');
  }
}

export class PageContextModel {

  constructor(param:string) {
    this.isPageNew = param === '0';
    this.isPageEdit = param !== '0';
    this.param = param;
    return this;
  }

  isPageNew: boolean;
  isPageEdit: boolean;
  param: string;
}
