
<div class="modal fade" id="ModalFilter" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Filter</h5>
        <button type="button" class="btn btn-sm btn-link" (click)="onCancel()">Reset</button>
      </div>
      <div class="modal-body">
        <div class="col-12 p-0">
          <div class="row mb-3">
            <div class="col-4">
              <select class="form-select" [(ngModel)]="filterOption.key" (change)="onChangeFilterField($event)">
                <option value="0">Select Field</option>
                <option *ngFor="let item of filter.filterOption; let no = index" [value]="item.key">{{item.title}}</option>
              </select>
            </div>
            <div class="col-8">
              <div *ngIf="filterOption.dataType == 'text'">
                <input type="text" [(ngModel)]="filter.value" class="form-control" placeholder="Put text here">
              </div>
              <div *ngIf="filterOption.dataType == 'dropdown'">
                <select class="form-select" [(ngModel)]="filter.value">
                  <option value="0">Select Option</option>
                  <option *ngFor="let item of filterOption.optionValues; let no = index" [value]="item.key">{{item.name}}</option>
                </select>
              </div>
              <div *ngIf="filterOption.dataType == 'datetime'">
                <input type="text" [(ngModel)]="filter.value" class="form-control" placeholder="Put text here">
              </div>
            </div>
            <!-- <div class="col-2 float-end">
              <button type="button" class="btn btn-sm btn-primary me-2" (click)="onSearch()"><i class="bx bx-search"></i> Search</button>
              <button type="button" class="btn btn-sm btn-secondary" (click)="onCancel()"><i class="bx bx-trash"></i> Cancel</button>
            </div> -->
          </div>
          <hr>
          <div class="float-end">
            <button type="button" class="btn btn-primary" (click)="onSearch()"><i class="bx bx-search"></i> Search</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<div class="card shadow-none border border-secondary mb-3">
  <div class="card-body pb-2">
    <h5 class="card-title">Filter</h5>
    <div class="col-12 p-0">
      <div class="row mb-3">
        <div class="col-4">
          <select class="form-select" [(ngModel)]="filterOption.key" (change)="onChangeFilterField($event)">
            <option value="0">Select Field</option>
            <option *ngFor="let item of filter.filterOption; let no = index" [value]="item.key">{{item.title}}</option>
          </select>
        </div>
        <div class="col-6">
          <div *ngIf="filterOption.dataType == 'text'">
            <input type="text" [(ngModel)]="filter.value" class="form-control" placeholder="Put text here">
          </div>
          <div *ngIf="filterOption.dataType == 'dropdown'">
            <select class="form-select" [(ngModel)]="filter.value">
              <option value="0">Select Option</option>
              <option *ngFor="let item of filterOption.optionValues; let no = index" [value]="item.key">{{item.name}}</option>
            </select>
          </div>
          <div *ngIf="filterOption.dataType == 'datetime'">
            <input type="text" [(ngModel)]="filter.value" class="form-control" placeholder="Put text here">
          </div>
        </div>
        <div class="col-2 float-end">
          <button type="button" class="btn btn-sm btn-primary me-2" (click)="onSearch()"><i class="bx bx-search"></i> Search</button>
          <button type="button" class="btn btn-sm btn-secondary" (click)="onCancel()"><i class="bx bx-trash"></i> Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
<hr> -->
