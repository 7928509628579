import { StorageConfiguration } from './storage.configuration';
import { ServerApiModel } from './../models/server-configuration.model';
import { SERVER_ENVIRONMENT } from './parameter.configuration';
import { ObjectExtension } from '../extensions/object.extension';
import { ServerConfigurationModel } from '../models/server-configuration.model';
import { StringExtension } from '../extensions/string.extension';
import { CONSTANT_STORAGE } from '../constants/global.constant';
// @dynamic
export class ServerConfiguration {
  public static RouteUrl(apiRoute?: string, param?: string, queryOption?: string) {
    var serverConfig = StorageConfiguration.getLocalData<ServerConfigurationModel>(CONSTANT_STORAGE.SERVER);
    var routeConfig = StorageConfiguration.getLocalData<ServerApiModel>(CONSTANT_STORAGE.ROUTE);
    var appConfiguration = serverConfig;
    var routeConfiguration = routeConfig;
    var environmentName = SERVER_ENVIRONMENT.environment;
    if(appConfiguration == null) return '';
    if(routeConfiguration == null) return '';

    var filterServers = appConfiguration.environments.filter(x => x.environment.toLowerCase() == environmentName.toLowerCase());
    if(!ObjectExtension.Any(filterServers)){
      return '';
    }
    var appEnvironment = filterServers[0];
    var appServer = appEnvironment.server;

    var apiRoutes = routeConfiguration.routes;

    var urlFinal = '';
    var protocol = '';
    var host = '';
    var route = '';
    var apiParam = '';
    var queryOptionParam = '';

    if (window.location.protocol.includes('https')) {
      protocol = 'https://';
    } else if (window.location.protocol.includes('http')) {
      protocol = 'http://';
    }

    var port = StringExtension.IsNullOrEmpty(appServer.port)
      ? ''
      : ':' + appServer.port;
    var path = StringExtension.IsNullOrEmpty(appServer.path)
      ? ''
      : '/' + appServer.path;

    host = protocol + appServer.host + port + path;

    if (!StringExtension.IsNullOrEmpty(apiRoute)) {
      var _apiroute = apiRoute as string;
      var selectedApiRoutes = apiRoutes.filter(x => x.title.toLowerCase() === _apiroute.toLowerCase());
      if(ObjectExtension.Any(selectedApiRoutes)){
        route = selectedApiRoutes[0].url;
      }
    }

    if (!StringExtension.IsNullOrEmpty(param)) {
      apiParam = '/' + param as string;
    }

    if(!StringExtension.IsNullOrEmpty(queryOption)) {
      queryOptionParam = queryOption??'';
    }

    urlFinal = host + route + apiParam + queryOptionParam;

    return urlFinal;
  }

  public static UploadURL(module?: string) {
    var serverConfig = StorageConfiguration.getLocalData<ServerConfigurationModel>(CONSTANT_STORAGE.SERVER);
    var appConfiguration = serverConfig;
    var environmentName = SERVER_ENVIRONMENT.environment;
    if(appConfiguration == null) return '';
    var filterServers = appConfiguration.environments.filter(x => x.environment.toLowerCase() == environmentName.toLowerCase());
    if(!ObjectExtension.Any(filterServers)){
      return '';
    }

    var appEnvironment = filterServers[0];
    var appServer = appEnvironment.server;
    var protocol = '';
    var host = '';

    if (window.location.protocol.includes('https')) {
      protocol = 'https://';
    } else if (window.location.protocol.includes('http')) {
      protocol = 'http://';
    }

    var port = StringExtension.IsNullOrEmpty(appServer.port)
      ? ''
      : ':' + appServer.port;
    var path = StringExtension.IsNullOrEmpty(appServer.path)
      ? ''
      : '/' + appServer.path;
    var uploadPath = StringExtension.IsNullOrEmpty(appServer.upload)
      ? '/filestorage/'
      : appServer.upload;
    var modulePath = StringExtension.IsNullOrEmpty(module)
      ? ''
      : module;

    host = protocol + appServer.host + port + path + uploadPath + modulePath;

    return host;
  }

  public static ParseImage(image: string) {
    if(StringExtension.IsNullOrEmpty(image)) return null;
    return this.UploadURL() + image;
  }
}
