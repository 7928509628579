
import * as CryptoJS from 'crypto-js';
// @dynamic

export class CryptographyUtility {
  private static secretKey = '64121923538e';
  private static secretPassphrase = '90576d2c2815';

  public static encryption(data: string) {
    var ciphertext = CryptoJS.Rabbit.encrypt(data, this.secretKey).toString();
    // var encrypted = CryptoJS.DES.encrypt(ciphertext, this.secretPassphrase).toString();
    return ciphertext;
  }

  public static decryptionString(ciphertext:string) {
    // var dencrypted = CryptoJS.DES.decrypt(ciphertext, this.secretPassphrase).toString();
    var bytes = CryptoJS.Rabbit.decrypt(ciphertext, this.secretKey);
    var decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

  public static decryption<T>(ciphertext:string) {
    // var dencrypted = CryptoJS.DES.decrypt(ciphertext, this.secretPassphrase).toString();
    var bytes = CryptoJS.Rabbit.decrypt(ciphertext, this.secretKey);
    var decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decrypted as T;
  }

  private static setJsonFormatter(){
    var jsonFormatter = {
      stringify: (cipherParams: any) => {
        // create json object with ciphertext
        var jsonObj = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };

        // stringify json object
        return JSON.stringify(jsonObj);
      },
      parse: (jsonStr: any) => {
        // parse json string
        var jsonObj = JSON.parse(jsonStr);
        // extract ciphertext from json object, and create cipher params object
        var cipherParams = CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct)
        });
        // optionally extract iv or salt
        if (jsonObj.iv) {
          cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
        }
        if (jsonObj.s) {
          cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
        }
        return cipherParams;
      }
    }

    return jsonFormatter;
  }
}
