import { Modal } from 'bootstrap';
declare var $: any;
// @dynamic
export class ModalExtension {
  private static ModalElement(elementid: string): Modal | null {
    var element = document.getElementById(elementid);
    if(element == null){
      return null;
    }

    return new Modal(element, {
      keyboard: false
    });
  }
  public static Show(elementid: string): void {
    var modalElement = this.ModalElement(elementid);
    if(modalElement){
      modalElement.show();
    }
  }
  public static Hide(elementid: string): void {
    var modalElement = this.ModalElement(elementid);
    if(modalElement){
      modalElement.hide();
      var myModal = '#'+elementid;
      $(myModal).removeClass("in");
      $(".modal-backdrop").remove();
      $('body').removeClass('modal-open');
      $('body').css('padding-right', '');
      $('body').css('overflow', '');
      $(myModal).hide();
    }
  }
}
