// @dynamic
export class StringExtension {
  public static IsNullOrEmpty(value: string | any | undefined) {
    if (value == undefined) return true;
    else if (value == null) return true;
    else if (value == "") return true;
    else if (value == " ") return true;
    else if (value == "  ") return true;
    else if (value == "   ") return true;
    else if (value == "null") return true;
    else return false;
  }
}
