<ng-container>
    <!-- User -->
    <li class="nav-item navbar-dropdown dropdown-user dropdown">
      <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
        <div class="avatar avatar-online">
          <img src="../assets/img/avatars/user_no_photo.png" alt class="w-px-40 h-auto rounded-circle" />
        </div>
      </a>
      <ul class="dropdown-menu dropdown-menu-end">
        <li>
          <a class="dropdown-item" href="#">
            <div class="d-flex">
              <div class="flex-shrink-0 me-3">
                <div class="avatar avatar-online">
                  <img src="../assets/img/avatars/user_no_photo.png" alt class="w-px-40 h-auto rounded-circle" />
                </div>
              </div>
              <div class="flex-grow-1">
                <span class="fw-semibold d-block">{{userProfile.name}}</span>
                <small class="text-muted">{{userProfile.email}}</small>
              </div>
            </div>
            <div class="flex-grow-0 mt-2">
              <small class="text-md-start fw-semibold d-block">Member : {{groupmember.group.name}}</small>
            </div>
          </a>
        </li>
        <li>
          <div class="dropdown-divider"></div>
        </li>
        <li>
          <a class="dropdown-item cursor-pointer" [routerLink]="['/user', userProfile.id]">
            <i class="bx bx-user me-2"></i>
            <span class="align-middle">My Profile</span>
          </a>
        </li>
        <!-- <li>
          <a class="dropdown-item cursor-pointer" [routerLink]="['/setting']">
            <i class="bx bx-cog me-2"></i>
            <span class="align-middle">Settings</span>
          </a>
        </li> -->
        <li>
          <div class="dropdown-divider"></div>
        </li>
        <li>
          <a class="dropdown-item cursor-pointer" (click)="onLogout()">
            <i class="bx bx-power-off me-2"></i>
            <span class="align-middle">Log Out</span>
          </a>
        </li>
      </ul>
    </li>
    <!--/ User -->

</ng-container>
