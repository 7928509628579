import { AuthService } from './../services/auth.service';
import { Logger } from './../utilities/logger.utility';
import { AirportModel, AppConfigModel } from './../models/configuration.model';
import { APIResponse } from './../models/api-response.model';
import { CONSTANT_STORAGE, STATIC_JSON_DATA } from './../constants/global.constant';
import { APIROUTE } from './../../app-view/api-route.config';
import { ServerConfiguration } from './../configurations/server.configuration';
import { StorageConfiguration } from './../configurations/storage.configuration';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationModel } from '../models/navigation.model';

export function initializeAppFactory(httpClient: HttpClient, _authService:AuthService) {
  return () => new Observable((subscriber) => {
    Logger.info('---------START---------');
    Logger.info('Server Initiation');
    // HTTP Get call
    httpClient.get(STATIC_JSON_DATA.SERVER).subscribe(responseERVER => {
      StorageConfiguration.saveLocalData(CONSTANT_STORAGE.SERVER, responseERVER);
      Logger.info('Route Initiation');
      httpClient.get(STATIC_JSON_DATA.ROUTE).subscribe(responseROUTE => {
        StorageConfiguration.saveLocalData(CONSTANT_STORAGE.ROUTE, responseROUTE);
        Logger.info('Form Stepper Initiation');
        httpClient.get(STATIC_JSON_DATA.FORM_ASSET_STEPPER).subscribe(responseSTEPPER => {
          StorageConfiguration.saveLocalData(CONSTANT_STORAGE.FORM_ASSET_STEPPER, responseSTEPPER);
          Logger.info('App Configuration Initiation');
          let urlConfig = ServerConfiguration.RouteUrl(APIROUTE.APP_CONFIG);
          httpClient.get<APIResponse<AppConfigModel[]>>(urlConfig).subscribe(responseAppConfig => {
            if(!responseAppConfig.error){
              var appConfigs = responseAppConfig.data;
              StorageConfiguration.saveLocalData(CONSTANT_STORAGE.APP_CONFIG, appConfigs);
            }
            if (_authService.isLoggedIn) {
              Logger.info('Navigation Initiation');
              let url = ServerConfiguration.RouteUrl(APIROUTE.NAVIGATION);
              httpClient.get<APIResponse<NavigationModel[]>>(url).subscribe(responseNav => {
                var navigations = responseNav.data;
                console.log(navigations);
                StorageConfiguration.saveLocalData(CONSTANT_STORAGE.NAVIGATION, navigations);
                Logger.info('All Initiation Complete');
                Logger.info('---------END---------');
                subscriber.complete();
              });
            } else {
              subscriber.complete();
              Logger.info('All Initiation Complete');
              Logger.info('---------END---------');
            }
          });
        });
      });
    }, error => {
      Logger.info('Server Initiation error');
      subscriber.error();
    });
  });
  // let url = ServerConfiguration.RouteUrl(APIROUTE.NAVIGATION);
  // // let url = './assets/configurations/navigation.json';
  // return () => httpClient.get<APIResponse<NavigationModel[]>>(url).pipe(
  //   tap(response => {
  //     Logger.info('Navigation Initiation');
  //     var navigations = response.data;
  //     StorageConfiguration.saveLocalData(CONSTANT_STORAGE.NAVIGATION, navigations);
  //   })
  // );
}
