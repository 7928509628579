<div class="bg-lighter ps-4 pt-3 pe-4" *ngIf="pagination">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="dataTables_length" id="DataTables_Table_0_length">
        <label>
          <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="form-select" [(ngModel)]="pagination.limit" (ngModelChange)="onLimitPage($event)">
            <option *ngFor="let item of pagingLimits; let no = index" [value]="item">{{item}}</option>
          </select>
        </label>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="float-end" id="DataTables_Table_0_paginate">
        <ul class="pagination pagination-square pagination-primary">
          <li class="page-item first">
            <a class="page-link cursor-pointer" (click)="onFirstPage()"><i class="tf-icon bx bx-chevrons-left"></i></a>
          </li>
          <li class="page-item prev">
            <a class="page-link cursor-pointer" (click)="onPrevPage()"><i class="tf-icon bx bx-chevron-left"></i></a>
          </li>
          <li class="page-item text-nowrap">
            <span class="page-link cursor-pointer"><small>Showing <strong>{{pagination.page}}</strong> to <strong>{{pagination.totalPage}}</strong> of <strong>{{pagination.count}}</strong> entries</small></span>
          </li>
          <li class="page-item next">
            <a class="page-link cursor-pointer" (click)="onNextPage()"><i class="tf-icon bx bx-chevron-right"></i></a>
          </li>
          <li class="page-item last">
            <a class="page-link cursor-pointer" (click)="onLastPage()"><i class="tf-icon bx bx-chevrons-right"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
