<div class="card">
  <div class="card-header d-flex align-items-center justify-content-between border-bottom">
    <h5 class="card-title m-0 me-2">User Management</h5>
    <div class="card-action-element">
      <ul class="list-inline mb-0">
        <li class="list-inline-item">
          <a (click)="onViewSearch()" class="card-reload cursor-pointer"><i class="tf-icons bx bx-search"></i></a>
        </li>
        <li class="list-inline-item">
          <a (click)="initialize()" class="card-reload cursor-pointer"><i class="tf-icons bx bx-rotate-left scaleX-n1-rtl"></i></a>
        </li>
        <li class="list-inline-item">
          <a (click)="onAddNew()" class="card-reload cursor-pointer text-primary"><i class="tf-icons bx bx-plus scaleX-n1-rtl"></i> Add New</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="card-body p-0">
    <div class="p-3" *ngIf="isShowSearch">
      <div class="input-group">
        <!-- <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search"></i></span> -->
        <input type="text" [(ngModel)]="filter.value" class="form-control" placeholder="Search Name..." aria-label="Search Name..." aria-describedby="basic-addon-search31">
        <button class="btn btn-outline-primary" type="button" (click)="onSearchChange($event)"><i class="bx bx-search"></i></button>
        <button class="btn btn-outline-danger" type="button" (click)="onClearSearch()"><i class="bx bx-trash"></i></button>
      </div>
    </div>
    <div class="table-wrapper">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead style="color: #696cff !important;background-color: rgba(105, 108, 255, 0.16) !important;">
            <tr>
              <th style="width: 2% !important;text-align: center !important;">No</th>
              <th>NIK</th>
              <th><span>Name - Username</span></th>
              <th>Email - Phone</th>
              <!-- <th>Phone</th> -->
              <!-- <th>Email</th> -->
              <th>Role</th>
              <!-- <th>Username</th> -->
              <!-- <th>Is Admin</th> -->
              <th>Status</th>
              <th>Modified</th>
              <th>Modified By</th>
              <th style="width: 2%  !important;text-align: center !important;" *ngIf="isAdministratorPrivillage">Actions</th>
            </tr>
          </thead>
          <tbody class="table-border-bottom-0">
            <tr *ngFor="let item of users; let no = index">
              <td style="width: 2% !important;text-align: center !important;">{{no+1}}</td>
              <td class="text-center">
                <!-- <a class="link-primary cursor-pointer text-decoration-underline" (click)="onViewDetail(item)" *ngIf="isProfileUserLogin(item.userProfile.id)">{{item.userProfile.nik}}</a> -->
                <!-- <span *ngIf="!isProfileUserLogin(item.userProfile.id)">{{item.userProfile.nik}}</span> -->
                <span>{{item.userProfile.nik}}</span>
              </td>
              <td>
                <div class="d-flex flex-column">
                  <div class="text-body text-truncate">
                    <span class="fw-semibold">{{item.userProfile.name}}</span>
                  </div>
                  <small class="text-muted">{{item.userAccess.username}}</small>
                </div>
              </td>
              <td>
                <div class="d-flex flex-column">
                  <div class="text-body text-truncate">
                    <span class="fw-semibold">{{item.userProfile.email}}</span>
                  </div>
                  <small class="text-muted">{{item.userProfile.phone}}</small>
                </div>
              </td>
              <!-- <td>{{item.userProfile.phone}}</td>
              <td>{{item.userProfile.email}}</td> -->
              <td>
                <div class="d-flex flex-column">
                  <div class="text-body text-truncate">
                    <span class="badge bg-label-primary m-1">{{item.groupmember != null ? item.groupmember.group.name : ''}}</span>
                  </div>
                  <span class="text-nowrap text-truncate" *ngIf="item.groupmember">
                    <span *ngIf="item.groupmember.group.can_create" class="badge bg-label-success m-1">C</span>
                    <span *ngIf="item.groupmember.group.can_update === true" class="badge bg-label-warning m-1">U</span>
                    <span *ngIf="item.groupmember.group.can_delete === true" class="badge bg-label-danger m-1">D</span>
                    <span *ngIf="item.groupmember.group.can_view === true" class="badge bg-label-info m-1">V</span>
                    <span *ngIf="item.groupmember.group.can_view_mobile === true" class="badge bg-label-success m-1">V</span>
                  </span>
                </div>

              </td>
              <!-- <td>{{item.userAccess.username}}</td> -->
              <!-- <td class="text-center">
                <i class="bx bx-check bx-sm" *ngIf="item.userProfile.isAdmin"></i>
                <i class="bx bx-x bx-sm" *ngIf="!item.userProfile.isAdmin"></i>
              </td> -->
              <td class="text-center">
                <span class="badge bg-label-danger me-1" *ngIf="item.userAccess.isBlocked">Blocked</span>
                <span class="badge bg-label-success me-1" *ngIf="!item.userAccess.isBlocked">Active</span>
              </td>
              <td class="text-center text-muted"><small>{{(item.modifiedByUser ? item.modifiedByUser.modifiedDate : item.createdByUser.createdDate) | date:'yyyy-MM-dd'}}</small></td>
              <td class="text-muted"><small>{{item.modifiedByUser ? item.modifiedByUser.name : item.createdByUser.name}}</small></td>
              <td style="width: 2%  !important;text-align: center !important;" *ngIf="isAdministratorPrivillage">
                <div class="dropdown">
                  <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <i class="bx bx-dots-vertical-rounded"></i>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" (click)="onEdit(item)"><i class="bx bx-edit-alt me-1"></i> Edit</a>
                    <a class="dropdown-item" (click)="onDelete(item)"><i class="bx bx-trash me-1"></i> Delete</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div>
    <app-pagination
        [pagination]="pagination"
        (onFirstPageChange)="onFirstPage($event)"
        (onPrevPageChange)="onPrevPage($event)"
        (onNextPageChange)="onNextPage($event)"
        (onLastPageChange)="onLastPage($event)"
        (onLimitPageChange)="onLimitPage($event)">
      </app-pagination>
  </div>
</div>

<div class="modal fade" id="formNewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">User Management</h5>
        <button type="button" class="btn-close" (click)="onClose()"></button>
      </div>
      <div class="modal-body">
        <div [formGroup]="userForm" class="needs-validation">
          <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-end" for="basic-default-company">NIK &nbsp;<sup style="color: red;">*</sup></label>
            <div class="col-sm-10">
              <input type="text" formControlName="nik" class="form-control" id="basic-default-company" required
                [ngClass]="{ 'is-invalid': userForm.isInvalid(field.nik) }">
              <div *ngIf="userForm.isValidate(field.nik, 'required')" class="invalid-feedback">
                <div *ngIf="userForm.isInvalid(field.nik)">Field is required!</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-end" for="basic-default-company">Name &nbsp;<sup style="color: red;">*</sup></label>
            <div class="col-sm-10">
              <input type="text" formControlName="name" class="form-control" id="basic-default-company" required
                [ngClass]="{ 'is-invalid': userForm.isInvalid(field.name) }">
              <div *ngIf="userForm.isValidate(field.name, 'required')" class="invalid-feedback">
                <div *ngIf="userForm.isInvalid(field.name)">Field is required!</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-end" for="basic-default-company">Phone &nbsp;</label>
            <div class="col-sm-10">
              <input type="text" formControlName="phone" class="form-control" id="basic-default-company">
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-end" for="basic-default-company">Email &nbsp;<sup style="color: red;">*</sup></label>
            <div class="col-sm-10">
              <input type="text" formControlName="email" class="form-control" id="basic-default-company" (change)="onInputEmailChange()" required
                [ngClass]="{ 'is-invalid': userForm.isInvalid(field.email) }">
              <div *ngIf="userForm.isValidate(field.email, 'required')" class="invalid-feedback">
                <div *ngIf="userForm.isInvalid(field.email)">Field is required!</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-end" for="basic-default-company">Username &nbsp;<sup style="color: red;">*</sup></label>
            <div class="col-sm-10">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="defaultCheckisUseEmailForUserName" formControlName="isUseEmailForUserName">
                <label class="form-check-label" for="defaultCheckisUseEmailForUserName"> Use email for username </label>
              </div>
              <div *ngIf="!field.isUseEmailForUserName?.value">
                <input type="text" formControlName="username" class="form-control" id="basic-default-company"  required
                [ngClass]="{ 'is-invalid': !field.isUseEmailForUserName?.value && userForm.isInvalid(field.username) }">
                <div *ngIf="!field.isUseEmailForUserName?.value && userForm.isValidate(field.username, 'required')" class="invalid-feedback">
                  <div *ngIf="!field.isUseEmailForUserName?.value && userForm.isInvalid(field.username)">Field is required!</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-end" for="basic-default-company">Password &nbsp;<sup style="color: red;">*</sup></label>
            <div class="col-sm-10">
              <input type="password" formControlName="password" class="form-control" id="basic-default-company" required
                [ngClass]="{ 'is-invalid': userForm.isInvalid(field.password) }">
              <div *ngIf="userForm.isValidate(field.password, 'required')" class="invalid-feedback">
                <div *ngIf="userForm.isInvalid(field.password)">Field is required!</div>
              </div>
            </div>
          </div>
          <!-- <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-end" for="basic-default-company">Is Admin &nbsp;</label>
            <div class="col-sm-10">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="defaultCheck3" formControlName="isAdmin">
                <label class="form-check-label" for="defaultCheck3"> Set Permission Administrator </label>
              </div>
            </div>
          </div> -->
          <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-end" for="basic-default-company">Is Blocked &nbsp;</label>
            <div class="col-sm-10">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="defaultCheckisBlocked" formControlName="isBlocked">
                <label class="form-check-label" for="defaultCheckisBlocked"> Block User For Access This Site </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="float-end">
          <!-- <button type="button" class="btn btn-secondary" style="margin-right: 5px !important;" (click)="onClose()">Close</button> -->
          <button type="button" class="btn btn-primary" (click)="onSubmit()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="formViewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">User Management</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="list-group list-group-flush">
          <div class="list-group-item list-group-item-action">
            <div class="row">
              <div class="col-4"><strong>NIK</strong></div>
              <div class="col-8">{{user.userProfile.nik}}</div>
            </div>
          </div>
          <div class="list-group-item list-group-item-action">
            <div class="row">
              <div class="col-4"><strong>Name</strong></div>
              <div class="col-8">{{user.userProfile.name}}</div>
            </div>
          </div>
          <div class="list-group-item list-group-item-action">
            <div class="row">
              <div class="col-4"><strong>Phone</strong></div>
              <div class="col-8">{{user.userProfile.phone}}</div>
            </div>
          </div>
          <div class="list-group-item list-group-item-action">
            <div class="row">
              <div class="col-4"><strong>Email</strong></div>
              <div class="col-8">{{user.userProfile.email}}</div>
            </div>
          </div>
          <div class="list-group-item list-group-item-action">
            <div class="row">
              <div class="col-4"><strong>Username</strong></div>
              <div class="col-8">{{user.userAccess.username}}</div>
            </div>
          </div>
          <div class="list-group-item list-group-item-action">
            <div class="row">
              <div class="col-4"><strong>Status</strong></div>
              <div class="col-8">
                <span class="badge bg-label-danger me-1" *ngIf="user.userAccess.isBlocked">Blocked</span>
                <span class="badge bg-label-success me-1" *ngIf="!user.userAccess.isBlocked">Active</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
