import { AuthService } from './auth.service';
import { ModalExtension } from './../extensions/bootstrap/modal.extension';
import { StorageConfiguration } from '../configurations/storage.configuration';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/app-core/services/http.service';
import { ActivatedRouteSnapshot, CanActivate,Router, RouterStateSnapshot } from "@angular/router";
import { Injectable } from '@angular/core';

@Injectable()
export class LoginActivate implements CanActivate {
  constructor(private _ervice: HttpService, private _router: Router,private _authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean {
    if (!this._authService.isLoggedIn) {
      // ModalExtension.Show('logimodal');
      this._router.navigate(['/login']);
    }
    return true;
  }
}
