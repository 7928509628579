import { AuthService } from './../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { StorageConfiguration } from './../../configurations/storage.configuration';
import { UserAccessModel } from './../../models/user-access.model';
import { UserProfileModel } from './../../models/user-profile.model';
import { APIROUTE } from './../../../app-view/api-route.config';
import { APIResponse } from './../../models/api-response.model';
import { HttpService } from './../../services/http.service';
import { NotificationService } from './../../services/notification.service';
import { UserModel, UserLoginResultModel } from './../../models/user.model';
import { Component, OnInit } from '@angular/core';
import { UserFormPresenter } from '../users/user_form.presenter';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent extends UserFormPresenter implements OnInit {
  users: UserModel[] | undefined;
  userLogin: UserLoginResultModel = {} as UserLoginResultModel;
  isLoading: boolean = false;
  userid: number | undefined;

  constructor(public _service: HttpService, public _notification:NotificationService, private _actRoute: ActivatedRoute, private _authService: AuthService) {
    super();
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.userid = this._actRoute.snapshot.params['id'];
    this.userLogin = {} as UserLoginResultModel;
    this.users = [] as UserModel[];
    this.user.userProfile = {} as UserProfileModel;
    this.user.userAccess = {} as UserAccessModel;
    this.user.createdByUser = {} as UserProfileModel;
    this.user.modifiedByUser = {} as UserProfileModel;
    if(this.userid) this.getDataById();
    else this.getData();
  }

  getData() {
    this.isLoading = this._service.setLoading(true);
    var user = this._authService.getAuthInformation();
    if(user){
      this.userLogin = user;
      this.user = user.user;
      this.setFieldValues(this.user);
      this.isLoading = this._service.setLoading(false);
    }
  }

  getDataById() {
    if(this.userid){
      this.isLoading = this._service.setLoading(true);
      this._service.get<APIResponse<UserModel>>(APIROUTE.USER_PROFILE, this.userid.toString()).subscribe((response) =>  {
        this.user = response.data;
        this.setFieldValues(this.user);
        this.isLoading = this._service.setLoading(false);
      });
    }
  }

  onSubmit() {
    this.userForm.submitted = true;
    if (this.userForm.invalid) {
      this._notification.warning('Save Data', 'Please fill mandatory field!');
      return;
    }

    this.getFieldValue();

    if(!this.user) {
      throw Error('Data Model, Data Model undefined')
    }

    this.updateData();
  }

  updateData() {

    this.isLoading = this._service.setLoading(true);
    this.user.userProfile.isDeleted=false;
    this._service.put<APIResponse<UserModel>>(APIROUTE.USER_PROFILE, this.user.userProfile.id.toString(), this.user).subscribe((response) =>  {
      this.userLogin.user = this.user;
      this._authService.saveAuthInformation(this.userLogin);
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Update Data', 'Update data success');
      this.initialize();
    }, error => {
      this._notification.error('Update Data', 'Update data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  onInputEmailChange() {
    if(this.field.isUseEmailForUserName) {
      this.setFieldValue('username', this.field.email);
    }
  }
}
