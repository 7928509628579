import { PaginationModel } from './../../models/api-response.model';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() pagination: PaginationModel = {} as PaginationModel;
  @Output() onFirstPageChange: EventEmitter<PaginationModel> = new EventEmitter<PaginationModel>();
  @Output() onPrevPageChange: EventEmitter<PaginationModel> = new EventEmitter<PaginationModel>();
  @Output() onNextPageChange: EventEmitter<PaginationModel> = new EventEmitter<PaginationModel>();
  @Output() onLastPageChange: EventEmitter<PaginationModel> = new EventEmitter<PaginationModel>();
  @Output() onLimitPageChange: EventEmitter<PaginationModel> = new EventEmitter<PaginationModel>();
  pagingLimits: number[] = [10, 25, 50, 100] as number[];

  constructor() { }

  ngOnInit(): void {
  }

  onFirstPage() {
    if (this.pagination.page !== 1) {
      this.pagination.page = 1;
      this.onFirstPageChange.emit(this.pagination);
    }
  }

  onPrevPage() {
    if (this.pagination.previousPage !== 0) {
      this.pagination.page = this.pagination.previousPage;
      this.onPrevPageChange.emit(this.pagination);
    }
  }

  onNextPage() {
    if (this.pagination.page !== this.pagination.totalPage) {
      this.pagination.page = this.pagination.nextPage;
      this.onNextPageChange.emit(this.pagination);
    }
  }

  onLastPage() {
    if (this.pagination.page !== this.pagination.totalPage) {
      this.pagination.page = this.pagination.totalPage;
      this.onLastPageChange.emit(this.pagination);
    }
  }

  onLimitPage($event: any) {
    this.pagination.limit = parseInt($event);
    this.onLimitPageChange.emit(this.pagination);
  }
}
