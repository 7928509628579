import { Component, OnInit } from '@angular/core';
import { APIResponse, PaginationModel } from 'src/app/app-core/models/api-response.model';
import { AuthService } from 'src/app/app-core/services/auth.service';
import { HttpService } from 'src/app/app-core/services/http.service';
import { NotificationService } from 'src/app/app-core/services/notification.service';
import { AssetModel } from 'src/app/app-model/asset.model';
import { APIROUTE } from '../api-route.config';
import { GridFilterParamModel } from 'src/app/app-core/models/grid-extension.model';
import { AssetPresenter } from './asset.presenter';
import { AssetUsedModel } from 'src/app/app-model/assetused.model';
import { ModalExtension } from 'src/app/app-core/extensions/bootstrap/modal.extension';
import { ObjectExtension } from 'src/app/app-core/extensions/object.extension';
import { GridExtension } from 'src/app/app-core/extensions/grid.extension';
import { URLUtility } from 'src/app/app-core/utilities/url.utility';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.css']
})
export class AssetComponent extends AssetPresenter implements OnInit {
  isLoading: boolean = false;
  isAdministratorPrivillage: boolean = false;
  isShowSearch: boolean = false;
  filter: GridFilterParamModel = {} as GridFilterParamModel;
  urlContext: URLUtility | undefined;

  constructor(private _service: HttpService, public _notification:NotificationService,private _authService: AuthService, private _router: Router) {
    super();
  }

  ngOnInit(): void {
    this.isAdministratorPrivillage = this._authService.isAdministratorPrivillage;
    this.initialize();
  }

  initialize(){
    this.urlContext = new URLUtility(this._router);
    this.assets = [] as AssetModel[];
    this.asset = {} as AssetModel;
    this.isLoading = false;
    this.isShowSearch = false;
    this.pagination = {
      page: 1,
      count: 0,
      nextPage: 0,
      previousPage: 0,
      totalPage: 0,
      limit: 10
    } as PaginationModel;
    this.initializeGrid();
  }

  initializeGrid(){
    this.isLoading = this._service.setLoading(true);
    this._service.get<APIResponse<AssetModel[]>>(APIROUTE.ASSET).subscribe((response) =>  {
      this.assets = response.data;
      this.isLoading = this._service.setLoading(false);
    }, error => {
      this.isLoading = this._service.setLoading(false);
    });
  }

  onAddNew() {
    this.asset = {} as AssetModel;
    this.assetused = {} as AssetUsedModel;
    this.assetForm.submitted = false;
    this.assetForm.reset();
    this.assetusedForm.submitted = false;
    this.assetusedForm.reset();

    this.asset = { id: 0 } as AssetModel;
    this.urlContext?.redirect('/asset-form/', [this.asset.id.toString()]);
  }

  onEdit(item: AssetModel) {
    // var asset = ObjectExtension.Copy<AssetModel>(item);
    // this.setFieldAssetValues(asset);
    this.urlContext?.redirect('/asset-form/', [item.id.toString()]);
  }

  onViewDetail(item: AssetModel) {
    this.asset = ObjectExtension.Copy<AssetModel>(item);
    this.urlContext?.redirect('/asset-view/', [item.id.toString()]);
  }

  onSubmit() {
    this.assetForm.submitted = true;
    if (this.assetForm.invalid) {
      this._notification.warning('Save Data', 'Please fill mandatory field!');
      return;
    }

    this.assetusedForm.submitted = true;
    if (this.assetusedForm.invalid) {
      this._notification.warning('Save Data', 'Please fill mandatory field!');
      return;
    }

    this.getFieldValue();

    if(!this.asset) {
      throw Error('Data Model, Data Model undefined')
    }

    if(!this.assetused) {
      throw Error('Data Model, Data Model undefined')
    }

    this.isInsertAssetData ? this.insertData() : this.updateData();
  }

  insertData() {
    this.isLoading = this._service.setLoading(true);
    this.asset.isDeleted = false;
    this._service.post<APIResponse<AssetModel>>(APIROUTE.ASSET, this.asset).subscribe((response) =>  {
      ModalExtension.Hide('formNewModal');
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Information', 'Success Save Data');
      this.initialize();
    }, error => {
      this._notification.error('Information', 'Save data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  updateData() {
    this.isLoading = this._service.setLoading(true);
    this.asset.isDeleted = false;
    this._service.put<APIResponse<AssetModel>>(APIROUTE.ASSET, this.asset.id.toString(), this.asset).subscribe((response) =>  {
      ModalExtension.Hide('formNewModal');
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Information', 'Success Save Data');
      this.initialize();
    }, error => {
      this._notification.error('Information', 'Save data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  onDelete(item: AssetModel) {
    this.asset = item;
    this.asset.isDeleted = true;
    this.isLoading = this._service.setLoading(true);
    this._service.put<APIResponse<AssetModel>>(APIROUTE.ASSET, this.asset.id.toString(), this.asset).subscribe((response) =>  {
      ModalExtension.Hide('formNewModal');
      this.isLoading = this._service.setLoading(false);
      this._notification.success('Delete Data', 'Delete data success');
      this.initialize();
    }, error => {
      this._notification.error('Delete Data', 'Delete data error');
      this.isLoading = this._service.setLoading(false);
    });
  }

  onViewSearch() {
    this.isShowSearch = !this.isShowSearch;
    this.filter.value = '';
  }

  onSearchChange($event: any) {
    if(this.filter.value.length > 3) {
      this.initializeGrid();
    }
  }

  onClearSearch() {
    this.filter.value = '';
    this.initializeGrid();
  }


  override onFirstPage(pagination: PaginationModel) {
    this.initializeGrid();
  }

  override onNextPage(pagination: PaginationModel) {
    this.initializeGrid();
  }

  override onPrevPage(pagination: PaginationModel) {
    this.initializeGrid();
  }

  override onLastPage(pagination: PaginationModel) {
    this.initializeGrid();
  }

  override onLimitPage(pagination: PaginationModel) {
    this.initializeGrid();
  }

  onClose() {
    this.assetForm.submitted = false;
    this.assetForm.reset();

    this.assetusedForm.submitted = false;
    this.assetusedForm.reset();
    ModalExtension.Hide('formNewModal');
  }

  get buildQueryOption() {
    var gridExtension = new GridExtension();
    return gridExtension.paging(this.pagination.page, this.pagination.limit).filter(this.filter.field, this.filter.value).build();
  }
}
