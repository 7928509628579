<div class="col-12">
  <div class="card">
    <div class="card-header">
      <h5 class="card-title" id="staticBackdropLabel">Asset Management</h5>
    </div>
    <div class="card-body">
      <div [formGroup]="assetForm" class="needs-validation">
        <div class="row">
          <div class="col-6 mb-3">
            <div class="mb-3">
              <label for="unitOrganitationId"><strong>Unit</strong> &nbsp;<sup style="color: red;">*</sup></label>
              <select id="unitOrganitationId" class="form-select form-select-sm"
                formControlName="unitOrganitation_id" (change)="onChangeUnit($event)"
                [ngClass]="{ 'is-invalid': assetForm.isInvalid(fieldAsset.unitOrganitation_id) }" required>
                <option value="">Select Unit</option>
                <option *ngFor="let item of unitOrganitations" [value]="item.id">{{item.name}}</option>
              </select>
              <div *ngIf="assetForm.isValidate(fieldAsset.subUnitOrganitation_id, 'required')" class="invalid-feedback">
                <div *ngIf="assetForm.isInvalid(fieldAsset.subUnitOrganitation_id)">Field is required!</div>
              </div>
            </div>
            <div class="mb-3">
              <label for="subUnitOrganitation_id"><strong>Sub Unit</strong> &nbsp;<sup style="color: red;">*</sup></label>
              <select id="subUnitOrganitation_id" class="form-select form-select-sm" formControlName="subUnitOrganitation_id"
                [ngClass]="{ 'is-invalid': assetForm.isInvalid(fieldAsset.subUnitOrganitation_id) }" required>
                <option value="">Select Sub Unit</option>
                <option *ngFor="let item of subUnitOrganitations; let no = index" [value]="item.id">{{item.name}}</option>
              </select>
              <div *ngIf="assetForm.isValidate(fieldAsset.subUnitOrganitation_id, 'required')" class="invalid-feedback">
                <div *ngIf="assetForm.isInvalid(fieldAsset.subUnitOrganitation_id)">Field is required!</div>
              </div>
            </div>
          </div>
          <div class="col-6 mb-3">
            <div class="mb-3">
              <label for="categoryid"><strong>KIB</strong> &nbsp;<sup style="color: red;">*</sup></label>
              <select id="categoryid" class="form-select form-select-sm" formControlName="category_id" (change)="onChangeKIB($event)"
                [ngClass]="{ 'is-invalid': assetForm.isInvalid(fieldAsset.category_id) }" required>
                <option value="">Select Kategori</option>
                <option *ngFor="let item of categories; let no = index" [value]="item.id">{{item.code}} - {{item.name}}</option>
              </select>
              <div *ngIf="assetForm.isValidate(fieldAsset.category_id, 'required')" class="invalid-feedback">
                <div *ngIf="assetForm.isInvalid(fieldAsset.category_id)">Field is required!</div>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="isShowKIBA">
            <app-assetkiba-form [items]="assetKIBAs" (onDataChange)="onDataChange($event, 1)"></app-assetkiba-form>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="float-end">
        <button type="button" class="btn btn-secondary" style="margin-right: 5px !important;" (click)="onCancel()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="onSubmit()">Submit</button>
        <!--
        <button *ngIf="formStepId !== 1" type="button" class="btn btn-info" style="margin-right: 5px !important;" (click)="onBack()"><i class="bx bx-chevron-left"></i> Back</button>
        <button *ngIf="formStepId === maxFormStepId" type="button" class="btn btn-primary" (click)="onSubmit()">Submit</button>
        <button *ngIf="formStepId !== maxFormStepId" type="button" class="btn btn-primary" (click)="onStepNext()">Next <i class="bx bx-chevron-right"></i></button>
        -->
      </div>
    </div>
  </div>
</div>
