import { AuthService } from './services/auth.service';
import { ImageService } from './services/image.service';
import { NotificationService } from './services/notification.service';

import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './services/http.service';

@NgModule({
  declarations: [
  ],
  imports: [
    HttpClientModule
  ],
  exports: [],
  providers: [
      HttpService,
      ImageService,
      NotificationService,
      AuthService,

  ]
})
export class AppCoreModule { }
