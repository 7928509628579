import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AssetKIBAPresenter } from '../assetkiba.presenter';
import { AssetKIBAModel, AssetKIBAViewModel } from 'src/app/app-model/assetkiba.model';
import { HttpService } from 'src/app/app-core/services/http.service';
import { NotificationService } from 'src/app/app-core/services/notification.service';
import { AuthService } from 'src/app/app-core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageConfiguration } from 'src/app/app-core/configurations/storage.configuration';
import { AssetModel } from 'src/app/app-model/asset.model';
import { CONSTANT_STORAGE } from 'src/app/app-core/constants/global.constant';
import * as uuid from 'uuid';
import { APIResponse } from 'src/app/app-core/models/api-response.model';
import { APIROUTE, UPLOADMODULE } from '../../../api-route.config';
import { ObjectExtension } from 'src/app/app-core/extensions/object.extension';
import { ModalExtension } from 'src/app/app-core/extensions/bootstrap/modal.extension';
import { ServerConfiguration } from 'src/app/app-core/configurations/server.configuration';
import { UploadFileResultModel } from 'src/app/app-core/models/upload-file.model';
import { HttpErrorResponse } from '@angular/common/http';
import { StringExtension } from 'src/app/app-core/extensions/string.extension';

@Component({
  selector: 'app-assetkiba-form',
  templateUrl: './assetkiba-form.component.html',
  styleUrls: ['./assetkiba-form.component.css']
})
export class AssetkibaFormComponent extends AssetKIBAPresenter implements OnInit, OnChanges {
  @Input() items: Array<AssetKIBAModel> = [] as AssetKIBAModel[];
  @Output() onDataChange: EventEmitter<AssetKIBAModel[]> = new EventEmitter<AssetKIBAModel[]>();
  assetKIBATemps: AssetKIBAViewModel[] = [] as AssetKIBAViewModel[];
  assetKIBATemp: AssetKIBAViewModel = {} as AssetKIBAViewModel;
  isLoading: boolean = false;
  isAdministratorPrivillage: boolean = false;
  isFileFromSelector: boolean = false;
  isPageNew: boolean = false;
  isPageEdit: boolean = false;
  file: File | null = null;
  @Input() isPageView: boolean = false;

  constructor(private _service: HttpService, public _notification: NotificationService, private _authService: AuthService, private _router: Router, private _actRoute: ActivatedRoute) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges');
    this.initialize();
  }

  ngOnInit(): void {
    console.log('ngOnInit');
    this.isAdministratorPrivillage = this._authService.isAdministratorPrivillage;
    this.initialize();
  }

  initialize() {
    this.assetKIBAs = [] as AssetKIBAModel[];
    this.assetKIBA = {} as AssetKIBAModel;
    this.assetKIBATemps = [] as AssetKIBAViewModel[];
    this.assetKIBATemp = {} as AssetKIBAViewModel;

    this.isFileFromSelector = false;
    this.isLoading = false;
    this.initializeForm();
  }

  initializeForm() {
    this.loadFromTemp();
    if(ObjectExtension.Any(this.items)) this.parseDataAssetKIB(this.items);
  }

  loadFromTemp() {
    console.log('loadFromTemp');
    var items = StorageConfiguration.getDataTempAsset<AssetKIBAModel[]>(CONSTANT_STORAGE.ASSETKIBA);
    if(items !== null) this.items = items;
  }

  loadFromDatabase(assetId: number) {
    this._service.find<APIResponse<AssetKIBAModel[]>>(APIROUTE.ASSETKIBA_ASSET, assetId.toString()).subscribe({
      next: (response) => {
        if(response.data) this.parseDataAssetKIB(response.data);
        this.isLoading = this._service.setLoading(false);
      },
      error: (error) => {
        this.isLoading = this._service.setLoading(false);
      }
    })
  }

  parseDataAssetKIB(assets: AssetKIBAModel[]){
    this.assetKIBATemps = [] as AssetKIBAViewModel[];
    this.assetKIBAs = assets;
    this.assetKIBAs.forEach(item => {
      var asset = new AssetKIBAViewModel(uuid.v4(), item);
      this.assetKIBATemps.push(asset);
    });
  }

  onFileChange($event: any) {
    var file = $event.target.files[0];
    this.file = file;
    this.assetKIBA.image = URL.createObjectURL(file);
    this.isFileFromSelector = true;
    $event.target.value = null;
  }

  onResetFileChange() {
    this.file = null;
    this.assetKIBA.image = '';
    this.isFileFromSelector = false;
  }

  onAddNew() {
    this.isPageNew = true;
    this.isPageEdit = false;
    this.isFileFromSelector = false;
    this.setFieldValues();
    ModalExtension.Show('formNewAssetKIBAModal');
  }

  onEdit(item: AssetKIBAViewModel) {
    this.assetKIBATemp = item;
    this.isPageNew = false;
    this.isPageEdit = true;
    this.isFileFromSelector = false;
    this.setFieldValues(item.asset);
    ModalExtension.Show('formNewAssetKIBAModal');
  }

  onDelete(item: AssetKIBAViewModel) {
    this.deleteItem(item);
  }

  onSubmit() {

    this.assetKIBAForm.submitted = true;
    if (this.assetKIBAForm.invalid) {
      this._notification.warning('Save Data', 'Please fill mandatory field!');
      return;
    }

    this.getFieldValue();

    if (!this.assetKIBA) {
      throw Error('Data Model, Data Model undefined')
    }

    this.assetKIBATemp.asset = this.assetKIBA;
    this.processingSubmit();
  }

  processingSubmit(){
    if (this.file) {
      this.isLoading = this._service.setLoading(true);
      this._service.uploadFile<UploadFileResultModel>(this.file, UPLOADMODULE.DEFAULT).subscribe((response) => {
        this.assetKIBATemp.asset.image = response.filepath;
        this.onSuccessSubmit();
        this.isLoading = this._service.setLoading(false);
      }, (error: HttpErrorResponse) => {
        if(!ObjectExtension.IsNullOrEmpty(error.error)){
          if(!StringExtension.IsNullOrEmpty(error.error.statusMessage)) {
            var errorMessage = error.error.statusMessage;
            this._notification.error('Upload', errorMessage);
            this.isLoading = this._service.setLoading(false);
          }
        }else{
          var errorMessage = error.error.statusMessage;
          this._notification.error('Upload', 'Upload file error, please try again');
          this.isLoading = this._service.setLoading(false);
        }
      });
    }else{
      this.onSuccessSubmit();
      this.isLoading = this._service.setLoading(false);
    }
  }

  onSuccessSubmit() {
    this.deleteItem(this.assetKIBATemp);

    this.assetKIBATemps.push(this.assetKIBATemp);
    console.log('onSuccessSubmit', this.assetKIBATemps);
    this.mergeData();
    this.onDataChange.emit(this.assetKIBAs);

    ModalExtension.Hide('formNewAssetKIBAModal');
  }

  onCancel() {
    this.file = null;
    this.isFileFromSelector = false;
    this.assetKIBATemp = new AssetKIBAViewModel(uuid.v4(), null);
    this.setFieldValues();
    ModalExtension.Hide('formNewAssetKIBAModal');
  }

  parseImage(imageName: string) {
    var imageUrl = ServerConfiguration.ParseImage(imageName) ?? '';
    return imageUrl;
  }

  deleteItem(item: AssetKIBAViewModel) {
    this.assetKIBATemps = this.assetKIBATemps.filter(x => x.uniqueValue !== item.uniqueValue);
  }

  mergeData() {
    if(ObjectExtension.Any(this.assetKIBATemps)) {
      console.log('ObjectExtension Temp');
      this.assetKIBAs = [];
      this.assetKIBATemps.forEach(x => {
        this.assetKIBAs.push(x.asset);
      });
    }
  }
}
