import { NavigationModel } from './../../models/navigation.model';
import { APIROUTE } from './../../../app-view/api-route.config';
import { ServerConfiguration } from './../../configurations/server.configuration';
import { AuthService } from './../../services/auth.service';
import { StringExtension } from 'src/app/app-core/extensions/string.extension';
import { ObjectExtension } from 'src/app/app-core/extensions/object.extension';
import { HttpErrorResponse } from '@angular/common/http';
import { CONSTANT_STORAGE } from './../../constants/global.constant';
import { Logger } from './../../utilities/logger.utility';
import { StorageConfiguration } from '../../configurations/storage.configuration';
import { ModalExtension } from './../../extensions/bootstrap/modal.extension';
import { APIResponse } from './../../models/api-response.model';
import { Router } from '@angular/router';
import { NotificationService } from './../../services/notification.service';
import { UserLoginResultModel } from './../../models/user.model';
import { HttpService } from 'src/app/app-core/services/http.service';
import { Component, OnInit } from '@angular/core';
import { UserLoginParamModel } from '../../models/user.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userLoginParam: UserLoginParamModel = {} as UserLoginParamModel;
  isLoading: boolean = false;
  isShowPassword: boolean = false;
  inputType: string = 'password';
  inputIconType: string = 'bx-hide';

  constructor(private _service: HttpService, public _router: Router, public _notification:NotificationService, private _authService: AuthService) { }

  ngOnInit(): void {
  }

  onSignIn() {
    this.isLoading = this._service.setLoading(true);
    this._service.authLogin<UserLoginResultModel>(this.userLoginParam.username,this.userLoginParam.password)
      .subscribe(response => {
        this.isLoading = this._service.setLoading(false);
        if(response !== null){
          this.onSuccessLogin(response);
        }else{
          this._notification.error('Information', response);
        }
      }, (error: HttpErrorResponse) => {
        Logger.info(error.error);
        if(!ObjectExtension.IsNullOrEmpty(error.error)){
          if(!StringExtension.IsNullOrEmpty(error.error.statusMessage)) {
            this.isLoading = this._service.setLoading(false);
            this._notification.error('Information', error.error.statusMessage);
          }
        }else{
          this._notification.error('Information', 'Invalid Username or Password');
        }
        this.isLoading = this._service.setLoading(false);
      });
  }

  onSuccessLogin(item: UserLoginResultModel) {
    console.log(item);
    this._authService.saveAuthInformation(item);
    this._authService.saveToken(item.token.token);
    this._router.navigate(['/']).finally(() => {
      window.location.reload();
    });
    // window.location.reload();
  }

  onShowOrHidePassword() {
    this.isShowPassword = this.isShowPassword ? false:true;
    Logger.info('onShowOrHidePassword', this.isShowPassword);
    this.inputType = this.isShowPassword ? 'text' : 'password';
    this.inputIconType = this.isShowPassword ? 'bx-show' : 'bx-hide';
  }
}
