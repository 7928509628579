import { AuthService } from './../../services/auth.service';
import { UserProfileModel } from './../../models/user-profile.model';
import { Component, OnInit } from '@angular/core';
import { GroupMemberModel } from '../../models/group-member.model';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html'
})
export class UserInformationComponent implements OnInit {
  userProfile: UserProfileModel = {} as UserProfileModel;
  groupmember: GroupMemberModel = {} as GroupMemberModel;

  constructor(private _authService: AuthService) { }

  ngOnInit(): void {
    this.userProfile = {} as UserProfileModel;
    this.groupmember = {} as GroupMemberModel;
    this.getUserLogin();
  }

  get isLoggedIn() {
    return this._authService.isLoggedIn;
  }

  onLogout() {
    this._authService.signOut();
  }

  getUserLogin() {
    var userInfo = this._authService.getAuthInformation();
    if(this.isLoggedIn){
      if(userInfo){
        this.userProfile = userInfo.user.userProfile;
        this.groupmember = userInfo.user.groupmember;
      }
    }
  }

}
